/**
 * @author Abdul Rahim M -  abdul@au79consulting.com
 * @date 11-06-2024
 * @description This is the Main Dashboard Layout common for Topbar
 */
import {
  Avatar,
  Box,
  Button,
  DialogContentText,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, Redirect } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { sideBarData } from "../SideBar/sideBarData";
import actionType from "../../redux/actionTypes";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeloitteLogo from "../../assets/logoIcons/Light Deloitte Logo.jpg";
import DeloitteLogoWhite from "../../assets/logoIcons/DeloitteWhite.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";
import appTheme from "../../assets/app-theme/appTheme";
import LogoutIcon from "@mui/icons-material/Logout";
import CustomConfirmationDialog from "../../components/dialog-boxes/CustomConfirmationDialog";
import customConsole from "../../config/customConsole";

function TopBar() {
  const dispatch = useDispatch();
  const profilePathDetails = useSelector((state) => state?.profileReducer);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isLogout, setIsLogout] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState("PROFILE");
  const [pageUrl, setPageURL] = React.useState("PROFILE");
  const [profilepicpath, setProfilePicPath] = React.useState("");
  const [profilePng, setProfilePng] = React.useState("");
  const [openLogOutConfirmDialog, setOpenLogOutConfirmDialog] = useState(false);

  React.useEffect(() => {
    customConsole("profilePathDetails?.profile_pic_name");
    customConsole(profilePathDetails?.profile_pic_name);
    setProfilePng(profilePathDetails?.profile_pic_name);
  }, [profilePathDetails]);
  React.useEffect(() => {
    customConsole("profilePathDetails?.profile_pic_path");
    customConsole(profilePathDetails?.profile_pic_path);
    setProfilePicPath(profilePathDetails?.profile_pic_path);
  }, [profilePathDetails?.profile_pic_path]);

  React.useEffect(() => {
    if (profilePng && profilePng.length) {
      dispatch(
        HandleApiActions({
          ...profileActions.get_profile_pic,
          show_toast: true,
          file_name: profilePng,
          is_file: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;
    customConsole(pathname);
    setPageURL(pathname);
    const urlParts = pathname.split("/"); // Split path into segments
    customConsole("TOP BAR");
    // Extract page name (consider adjusting based on your URL structure)
    const pageName = urlParts[urlParts.length - 1] || "profile"; // Default to 'Home' if no page name
    customConsole(pageName);
    // home, templates, admin-settings, profile,
    // legal-requirements, project-config, type-approval
    // labcar, user-management
    switch (pageName) {
      case "home":
        setPageTitle("DASHBOARD");
        break;
      case "templates":
        setPageTitle("TEMPLATES");
        break;
      case "type-approval":
        setPageTitle("TYPE APPROVALS");
        break;
      case "labcar":
        setPageTitle("VIRTUAL LABCAR");
        break;
      case "user-management":
        setPageTitle("USER MANAGEMENT");
        break;
      case "admin-settings":
        setPageTitle("SETTINGS");
        break;
      case "project-config":
        setPageTitle("PROJECT CONFIGURATOR");
        break;
      case "profile":
        setPageTitle("PROFILE");
        break;
      case "legal-requirements":
        setPageTitle("LEGAL REQUIREMENTS");
        break;
      case "docs":
        setPageTitle("API DOCUMENTATION");
        break;
      default:
        setPageTitle("PROFILE");
        break;
    }
  }, [window.location.pathname]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOutConfirm = () => {
    setIsLogout(true);
    dispatch({
      type: actionType.SIGN_OUT,
    });
    dispatch({
      type: actionType.RESET_PROFILE,
    });
    dispatch({
      type: actionType.RESET_LAB_CAR_DATA,
    });
    dispatch({
      type: actionType.RESET_PROJECT_CONFIG,
    });
  };

  const handleLogOut = () => {
    setOpenLogOutConfirmDialog(true);
  };
  if (isLogout) {
    return <Navigate to="/authenticate" />;
  }
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const handleClickToPage = (to) => {
    navigate(to);
    setOpenDrawer(!openDrawer);
  };

  const capitalizePageTitle = (title) => {
    // Split the title into words
    const words = title.toLowerCase().split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the capitalized words back into a string
    const capitalizedTitle = capitalizedWords.join(" ");

    return capitalizedTitle;
  };
  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        sx={{ border: "0.5px solid gray" }}
        boxShadow="3"
        backgroundColor={appTheme.primaryColor}
      >
        {isMobile && (
          <Box>
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            <Drawer open={openDrawer}>
              <Box ml="10px" mt="10px">
                <img alt="" src={DeloitteLogo} style={{ width: "210px" }} />
              </Box>
              <Divider />
              <Box sx={{ flex: 1, overflowY: "auto" }}>
                <List component="nav">
                  {sideBarData?.map((data, index) => (
                    <ListItemButton
                      key={index}
                      onClick={() => handleClickToPage(data.path)}
                      sx={{
                        backgroundColor: pageUrl === data.path ? "#cff5e8" : "",
                      }}
                    >
                      <ListItemIcon>{data.icon}</ListItemIcon>
                      <ListItemText primary={data.title} />
                    </ListItemButton>
                  ))}
                </List>
              </Box>
            </Drawer>
          </Box>
        )}
        {!isMobile && (
          <>
            <Box
              ml="10px"
              // mt="10px"
              my="10px"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="" src={DeloitteLogoWhite} style={{ width: "120px" }} />
            </Box>
          </>
        )}

        <Box
          sx={{
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "20px", sm: "22px" },
              color: "white",
              fontFamily: "sans-serif",
            }}
          >
            {capitalizePageTitle(pageTitle)}
          </Typography>
        </Box>

        <Box>
          {/* <IconButton
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
          >
            <Avatar alt="Avatar" src={profilepicpath} variant="square" />
          </IconButton>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogOut} disableRipple>
              <EditIcon />
              Logout
            </MenuItem>
          </StyledMenu> */}

          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              onClick={handleLogOut}
              color="inherit"
              aria-label="logout"
            >
              <Typography
                variant="body2"
                sx={{ color: "white", fontWeight: "bold" }}
              >
                Logout
              </Typography>
              <LogoutIcon sx={{ ml: 1, color: "white" }} />
            </IconButton>
          </Stack>
        </Box>
      </Box>

      <CustomConfirmationDialog
        open={openLogOutConfirmDialog}
        onClose={() => setOpenLogOutConfirmDialog(false)}
        dialogTitle={"Confirm Logout"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        }
        onConfirm={handleLogOutConfirm}
      />
    </div>
  );
}

export default TopBar;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
