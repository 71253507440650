import actionType from "../actionTypes";

const initialState = {
  softwareIntersection: [],
};

const softwareToSoftwareIntersectionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.GET_SOFTWARE_INTERSECTIONS:
      return {
        ...state,
        softwareIntersection: action.payload.error ? [] : action.payload.data,
      };
    default:
      return state;
  }
};

export default softwareToSoftwareIntersectionReducer;
