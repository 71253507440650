/**
 * @author Bharathan - bharathan@au79consulting.com
 * @date 09-08-2024
 * @description This is the LegalToSoftware Page in the Link Tab for the Virtual Type Approval Page
 */

// Import required components and functions
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { SelectTextFieldN } from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../redux/actions/actionHandler";
import labCar from "../../../redux/actions/labCar";

//Component to link Legal Standards with required S/W Modules.
function LegalToSoftwareLinks({
  selectedProjectId,
  selectedProjectIsZonal,
  selectedProjectZonesList,
}) {
  // Redux dispatch and state selectors
  const dispatch = useDispatch();
  const {
    eeComponentsMainList,
    eeComponentsLinkedList,
    eeComponentsMainLinkedList,
    swModulesMainList,
    swModulesLinkedList,
  } = useSelector((state) => state.labCarReducer, shallowEqual);

  // State variables for various dropdowns and lists
  const [zoneMainOptionsList, setZoneMainOptionsList] = useState([]);
  const [selectedZoneMain, setSelectedZoneMain] = useState(null);
  const [zoneLinkedOptionsList, setZoneLinkedOptionsList] = useState([]);
  const [selectedZoneLinked, setSelectedZoneLinked] = useState(null);
  const [eeMainOptionsList, setEEMainOptionsList] = useState([]);
  const [selectedEEMainId, setSelectedEEMainId] = useState(null);
  const [eeLinkedOptionsList, setEELinkedOptionsList] = useState([]);
  const [selectedEELinkedId, setSelectedEELinkedId] = useState(null);
  const [eeMainLinkedOptionsList, setEEMainLinkedOptionsList] = useState([]);
  const [eeMainSWModulesList, setEEMainSWModulesList] = useState([]);
  const [eeLinkedSWModulesList, setEELinkedSWModulesList] = useState([]);
  const [selectedMainSWModuleDetails, setSelectedMainSWModuleDetails] =
    useState(null);

  // Fetch EE Main Components based on selected project and zone
  const fetchEEMainComponents = () => {
    if (selectedProjectId && selectedZoneMain) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_main,
          params: {
            project_id: selectedProjectId,
            zone_sequence: selectedZoneMain,
          },
          show_toast: false,
        })
      );
    }
  };

  // Fetch EE Linked Components based on selected project and zone
  const fetchEELinkedComponents = () => {
    if (selectedProjectId && selectedZoneLinked) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_linked,
          params: {
            project_id: selectedProjectId,
            zone_sequence: selectedZoneLinked,
          },
          show_toast: false,
        })
      );
    }
  };

  // Fetch EE Main Linked Components based on selected project
  const fetchEEMainLinkedComponents = () => {
    if (selectedProjectId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_main_linked,
          params: { project_id: selectedProjectId },
          show_toast: false,
        })
      );
    }
  };

  // Fetch linked SW modules based on selected project and EE Linked ID
  const fetchLinkedSWModules = () => {
    if (selectedProjectId && selectedEELinkedId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_linked_sw_modules,
          params: { project_id: selectedProjectId, ee_id: selectedEELinkedId },
          show_toast: false,
        })
      );
    }
  };

  // Fetch main SW modules based on selected project and EE Main ID
  const fetchMainSWModules = () => {
    if (selectedProjectId && selectedEEMainId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_main_sw_modules,
          params: { project_id: selectedProjectId, ee_id: selectedEEMainId },
          show_toast: false,
        })
      );
    }
  };

  // Clear and reset states on project ID change
  useEffect(() => {
    setZoneMainOptionsList([]);
    setSelectedZoneMain(null);
    setZoneLinkedOptionsList([]);
    setSelectedZoneLinked(null);
    setEEMainOptionsList([]);
    setSelectedEEMainId(null);
    setEELinkedOptionsList([]);
    setSelectedEELinkedId(null);
    setEEMainLinkedOptionsList([]);
    setEEMainSWModulesList([]);
    setEELinkedSWModulesList([]);
    setSelectedMainSWModuleDetails(null);
  }, [selectedProjectId]);

  // Update zone options when project zones change
  useEffect(() => {
    if (selectedProjectIsZonal) {
      const zoneOptions = selectedProjectZonesList
        .map((el) => ({
          value: el?.sequence,
          label: el?.zone_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setZoneMainOptionsList(zoneOptions);
      setZoneLinkedOptionsList(zoneOptions);
    }
  }, [selectedProjectZonesList]);

  // Fetch EE Main Linked Components on project ID change
  useEffect(() => {
    fetchEEMainLinkedComponents();
  }, [selectedProjectId]);

  // Fetch EE Main Components on zone selection change
  useEffect(() => {
    setSelectedEEMainId(null);
    setEEMainSWModulesList([]);
    fetchEEMainComponents();
  }, [selectedZoneMain]);

  // Fetch EE Linked Components on zone selection change
  useEffect(() => {
    setSelectedEELinkedId(null);
    setEELinkedSWModulesList([]);
    fetchEELinkedComponents();
  }, [selectedZoneLinked]);

  // Update EE Main Components options when the list changes
  useEffect(() => {
    if (eeComponentsMainList) {
      const options = eeComponentsMainList
        .map((el) => ({
          value: el?.ee_id,
          label: el?.description,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setEEMainOptionsList(options);
    }
  }, [eeComponentsMainList]);

  // Update EE Linked Components options when the list changes
  useEffect(() => {
    if (eeComponentsLinkedList) {
      const options = eeComponentsLinkedList
        .map((el) => ({
          value: el?.ee_id,
          label: el?.description,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setEELinkedOptionsList(options);
    }
  }, [eeComponentsLinkedList]);

  // Update EE Main Linked Components options when the list changes
  useEffect(() => {
    if (eeComponentsMainLinkedList) {
      const options = eeComponentsMainLinkedList
        .map((el) => ({
          value: el?.ee_id,
          label: el?.description,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setEEMainLinkedOptionsList(options);
    }
  }, [eeComponentsMainLinkedList]);

  // Fetch SW Modules based on EE Main ID and project ID
  useEffect(() => {
    setSelectedMainSWModuleDetails(null);
    fetchMainSWModules();
  }, [selectedProjectId, selectedEEMainId]);

  // Fetch Linked SW Modules based on EE Linked ID and project ID
  useEffect(() => {
    fetchLinkedSWModules();
  }, [selectedProjectId, selectedEELinkedId]);

  // Update EE Main SW Modules list when the main SW modules list changes
  useEffect(() => {
    if (swModulesMainList && selectedEEMainId) {
      setEEMainSWModulesList(swModulesMainList);
    }
  }, [swModulesMainList]);

  // Update EE Linked SW Modules list based on linked SW modules list
  useEffect(() => {
    if (swModulesLinkedList && selectedEELinkedId) {
      const filteredList = selectedMainSWModuleDetails
        ? swModulesLinkedList.filter(
            (el) => el?.swm_id !== selectedMainSWModuleDetails?.swm_id
          )
        : swModulesLinkedList;

      setEELinkedSWModulesList(filteredList);
    }
  }, [swModulesLinkedList, selectedMainSWModuleDetails]);

  return (
    <Box sx={styles.box}>
      <Box sx={styles.box1}>
        <Grid container spacing={4}>
          {/* Grid item for the Legal Standards section */}
          <Grid item xs={12} md={6} sx={styles.grid}>
            <Box sx={{ mb: 3 }}>
              {/* Dropdown to select Legal Standards */}
              <SelectTextFieldN
                size={"small"}
                label={"Legal Standards"}
                variant={"standard"}
                sltFldStyle={{
                  width: "190px",
                }}
              />
            </Box>
            <Card sx={styles.card}>
              {/* Card Header displaying the title "Legal Standards" */}
              <CardHeader
                sx={styles.cardHeader}
                title={
                  <Typography
                    textAlign={"center"}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    {"LEGAL STANDARDS"}
                  </Typography>
                }
              />
              <CardContent sx={styles.cardContent}>
                <Box component={Paper} sx={styles.box2}>
                  {/* Table Header displaying TOTAL, MAPPED, and UNMAPPED columns */}
                  <Box sx={styles.box3}>
                    <Box sx={styles.box4}>TOTAL</Box>
                    <Box sx={styles.box5}>MAPPED</Box>
                    <Box sx={styles.box6}>UNMAPPED</Box>
                  </Box>
                  {/* Table Body displaying data rows */}
                  <Box sx={styles.box7}>
                    <Box sx={styles.box8}>{0}</Box>
                    <Box sx={styles.box9}>{0}</Box>
                    <Box sx={styles.box10}>{0}</Box>
                  </Box>
                </Box>
                {/* Message displayed when no data is available */}
                <Typography variant="body1" sx={styles.typography}>
                  No data available
                </Typography>
              </CardContent>
              {/* Card Actions section (currently empty) */}
              <CardActions sx={styles.cardActions}></CardActions>
            </Card>
          </Grid>

          {/* Grid item for the Software Modules section */}
          <Grid item xs={12} md={6} sx={styles.grid}>
            <Box sx={{ mb: 3 }}>
              {/* Dropdowns to select Zone, EE Component, and Tag */}
              <SelectTextFieldN
                size={"small"}
                label={"Select Zone"}
                variant={"standard"}
                optionsList={zoneMainOptionsList}
                selectedValue={selectedZoneMain}
                handleOnChange={(e) => setSelectedZoneMain(e.target.value)}
                sltFldStyle={{
                  width: "190px",
                }}
              />
              <SelectTextFieldN
                size={"small"}
                label={"Select EE Component"}
                variant={"standard"}
                optionsList={selectedProjectIsZonal ? eeMainOptionsList : null}
                selectedValue={selectedEEMainId}
                disabled={selectedProjectIsZonal ? !selectedZoneMain : false}
                handleOnChange={(e) => setSelectedEEMainId(e.target.value)}
                sltFldStyle={{
                  width: "190px",
                  ml: 2,
                }}
              />
              <SelectTextFieldN
                size={"small"}
                label={"Select Tag"}
                variant={"standard"}
                sltFldStyle={{
                  width: "190px",
                  ml: 2,
                }}
              />
            </Box>
            <Card sx={styles.card}>
              {/* Card Header displaying the title "Software Modules" */}
              <CardHeader
                sx={styles.cardHeader}
                title={
                  <Typography
                    textAlign={"center"}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    {"SOFTWARE MODULES"}
                  </Typography>
                }
              />
              <CardContent sx={styles.cardContent}>
                <Box component={Paper} sx={styles.box11}>
                  {/* Table Header displaying TOTAL, MAPPED, and UNMAPPED columns */}
                  <Box sx={styles.box12}>
                    <Box sx={styles.box13}>TOTAL</Box>
                    <Box sx={styles.box14}>MAPPED</Box>
                    <Box sx={styles.box15}>UNMAPPED</Box>
                  </Box>
                  {/* Table Body displaying data rows */}
                  <Box sx={styles.box16}>
                    <Box sx={styles.box17}>{eeMainSWModulesList.length}</Box>
                    <Box sx={styles.box18}>{0}</Box>
                    <Box sx={styles.box19}>{0}</Box>
                  </Box>
                </Box>

                {eeMainSWModulesList && eeMainSWModulesList?.length > 0 ? (
                  eeMainSWModulesList?.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => setSelectedMainSWModuleDetails(item)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1.5,
                        mt: 1,
                        pl: 1.4,
                        pr: 1,
                        backgroundColor: "background.paper",
                        borderRadius: 1,
                        boxShadow: 4,
                        border:
                          selectedMainSWModuleDetails &&
                          selectedMainSWModuleDetails?.swm_id == item?.swm_id
                            ? "2px solid blue"
                            : "1px solid lightgrey",
                        cursor: "pointer",
                        "&:hover": {
                          // backgroundColor: "lightgrey",
                        },
                      }}
                    >
                      <Box sx={{ flexGrow: 1, textAlign: "start", my: 1 }}>
                        <Typography variant="body2">
                          <strong>Name:</strong> {item?.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {item?.description}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Version:</strong> {item?.version}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", mt: 2 }}
                  >
                    No data available
                  </Typography>
                )}
              </CardContent>
              {/* Card Actions section (currently empty) */}
              <CardActions sx={styles.cardActions}></CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default LegalToSoftwareLinks;

// Styles used for layout and design
const styles = {
  box: {
    mb: "70px",
    width: "100%",
  },
  box1: { mt: 2 },
  box2: { p: 2 },
  box3: {
    display: "flex",
    border: "1px solid grey",
    mt: "0px",
  },
  box4: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box5: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box6: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    padding: "5px",
  },
  box7: {
    display: "flex",
    border: "1px solid grey",
  },
  box8: {
    flex: 1,
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box9: {
    flex: 1,
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box10: {
    flex: 1,
    textAlign: "center",
    padding: "5px",
  },
  box11: { p: 2 },
  box12: {
    display: "flex",
    border: "1px solid grey",
  },
  box13: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box14: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box15: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    padding: "5px",
  },
  box16: {
    display: "flex",
    border: "1px solid grey",
  },
  box17: {
    flex: 1,
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box18: {
    flex: 1,
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box19: {
    flex: 1,
    textAlign: "center",
    padding: "5px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    mx: 2,
    boxShadow: 12,
    width: "420px",
  },
  cardHeader: {
    pb: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  cardContent: {
    height: "340px",
    overflowY: "auto",
    paddingTop: "0px",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  typography: { textAlign: "center", mt: 2 },
};
