/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 11-06-2024
 * @description This is the Project Configurator Page for the Application
 */
//Importing the required components/functions from the libraries

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import CustomButton from "../../components/buttons/CustomButton";
import CreateProject from "./CreateProject";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import { useDispatch, useSelector } from "react-redux";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import CloseIcon from "@mui/icons-material/Close";
import FrontBox from "./FrontBox";
import ViewDialog from "./ViewDialog";
import AddIcon from "@mui/icons-material/Add";
import appTheme from "../../assets/AppTheme/appTheme";
import customConsole from "../../config/customConsole";
import CustomFiltersN from "../../components/tables/CustomFiltersN";
import { CustomPagination, CustomSorting } from "../../components";
import { profileActions } from "../../redux/actions";
import RepeatIcon from "@mui/icons-material/Repeat";
import ReCreateProject from "./ReCreateProject";

//Main Project Configurator Page Function

function ProjectConfig() {
  //1.Redux
  const dispatch = useDispatch();
  const projectDetails = useSelector(
    (state) => state.projectConfigReducer?.projectConfig
  );
  console.log("projectDetails", projectDetails);
  const profileDetails = useSelector((state) => state.profileReducer);
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData?.project_status
  );
  const projectStatusList =
    projectStatusDetails && typeof projectStatusDetails === "object"
      ? Object.keys(projectStatusDetails)
      : [];

  //2.React useState
  const [boxes, setBoxes] = useState([]);
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [openReCreateProject, setOpenReCreateProject] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [showNoProjectsFound, setShowNoProjectsFound] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [recreateProject, setRecreateProject] = useState({});

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Sorting
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("created_at");
  const [selectedSortOrder, setSelectedSortOrder] = useState("ASC");

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("name");
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [projectStatusFilter, setProjectStatusFilter] = useState([]);
  //3.React useEffect

  const projectStatusByName = {
    ACTIVE_PRE_SOP: 100,
    ACTIVE_POST_SOP: 200,
    DE_ACTIVATED: 300,
    COMPLETED: 500,
  };
  useEffect(() => {
    // Get the profile details
    dispatch(HandleApiActions({ ...profileActions.get_profile_details }));
  }, []);

  useEffect(() => {
    if (boxes.length === 0) {
      const timer = setTimeout(() => {
        setShowNoProjectsFound(true);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setShowNoProjectsFound(false);
    }
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.get_project,
        params: {
          page_number: page,
          page_size: rowsPerPage,
          order_by_column: selectedSortColumn,
          order_by_sort: selectedSortOrder,
          ...(searchFieldValue && { [selectedSearchField]: searchFieldValue }),
        },
      })
    );
  }, [
    page,
    rowsPerPage,
    selectedSortColumn,
    selectedSortOrder,
    selectedSearchField,
    searchFieldValue,
  ]);

  useEffect(() => {
    if (projectDetails?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    const imageDetails = [];
    let filteredProjectList = projectDetails.map((data, index) => {
      imageDetails.push({
        id: data.project_id,
        image: data.vehicle_class_details.length
          ? data.vehicle_class_details[0].image
          : "",
      });
      customConsole(imageDetails);
      return {
        ...data,
        id: (page - 1) * rowsPerPage + index + 1,
        name: data?.name,
        trade_mark: data?.trade_mark,
        mft_details: {
          name: data?.mft_details?.name,
          address: data?.mft_details?.address,
        },
        architecture_name: data?.architecture_name,
        zones: data?.zones,
        vehicle_class_details: data?.vehicle_class_details,
        vehicle_function_details: data?.vehicle_function_details,
        created_at: data?.created_at
          ? convertUtcToTimeZone(
              data.created_at,
              profileDetails?.profile?.profile?.region
            )
          : null,
        updated_at: data?.updated_at
          ? convertUtcToTimeZone(
              data.updated_at,
              profileDetails?.profile?.profile?.region
            )
          : null,
        country: data?.country,
        region: data?.region,
        image_url: "",
      };
    });

    setBoxes(filteredProjectList);
  }, [projectDetails, profileDetails]);

  console.log("dialogData....", dialogData);
  useEffect(() => {
    let filteredStatus = projectStatusList?.map((el) => {
      return {
        value: projectStatusByName[el],
        label: formatString(el),
      };
    });
    console.log("filteredStatus", filteredStatus);
    setProjectStatusFilter(filteredStatus);
  }, [projectStatusDetails]);

  //4.Functions
  const handleCreateProjectClick = () => {
    setOpenCreateProject(true);
  };

  console.log("......boxes.....", boxes);

  const handleBoxAction = (index, actionType) => {
    const clickedData = boxes?.find((item) => item?.project_id === index);

    if (actionType === "recreate") {
      setRecreateProject(clickedData);
      setOpenReCreateProject(true);
    } else if (actionType === "dialog") {
      setDialogData(clickedData);
      setSelectedProjectId(index);
      setOpenDialog(true);
    }
  };

  console.log("dialogData", dialogData);

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("role_name", "");
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);

    setPage(1); // Reset to the first page when page size changes

    handleFilterClose();
  };

  // Handle sort close
  const handleSortClose = () => {
    setAnchorElSort(null);
    setOpenSort(false);
  };

  // Open sort menu
  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  // Reset the sort values
  const handleSortResetAll = () => {
    handleSortChange("created_at", "ASC");
  };

  // Handle sort change
  const handleSortChange = (column, order) => {
    setSelectedSortColumn(column);
    setSelectedSortOrder(order);

    setPage(1); // Reset to the first page when page size changes

    handleSortClose();
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };
  // Apply sorting
  const handleSortApplyNow = (selectedSortColumn, selectedSortOrder) => {
    handleSortChange(selectedSortColumn, selectedSortOrder);
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.tblFun}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: "10px" }}>
            <CustomFiltersN
              anchorEl={anchorElFilter}
              open={openFilter}
              handleClose={handleFilterClose}
              mainOptionsList={[
                { value: "name", label: "Name", type: "search" },
                { value: "trade_mark", label: "Trademark", type: "search" },
                {
                  value: "status",
                  label: "Status",
                  type: "select",
                  value_options_list: projectStatusFilter,
                },
              ]}
              selectedMainOption={selectedSearchField}
              selectedMainOptionValue={searchFieldValue}
              handleFilterClick={handleFilterClick}
              onReset={handleFilterResetAll}
              onApplyNow={handleFilterApplyNow}
            />
          </Box>
          <Box sx={{ mr: "10px" }}>
            <CustomSorting
              anchorEl={anchorElSort}
              open={openSort}
              handleClose={handleSortClose}
              sortOptionsList={[
                { value: "name", label: "Name" },
                { value: "status", label: "Status" },

                { value: "created_at", label: "Created At" },
                { value: "updated_at", label: "Updated At" },
              ]}
              selectedSortColumn={selectedSortColumn}
              selectedSortOrder={selectedSortOrder}
              handleSortClick={handleSortClick}
              onReset={handleSortResetAll}
              onApplyNow={handleSortApplyNow}
            />
          </Box>
          <CustomButton
            size={"small"}
            variant={"contained"}
            btnName={"Create"}
            handleOnClick={handleCreateProjectClick}
            btnStyle={{
              ml: 2,
            }}
            startIcon={<AddIcon />}
          />
        </Box>
      </Box>

      {projectDetails.length > 0 ? (
        <Box sx={{ mt: "20px" }}>
          <Box sx={styles.secondaryBox}>
            {boxes?.map((box, index) => {
              return (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        handleBoxAction(box?.project_id, "recreate")
                      }
                    >
                      <RepeatIcon />
                    </IconButton>
                  </Box>
                  <Box display="flex" key={index} sx={styles.dataBox}>
                    <FrontBox
                      profileDetails={profileDetails}
                      boxes={box}
                      handleBoxAction={handleBoxAction}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              hasMore={hasMore}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            mt: 12,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontWeight: "bold",
            }}
          >
            No Projects Found
          </Typography>
        </Box>
      )}

      {openCreateProject && (
        <CreateProject open={openCreateProject} close={setOpenCreateProject} />
      )}

      {openReCreateProject && (
        <ReCreateProject
          open={openReCreateProject}
          close={setOpenReCreateProject}
          recreateProject={recreateProject}
        />
      )}

      {openDialog && (
        <Dialog
          open={openDialog}
          fullWidth
          fullScreen
          maxWidth="xl"
          PaperProps={{ backgroundColor: "#adacac" }}
          TransitionComponent={Slide}
          transitionDuration={100}
          TransitionProps={{
            direction: "up",
          }}
        >
          <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
            <Typography fontWeight="bold" variant="h6">
              Project ID: {dialogData?.project_id}
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleCloseDialog()}
            sx={styles.IconButton}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={styles.dialogContent}>
            <ViewDialog boxes={dialogData} profileDetails={profileDetails} />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default ProjectConfig;

const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
  },
  tblFun: {
    mx: 2,
    mb: 2,
    mt: 2,
    display: "flex",

    justifyContent: "flex-end",
  },
  mainBox: { paddingBottom: "50px" },
  secondaryBox: {
    display: "flex",
    flexWrap: "wrap",
    mt: "20px",
  },
  dataBox: {
    cursor: "pointer",
    ml: "20px",
    mb: "20px",
  },
  dialogTitle: { m: 0, p: 2, boxShadow: "3" },
  IconButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "red",
  },
  dialogContent: { backgroundColor: "#f2f5fc ", padding: 2 },
};
