/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Reusable searchable text field component.
 */

import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Reusable searchable text field component.
 * @param { size, label, placeholder, value, setValue, handleClick }
 * @return The rendered searchable text field.
 */
function TableSearchTextField({
  size, 
  label, 
  placeholder, 
  value, 
  handleChange, 
  handleClick, 
  selectOptions = [], 
  selectLabel, 
  selectedOption, 
  handleSelectChange
}) {
  // Handles change events for the text field.
  // const handleChange = (e) => {
  //   setValue(e.target.value);
  // };

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <FormControl size={size} style={{ marginRight: 8, minWidth: 120 }}>
        <InputLabel>{selectLabel}</InputLabel>
        <Select
          value={selectedOption}
          onChange={handleSelectChange}
          label={selectLabel}
        >
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        type="search"
        size={size}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleClick();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default TableSearchTextField;
