/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : For Link software modules to software modules.
 */
import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomButton, SelectTextFieldN } from "../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, labCar } from "../../redux/actions";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import appTheme from "../../assets/AppTheme/appTheme";
import customConsole from "../../config/customConsole";
import EEComponentsTable from "./EEComponentsTable";
import softwareIntersectionAPI from "../../redux/actions/softwareIntersectionActions";
import SoftwareModuleTables from "./SoftwareModuleTables";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : Page for handling the software modules linking.
 * @param : selectedProjectId, selectedProjectIsZonal, selectedProjectZonesList, selectedProjectName, selectedProjectArchitecture, selectedProjectTradeMark, selectedProjectVehiclePlatform, isLinkSoftwareModulesScreen, setIsLinkSoftwareModulesScreen,
 * @return : The rendered Link Software Modules page.
 */
function VLcLinkSWModules({
  selectedProjectId,
  selectedProjectIsZonal,
  selectedProjectZonesList,
  selectedProjectName,
  selectedProjectArchitecture,
  selectedProjectTradeMark,
  selectedProjectVehiclePlatform,
  selectedProjectTotalZones,
  selectedProjectTotalEEComponents,
  isLinkSoftwareModulesScreen,
  setSelectedView,
  setIsLinkSoftwareModulesScreen,
}) {
  /* Dispatch */
  const dispatch = useDispatch();

  /* Get data from the redux store  */
  const { eeComponentsMainList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { eeComponentsLinkedList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { eeComponentsMainLinkedList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { swModulesMainList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { swModulesLinkedList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  // Sw Intersections
  const { softwareIntersection } = useSelector(
    (state) => state.softwareToSoftwareIntersectionReducer,
    shallowEqual
  );
  const [softwareIntersectionDetails, setSoftwareIntersectionDetails] =
    useState([]);

  /* Zone Main */
  const [zoneMainOptionsList, setZoneMainOptionsList] = useState([]);
  const [selectedZoneMain, setSelectedZoneMain] = useState(null);

  /* Zone Linked */
  const [zoneLinkedOptionsList, setZoneLinkedOptionsList] = useState([]);
  const [selectedZoneLinked, setSelectedZoneLinked] = useState(null);

  /* EE Components Main */
  const [eeMainOptionsList, setEEMainOptionsList] = useState([]);
  const [selectedEEMainId, setSelectedEEMainId] = useState(null);

  /* EE Components Linked */
  const [eeLinkedOptionsList, setEELinkedOptionsList] = useState([]);
  const [selectedEELinkedId, setSelectedEELinkedId] = useState(null);

  /* EE Components Main Linked */
  const [eeMainLinkedOptionsList, setEEMainLinkedOptionsList] = useState([]);

  /* EE - SW Module Main */
  const [eeMainSWModulesList, setEEMainSWModulesList] = useState([]);

  /* EE - SW Module Linked */
  const [eeLinkedSWModulesList, setEELinkedSWModulesList] = useState([]);

  /* EE - SW Module Main Selected */
  const [selectedMainSWModuleDetails, setSelectedMainSWModuleDetails] =
    useState(null);

  // Main Software Modules Pagination
  const [pageMain, setPageMain] = useState(1);
  const [rowsPerPageMain, setRowsPerPageMain] = useState(25);
  const [hasMoreMain, setHasMoreMain] = useState(false);

  // Linked Software Modules Pagination
  const [pageLinked, setPageLinked] = useState(1);
  const [rowsPerPageLinked, setRowsPerPageLinked] = useState(25);
  const [hasMoreLinked, setHasMoreLinked] = useState(false);

  /* Dispatch Functions */
  const getEEMainComponentsList = () => {
    if (selectedProjectId && selectedZoneMain) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_main,
          params: {
            project_id: selectedProjectId,
            zone_sequence: selectedZoneMain,
          },
          show_toast: false,
        })
      );
    }
  };

  const getEELinkedComponentsList = () => {
    if (selectedProjectId && selectedZoneLinked) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_linked,
          params: {
            project_id: selectedProjectId,
            zone_sequence: selectedZoneLinked,
          },
          show_toast: false,
        })
      );
    }
  };

  const getEEMainLinkedComponentsList = () => {
    if (selectedProjectId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_main_linked,
          params: {
            project_id: selectedProjectId,
          },
          show_toast: false,
        })
      );
    }
  };

  /* useEffects */
  useEffect(() => {
    /* Make to default state when the isLinkSoftwareModulesScreen is changed */
    /* Zone Main */
    setZoneMainOptionsList([]);
    setSelectedZoneMain(null);
    /* Zone Linked */
    setZoneLinkedOptionsList([]);
    setSelectedZoneLinked(null);
    /* EE Main */
    setEEMainOptionsList([]);
    setSelectedEEMainId(null);
    /* EE Linked */
    setEELinkedOptionsList([]);
    setSelectedEELinkedId(null);
    /* EE Main Linked */
    setEEMainLinkedOptionsList([]);
    /* EE Main SW */
    setEEMainSWModulesList([]);
    /* EE Linked SW */
    setEELinkedSWModulesList([]);
    /* Selected SW Module */
    setSelectedMainSWModuleDetails(null);
  }, [isLinkSoftwareModulesScreen]);

  useEffect(() => {
    if (selectedProjectIsZonal) {
      /* Zone Main */
      let filteredZoneMainOptionsList = selectedProjectZonesList
        ?.map((el) => {
          return {
            value: el?.sequence,
            label: el?.zone_name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setZoneMainOptionsList(filteredZoneMainOptionsList);

      /* Zone Linked */
      let filteredZoneLinkedOptionsList = selectedProjectZonesList
        ?.map((el) => {
          return {
            value: el?.sequence,
            label: el?.zone_name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setZoneLinkedOptionsList(filteredZoneLinkedOptionsList);
    }
  }, [selectedProjectZonesList]);

  useEffect(() => {
    /* Select first zone linked by default */
    if (selectedProjectIsZonal && zoneMainOptionsList[0]?.value) {
      setSelectedZoneMain(zoneMainOptionsList[0]?.value);
    }
  }, [zoneMainOptionsList]);

  useEffect(() => {
    /* Select first zone linked by default */
    if (selectedProjectIsZonal && zoneLinkedOptionsList[0]?.value) {
      setSelectedZoneLinked(zoneLinkedOptionsList[0]?.value);
    }
  }, [zoneLinkedOptionsList]);

  useEffect(() => {
    getEEMainLinkedComponentsList();
  }, [selectedProjectId]);

  useEffect(() => {
    /* EE Main */
    setSelectedEEMainId(null);
    /* EE Main SW */
    setEEMainSWModulesList([]);

    getEEMainComponentsList();
  }, [selectedZoneMain]);

  useEffect(() => {
    /* EE Linked */
    setSelectedEELinkedId(null);
    /* EE Linked SW */
    setEELinkedSWModulesList([]);

    getEELinkedComponentsList();
  }, [selectedZoneLinked]);

  useEffect(() => {
    if (eeComponentsMainList) {
      let filteredEEMainOptionsList = eeComponentsMainList
        ?.map((el) => {
          return {
            value: el?.ee_id,
            label: el?.description,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setEEMainOptionsList(filteredEEMainOptionsList);
    }
  }, [eeComponentsMainList]);

  useEffect(() => {
    /* Select first ee component main by default */
    if (selectedProjectIsZonal && eeMainOptionsList[0]?.value) {
      setSelectedEEMainId(eeMainOptionsList[0]?.value);
    }
  }, [eeMainOptionsList]);

  useEffect(() => {
    if (eeComponentsLinkedList) {
      let filteredEELinkedOptionsList = eeComponentsLinkedList
        ?.map((el) => {
          return {
            value: el?.ee_id,
            label: el?.description,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setEELinkedOptionsList(filteredEELinkedOptionsList);
    }
  }, [eeComponentsLinkedList]);

  useEffect(() => {
    /* Select first ee component linked by default */
    if (selectedProjectIsZonal && eeLinkedOptionsList[0]?.value) {
      setSelectedEELinkedId(eeLinkedOptionsList[0]?.value);
    }
  }, [eeLinkedOptionsList]);

  useEffect(() => {
    if (eeComponentsMainLinkedList) {
      let filteredEEMainLinkedOptionsList = eeComponentsMainLinkedList
        ?.map((el) => {
          return {
            value: el?.ee_id,
            label: el?.description,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setEEMainLinkedOptionsList(filteredEEMainLinkedOptionsList);
    }
  }, [eeComponentsMainLinkedList]);

  useEffect(() => {
    /* Select first ee component main/linked by default */
    if (!selectedProjectIsZonal && eeMainLinkedOptionsList[0]?.value) {
      setSelectedEEMainId(eeMainLinkedOptionsList[0]?.value);
      setSelectedEELinkedId(eeMainLinkedOptionsList[0]?.value);
    } else if (!selectedProjectIsZonal) {
      setSelectedEEMainId(null);
      setSelectedEELinkedId(null);
    }
  }, [eeMainLinkedOptionsList]);

  const getMainSWModulesList = () => {
    if (selectedProjectId && selectedEEMainId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_main_sw_modules,
          params: {
            project_id: selectedProjectId,
            ee_id: selectedEEMainId,
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    /* Selected SW Module */
    setSelectedMainSWModuleDetails(null);

    getMainSWModulesList();
  }, [selectedProjectId, selectedEEMainId]);

  const getLinkedSWModulesList = () => {
    if (selectedProjectId && selectedEELinkedId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_linked_sw_modules,
          params: {
            project_id: selectedProjectId,
            ee_id: selectedEELinkedId,
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    getLinkedSWModulesList();
  }, [selectedProjectId, selectedEELinkedId]);

  useEffect(() => {
    if (swModulesMainList && selectedEEMainId) {
      setEEMainSWModulesList(swModulesMainList);
    }
  }, [swModulesMainList]);

  // useEffect(() => {
  //   /* Select first software module main by default */
  //   if (!selectedMainSWModuleDetails && eeMainSWModulesList[0]) {
  //     setSelectedMainSWModuleDetails(swModulesMainList[0]);
  //   }
  // }, [eeMainSWModulesList]);

  useEffect(() => {
    if (
      swModulesLinkedList &&
      selectedEELinkedId &&
      selectedMainSWModuleDetails &&
      selectedMainSWModuleDetails?.swm_id
    ) {
      let filteredSWModulesLinkedList = swModulesLinkedList?.filter(
        (el) => el?.swm_id != selectedMainSWModuleDetails?.swm_id
      );
      setEELinkedSWModulesList(filteredSWModulesLinkedList);
    } else if (swModulesLinkedList && selectedEELinkedId) {
      setEELinkedSWModulesList(swModulesLinkedList);
    }
  }, [swModulesLinkedList, selectedMainSWModuleDetails]);

  useEffect(() => {
    customConsole("zoneMainOptionsList: " + zoneMainOptionsList);
    customConsole("selectedZoneMain: " + selectedZoneMain);
    customConsole("zoneLinkedOptionsList: " + zoneLinkedOptionsList);
    customConsole("selectedZoneLinked: " + selectedZoneLinked);
    customConsole("eeMainOptionsList: " + eeMainOptionsList);
    customConsole("selectedEEMainId: " + selectedEEMainId);
    customConsole("eeLinkedOptionsList: " + eeLinkedOptionsList);
    customConsole("selectedEELinkedId: " + selectedEELinkedId);
    customConsole("eeMainLinkedOptionsList: " + eeMainLinkedOptionsList);
    customConsole("eeMainSWModulesList: " + eeMainSWModulesList);
    customConsole("eeLinkedSWModulesList: " + eeLinkedSWModulesList);
    customConsole(
      "selectedMainSWModuleDetails: " + selectedMainSWModuleDetails
    );
    customConsole("eeComponentsMainList: " + eeComponentsMainList);
    customConsole("eeComponentsLinkedList: " + eeComponentsLinkedList);
    customConsole("eeComponentsMainLinkedList: " + eeComponentsMainLinkedList);
  }, [
    zoneMainOptionsList,
    selectedZoneMain,
    zoneLinkedOptionsList,
    selectedZoneLinked,
    eeMainOptionsList,
    selectedEEMainId,
    eeLinkedOptionsList,
    selectedEELinkedId,
    eeMainLinkedOptionsList,
    eeMainSWModulesList,
    eeLinkedSWModulesList,
    selectedMainSWModuleDetails,
    eeComponentsMainList,
    eeComponentsLinkedList,
    eeComponentsMainLinkedList,
  ]);

  /* Sw Intersections */
  const getSWModulesIntersectionsList = ({ projectId, eeId, swmId }) => {
    if (projectId && eeId) {
      dispatch(
        HandleApiActions({
          ...softwareIntersectionAPI.get_software_intersections,
          params: {
            project_id: Number(projectId),
            ee_id: Number(eeId),
            ...(swmId && { swm_id: Number(swmId) }),
          },
          show_toast: false,
        })
      );
    }
  };

  /* -------------- Handle Sw Module Link/UnLink functions -------------- */
  const handleSWModuleLink = (selectedLinkedSWModuleDetails) => {
    let currentTime = new Date().toISOString();

    if (
      selectedProjectId &&
      selectedMainSWModuleDetails &&
      selectedMainSWModuleDetails?.ee_id &&
      selectedMainSWModuleDetails?.swm_id &&
      selectedLinkedSWModuleDetails?.ee_id &&
      selectedLinkedSWModuleDetails?.swm_id
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.link_sw_module,
          params: {
            project_id: Number(selectedProjectId),
            parent: {
              ee_id: Number(selectedMainSWModuleDetails?.ee_id),
              swm_id: Number(selectedMainSWModuleDetails?.swm_id),
            },
            child: {
              ee_id: Number(selectedLinkedSWModuleDetails?.ee_id),
              swm_id: Number(selectedLinkedSWModuleDetails?.swm_id),
            },
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getMainSWModulesList();
        getLinkedSWModulesList();

        getSWModulesIntersectionsList({
          projectId: Number(selectedProjectId),
          eeId: Number(selectedMainSWModuleDetails?.ee_id),
          swmId: Number(selectedMainSWModuleDetails?.swm_id),
        });
      });
    }
  };

  const handleSWModuleUnLink = (selectedLinkedSWModuleDetails) => {
    let currentTime = new Date().toISOString();

    if (
      selectedProjectId &&
      selectedMainSWModuleDetails &&
      selectedMainSWModuleDetails?.ee_id &&
      selectedMainSWModuleDetails?.swm_id &&
      selectedLinkedSWModuleDetails?.swm_id
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.unlink_sw_module,
          params: {
            project_id: Number(selectedProjectId),
            ee_id: Number(selectedMainSWModuleDetails?.ee_id),
            swm_id: Number(selectedMainSWModuleDetails?.swm_id),
            removing_swm_id: Number(selectedLinkedSWModuleDetails?.swm_id),
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getMainSWModulesList();
        getLinkedSWModulesList();

        getSWModulesIntersectionsList({
          projectId: Number(selectedProjectId),
          eeId: Number(selectedMainSWModuleDetails?.ee_id),
          swmId: Number(selectedMainSWModuleDetails?.swm_id),
        });
      });
    }
  };

  /* Sw Intersections */
  useEffect(() => {
    getSWModulesIntersectionsList({
      projectId: Number(selectedProjectId),
      eeId: Number(selectedEEMainId),
      swmId: Number(selectedMainSWModuleDetails?.swm_id),
    });
  }, [selectedProjectId, selectedEEMainId, selectedMainSWModuleDetails]);

  // console.log("selectedMainSWModuleDetails", selectedMainSWModuleDetails);

  useEffect(() => {
    customConsole({ softwareIntersection });

    setSoftwareIntersectionDetails(softwareIntersection || []);
  }, [softwareIntersection]);

  useEffect(() => {
    customConsole({ softwareIntersectionDetails });
  }, [softwareIntersectionDetails]);

  return (
    <Box sx={styles.mainContainer}>
      {/* Back button */}
      <Box sx={styles.topContainer}>
        <Box sx={styles.backBtnContainer}>
          <CustomButton
            startIcon={<ArrowBackIcon />}
            size={"medium"}
            variant={"contained"}
            btnName={"Back"}
            handleOnClick={() => {
              setIsLinkSoftwareModulesScreen(false);
              setSelectedView("CAR");
            }}
          />
        </Box>

        {/* Link Software modules button */}
        <Typography
          variant="h6"
          fontWeight={"bold"}
          align="center"
          sx={styles.linkSoftwareModulesTxt}
        >
          Link Software modules
        </Typography>
      </Box>

      {/* Project details */}
      <Paper elevation={6} sx={styles.prjDetailsContainer}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
              <span style={styles.txtBold}>Project: </span>
              {selectedProjectName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
              <span style={styles.txtBold}>Architecture: </span>
              {selectedProjectArchitecture}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
              <span style={styles.txtBold}>Trade Mark: </span>
              {selectedProjectTradeMark}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
              <span style={styles.txtBold}>Vehicle Platform: </span>
              {selectedProjectVehiclePlatform}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
              <span style={styles.txtBold}>Zones: </span>
              {selectedProjectIsZonal ? selectedProjectTotalZones : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
              <span style={styles.txtBold}>EE Components: </span>
              {selectedProjectTotalEEComponents}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Software modules linking */}
      {/* <Box sx={{ mt: 4 }}> */}
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={styles.grid}>
            {/* Select fields */}
            <Box sx={{ mb: 3 }}>
              {selectedProjectIsZonal && (
                <SelectTextFieldN
                  size={"small"}
                  label={"Select Zone"}
                  variant={"standard"}
                  optionsList={zoneMainOptionsList}
                  selectedValue={selectedZoneMain}
                  handleOnChange={(e) => setSelectedZoneMain(e.target.value)}
                  sltFldStyle={styles.selectTxtStyle}
                />
              )}
              <SelectTextFieldN
                size={"small"}
                label={"Select EE Component"}
                variant={"standard"}
                optionsList={
                  selectedProjectIsZonal
                    ? eeMainOptionsList
                    : eeMainLinkedOptionsList
                }
                selectedValue={selectedEEMainId}
                disabled={selectedProjectIsZonal ? !selectedZoneMain : false}
                handleOnChange={(e) => setSelectedEEMainId(e.target.value)}
                sltFldStyle={{
                  ...styles.selectTxtStyle,
                  ml: 2,
                }}
              />
            </Box>
            {/* Software modules card */}
            <Card sx={styles.card}>
              <CardHeader
                sx={styles.cardHeader}
                title={
                  <Typography
                    textAlign={"center"}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    {"Software Modules"}
                  </Typography>
                }
              />
              <CardContent sx={styles.cardContent}>
                {eeMainSWModulesList && eeMainSWModulesList?.length > 0 ? (
                  eeMainSWModulesList?.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => setSelectedMainSWModuleDetails(item)}
                      sx={{
                        ...styles.cardItems,
                        border:
                          selectedMainSWModuleDetails &&
                          selectedMainSWModuleDetails?.swm_id == item?.swm_id
                            ? "2px solid blue"
                            : "1px solid lightgrey",
                        cursor: "pointer",
                        "&:hover": {
                          // backgroundColor: "lightgrey",
                        },
                      }}
                    >
                      <Box sx={styles.cardItemsTxtContainer}>
                        <Typography variant="body2">
                          <strong>Name:</strong> {item?.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {item?.description}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Version:</strong> {item?.version}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1" sx={styles.noDataAvailableTxt}>
                    No data available
                  </Typography>
                )}
              </CardContent>
              <CardActions sx={styles.cardActions}></CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.grid}>
            {/* Select fields */}
            <Box sx={{ mb: 3 }}>
              {selectedProjectIsZonal && (
                <SelectTextFieldN
                  size={"small"}
                  label={"Select Zone"}
                  variant={"standard"}
                  optionsList={zoneLinkedOptionsList}
                  selectedValue={selectedZoneLinked}
                  handleOnChange={(e) => setSelectedZoneLinked(e.target.value)}
                  sltFldStyle={styles.selectTxtStyle}
                />
              )}
              <SelectTextFieldN
                size={"small"}
                label={"Select EE Component"}
                variant={"standard"}
                optionsList={
                  selectedProjectIsZonal
                    ? eeLinkedOptionsList
                    : eeMainLinkedOptionsList
                }
                selectedValue={selectedEELinkedId}
                disabled={selectedProjectIsZonal ? !selectedZoneLinked : false}
                handleOnChange={(e) => setSelectedEELinkedId(e.target.value)}
                sltFldStyle={{
                  ...styles.selectTxtStyle,
                  ml: 2,
                }}
              />
            </Box>
            {/* Software modules card */}
            <Card sx={styles.card}>
              <CardHeader
                sx={styles.cardHeader}
                title={
                  <Typography
                    textAlign={"center"}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    {"Linked To"}
                  </Typography>
                }
              />
              <CardContent sx={styles.cardContent}>
                {eeLinkedSWModulesList && eeLinkedSWModulesList?.length > 0 ? (
                  eeLinkedSWModulesList?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        ...styles.cardItems,
                        border: item?.linked_swm_ids?.includes(
                          selectedMainSWModuleDetails?.swm_id
                        )
                          ? "2px solid green"
                          : "1px solid lightgrey",
                        // cursor: "pointer",
                        "&:hover": {
                          // backgroundColor: "lightgrey",
                        },
                      }}
                    >
                      <Box sx={styles.cardItemsTxtContainer}>
                        <Typography variant="body2">
                          <strong>Name:</strong> {item?.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {item?.description}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Version:</strong> {item?.version}
                        </Typography>
                      </Box>
                      <Box>
                        {selectedMainSWModuleDetails && (
                          <Tooltip
                            title={
                              item?.linked_swm_ids?.includes(
                                selectedMainSWModuleDetails?.swm_id
                              )
                                ? "Unlink"
                                : "Link"
                            }
                          >
                            <IconButton
                              size="small"
                              color="info"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  item?.linked_swm_ids?.includes(
                                    selectedMainSWModuleDetails?.swm_id
                                  )
                                ) {
                                  handleSWModuleUnLink(item);
                                } else {
                                  handleSWModuleLink(item);
                                }
                              }}
                            >
                              {item?.linked_swm_ids?.includes(
                                selectedMainSWModuleDetails?.swm_id
                              ) ? (
                                <LinkOffIcon sx={styles.linkOffIcon} />
                              ) : (
                                <LinkIcon sx={styles.linkIcon} />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1" sx={styles.noDataAvailableTxt}>
                    No data available
                  </Typography>
                )}
              </CardContent>
              <CardActions sx={styles.cardActions}></CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {!selectedMainSWModuleDetails && (
        <EEComponentsTable
          selectedProjectId={selectedProjectId}
          selectedEEMainId={selectedEEMainId}
          eeMainLinkedOptionsList={eeMainLinkedOptionsList}
          selectedMainSWModuleDetails={selectedMainSWModuleDetails}
          softwareIntersectionDetails={softwareIntersectionDetails}
        />
      )}
      {selectedMainSWModuleDetails && (
        <Box sx={{ mt: "10px" }}>
          <SoftwareModuleTables
            selectedMainSWModuleDetails={selectedMainSWModuleDetails}
            softwareIntersectionDetails={softwareIntersectionDetails}
            handleSWModuleUnLink={handleSWModuleUnLink}
            handleSWModuleLink={handleSWModuleLink}
            selectedEEMainId={selectedEEMainId}
            selectedProjectId={selectedProjectId}
          />
        </Box>
      )}
    </Box>
  );
}

export default VLcLinkSWModules;

// Styles
const styles = {
  mainContainer: {
    mb: "70px",
    width: "100%",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mx: 1,
  },
  backBtnContainer: {
    display: "flex",
    alignItems: "center",
  },
  prjDetailsContainer: {
    px: 2,
    py: 1,
    mx: 4,
    mt: 2,
  },
  prjDetailsTxt: {
    textAlign: "start",
    pl: 2,
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    mx: 2,
    boxShadow: 12,
    // width: "420px",
    width: "100%",
    maxWidth: "420px",
  },
  cardHeader: {
    pb: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  cardContent: {
    height: "340px",
    overflowY: "auto",
  },
  cardItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
  },
  cardItemsTxtContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  noDataAvailableTxt: {
    textAlign: "center",
    mt: 2,
  },
  linkOffIcon: {
    color: "#FF5757",
  },
  linkIcon: {
    color: "green",
  },
  selectTxtStyle: {
    width: "190px",
    mt: 1,
  },
  txtBold: {
    fontWeight: "bold",
  },
  linkSoftwareModulesTxt: {
    flexGrow: 1,
  },
};
