/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 08-07-2024
 * @description Reusable table withe multiple filter and sorting options
 */
import { styled, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import React from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1": {
    display: "block",
  },
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  // Disable the data-grid pagination footer
  "& .MuiDataGrid-footerContainer": {
    display: "none !important",
  },
}));

const CustomToolbar = ({ heading }) => (
  <GridToolbarContainer>
    <GridToolbar
      printOptions={{
        disableToolbarButton: true, // Disable the print option
      }}
    />
    {heading && <Typography sx={styles.tableHeading}>{heading}</Typography>}
  </GridToolbarContainer>
);

function CustomDataGridTableN({
  tableRowsData,
  columns,
  handleTableRowClick,
  isMultiLine = false,
  tableHeading,
}) {
  return (
    <StyledDataGrid
      localeText={{ toolbarDensity: "View" }}
      // autoHeight={true}
      rows={tableRowsData}
      columns={columns}
      getRowHeight={isMultiLine ? (params) => "auto" : undefined}
      // autoPageSize
      disableColumnMenu
      // disableDensitySelector={isMultiLine ? (params) => "auto" : undefined}
      // disableColumnSorting
      disableColumnSelector
      disableColumnFilter
      disableRowSelectionOnClick
      // slots={{ toolbar: GridToolbar }}
      slots={{ toolbar: () => <CustomToolbar heading={tableHeading} /> }}
      slotProps={{
        toolbar: { printOptions: { disableToolbarButton: true } },
      }}
      onRowClick={(params) => handleTableRowClick({ params })}
      sx={{
        "& .MuiDataGrid-cell": {
          ...(isMultiLine && {
            py: 1, // Add padding to cells
          }),
          borderBottom: "1px solid #e0e0e0", // Add border to cells
        },
      }}
    />
  );
}

export default CustomDataGridTableN;

// Styles
const styles = {
  tableHeading: {
    fontSize: "18px",
    fontWeight: "bold",
    flexGrow: 1,
  },
};
