/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 05-09-2024
 * @description : Component to add, update and replace ee component.
 */

import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import SoftwareModuleTables from "./SoftwareModuleTables";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions";
import softwareIntersectionAPI from "../../redux/actions/softwareIntersectionActions";

function EEComponentsTable({
  selectedEEMainId,
  eeComponentsMainLinkedList,

  softwareIntersectionDetails,
}) {
  const [filteredEE, setFilteredEE] = useState({});

  useEffect(() => {
    console.log(
      "softwareIntersectionDetailsfdfheihvkj",
      softwareIntersectionDetails
    );
  }, [softwareIntersectionDetails]);

  useEffect(() => {
    const filteredList = eeComponentsMainLinkedList?.find(
      (data) => data?.ee_id === selectedEEMainId
    );
    console.log("filteredList", filteredList);
    setFilteredEE(filteredList);
  }, [selectedEEMainId]);

  console.log("selectedEEMainId", selectedEEMainId);

  return (
    <div>
      <Box
        sx={{
          mt: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          border: "1px solid black",
          borderRadius: "4px", // optional, for rounded corners
          boxShadow: "none", // remove shadow if you want a clean border look
        }}
      >
        {filteredEE && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              boxShadow: "none",
              mt: "20px",
              padding: "10px",
              borderBottom: "1px solid black",
            }}
          >
            <Paper
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Selected EE Component Name
              </Typography>
              <strong>:</strong>
              <Typography>{filteredEE?.description}</Typography>
            </Paper>
            <Paper
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Version</Typography>
              <strong>:</strong>
              <Typography> {filteredEE?.version}</Typography>
            </Paper>
          </Box>
        )}

        <Box
          sx={{
            mt: "10px",
            maxHeight: "400px",
            overflowY: "auto",
            border: "1px solid black",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ borderCollapse: "collapse", width: "100%" }}
          >
            {/* <TableHead>
              <TableRow>
                <TableCell
                  sx={{ border: "1px solid black", textAlign: "center" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid black", textAlign: "center" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Software Modules
                  </Typography>
                </TableCell>
                <TableCell
                  colSpan={3}
                  sx={{ border: "1px solid black", textAlign: "center" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Linked Software Modules
                  </Typography>
                </TableCell>
              </TableRow>

              <TableCell sx={{ border: "1px solid black" }} />
              <TableCell sx={{ border: "1px solid black" }} />
              <TableCell
                sx={{
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Software Module
                  </Typography>
                </Box>
                <Box sx={{ border: "1px solid black", textAlign: "center" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    EE Component
                  </Typography>
                </Box>
                <Box sx={{ border: "1px solid black", textAlign: "center" }}>
                  <Typography sx={{ fontWeight: "bold" }}>Zone</Typography>
                </Box>
              </TableCell>
            </TableHead> */}
            {/* <TableHead> */}
            <TableRow>
              <TableCell
                sx={{ border: "1px solid black", textAlign: "center" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
              </TableCell>
              <TableCell
                sx={{ border: "1px solid black", textAlign: "center" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Software Modules
                </Typography>
              </TableCell>
              <TableCell
                colSpan={3}
                sx={{ border: "1px solid black", textAlign: "center" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Linked Software Modules
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ border: "1px solid black" }} />
              <TableCell sx={{ border: "1px solid black" }} />
              <TableCell sx={{ border: "1px solid black" }}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Software Module
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    EE Component
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontWeight: "bold" }}>Zone</Typography>
                </Box>
              </TableCell>
            </TableRow>
            {/* </TableHead> */}

            <TableBody>
              {softwareIntersectionDetails?.length > 0 ? (
                softwareIntersectionDetails?.map((module, idx) => (
                  <>
                    {module?.linked_swm_details?.length > 0 ? (
                      module?.linked_swm_details.map(
                        (linkedModule, linkedIdx) => (
                          <>
                            <TableRow>
                              {/* __________________ */}
                              {linkedIdx == 0 && (
                                <>
                                  <TableCell
                                    rowSpan={module?.linked_swm_details?.length}
                                    sx={{
                                      border: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    {idx + 1} {}
                                  </TableCell>

                                  <TableCell
                                    rowSpan={module?.linked_swm_details?.length}
                                    sx={{
                                      border: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "4px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography sx={{ fontWeight: "bold" }}>
                                          Name:
                                        </Typography>
                                        <Typography sx={{ ml: 1 }}>
                                          {module?.name}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography sx={{ fontWeight: "bold" }}>
                                          Version:
                                        </Typography>
                                        <Typography sx={{ ml: 1 }}>
                                          {module?.version}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </>
                              )}

                              {/* ______________ */}

                              <TableCell sx={{ border: "1px solid black" }}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Name:
                                </Typography>
                                <Typography>{linkedModule?.name}</Typography>

                                <Typography sx={{ fontWeight: "bold" }}>
                                  Version:
                                </Typography>
                                <Typography sx={{ ml: 1 }}>
                                  {linkedModule?.version}
                                </Typography>
                              </TableCell>

                              <TableCell sx={{ border: "1px solid black" }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Description:
                                </Typography>
                                <Typography sx={{ ml: 1 }}>
                                  {linkedModule?.ee_details?.description}
                                </Typography>

                                <Typography sx={{ fontWeight: "bold" }}>
                                  Version:
                                </Typography>
                                <Typography sx={{ ml: 1 }}>
                                  {linkedModule?.ee_details?.version}
                                </Typography>
                              </TableCell>

                              <TableCell sx={{ border: "1px solid black" }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Name:
                                </Typography>
                                <Typography sx={{ ml: 1 }}>
                                  {linkedModule.zone_details?.zone_name ||
                                    "N/A"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{ textAlign: "center", color: "red" }}
                        >
                          Data Not Found
                        </TableCell>
                      </TableRow>
                    )}

                    {/* <TableCell
                      sx={{ textAlign: "center", border: "1px solid black" }}
                    >
                      <Box
                        sx={{ textAlign: "center", border: "1px solid black" }}
                      >
                        <Typography>Hello</Typography>
                        <Typography>Hello</Typography>
                      </Box>
                      <Typography>Hello</Typography>
                      <Typography>Hello</Typography>
                      <Typography>Hello</Typography>
                    </TableCell>

                    <TableCell
                      sx={{ textAlign: "center", border: "1px solid black" }}
                    >
                      <Box
                        sx={{ textAlign: "center", border: "1px solid black" }}
                      >
                        <Typography>Hello</Typography>
                        <Typography>Hello</Typography>
                      </Box>
                      <Typography>Hello</Typography>
                      <Typography>Hello</Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", border: "1px solid black" }}
                    >
                      HEllo
                    </TableCell> */}
                  </>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{ textAlign: "center", color: "red" }}
                  >
                    Data Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </div>
  );
}

export default EEComponentsTable;
