/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This file is to have the Login, signup & forgot password functionality
 */
import React, { useState } from "react";
import { Login, ForgotPassword, ResetPassword } from "../../components";
import { Box } from "@mui/material";
import appMeta from "../../assets/app-meta/appMeta";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import styles from "./authStyle";
import {
  ForgotPasswordAction,
  LoginAction,
  ResetPasswordAction,
} from "../../redux/actions";
import { toast } from "react-toastify";
import useThrottle from "../../hooks/useThrottle";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : Displays the auth page ui.
 * @param : none
 * @return : The rendered authentication page.
 */
function Authentication() {
  const [authView, setAuthView] = useState(100);
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  // Function to change the current authentication view
  const handleAuthViewChange = (newAuthView) => {
    setAuthView(newAuthView);
  };

  // Function to handle login
  const handleLogin = ({ email, password, rememberMe }) => {
    dispatch(LoginAction({ email, password, storage: rememberMe })).then(
      (res) => {
        if (!res?.error) {
          toast.success(res?.msg);
        } else {
          toast.error(res?.msg);
        }
      }
    );
  };
  // Throttle the login function to prevent rapid calls
  const throttledHandleLogin = useThrottle(handleLogin, 2500);

  // Function to handle forgot password
  const handleForgotPassword = ({ email }) => {
    dispatch(ForgotPasswordAction({ email, handleAuthViewChange })).then(
      (res) => {
        if (!res?.error) {
          toast.success(res?.msg);
        } else {
          toast.error(res?.msg);
        }
      }
    );
  };
  // Throttle the forgot password function to prevent rapid calls
  const throttledHandleForgotPassword = useThrottle(handleForgotPassword, 2500);

  // Function to handle reset password
  const handleResetPassword = ({ otp, password }) => {
    dispatch(ResetPasswordAction({ otp, password, handleAuthViewChange })).then(
      (res) => {
        if (!res?.error) {
          toast.success(res?.msg);
        } else {
          toast.error(res?.msg);
        }
      }
    );
  };
  // Throttle the reset password function to prevent rapid calls
  const throttledHandleResetPassword = useThrottle(handleResetPassword, 2500);

  // If the user is authenticated, navigate to the dashboard
  if (auth.isAuthenticated) {
    return <Navigate to="/dashboard/home" replace={true} />;
    // return <Navigate to="/dashboard/home" replace={true} />;
  }

  return (
    <Box sx={styles.container}>
      {/* Background Image */}
      <Box sx={styles.bgImgContainer}>
        <img height={600} src="auth_bg_car.png" alt="" srcset="" />
      </Box>
      {/* Authentication view */}
      <Box sx={styles.mainContainer}>
        {(() => {
          // Render the appropriate component based on the current authentication view
          switch (authView) {
            case appMeta.authView.LOGIN:
              return (
                <Login
                  handleLogin={handleLogin}
                  throttledHandleLogin={throttledHandleLogin}
                  handleAuthViewChange={handleAuthViewChange}
                />
              );
            case appMeta.authView.FORGOT_PASSWORD:
              return (
                <ForgotPassword
                  handleForgotPassword={handleForgotPassword}
                  throttledHandleForgotPassword={throttledHandleForgotPassword}
                  handleAuthViewChange={handleAuthViewChange}
                />
              );
            case appMeta.authView.RESET_PASSWORD:
              return (
                <ResetPassword
                  handleResetPassword={handleResetPassword}
                  throttledHandleResetPassword={throttledHandleResetPassword}
                  handleAuthViewChange={handleAuthViewChange}
                />
              );
            default:
              return (
                <Login
                  handleLogin={handleLogin}
                  handleAuthViewChange={handleAuthViewChange}
                />
              );
          }
        })()}
      </Box>
    </Box>
  );
}

export default Authentication;
