/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 03-07-2024
 * @description Reducer function for handling user-management related actions and state changes.
 */
import actionType from "../actionTypes";

const initialState = {
  usersList: [],
  rolesList: [],
  featuresList: [],
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_COUNTRY_CODES:
      return {
        ...state,
        countryCodes: action.payload.error ? [] : action.payload.data,
      }
    case actionType.GET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload.error ? [] : action.payload.data,
      };
    case actionType.GET_ROLES_LIST:
      return {
        ...state,
        rolesList: action.payload.error ? [] : action.payload.data,
      };
    case actionType.GET_FEATURES_LIST:
      return {
        ...state,
        featuresList: action.payload.error ? [] : action.payload.data,
      };
    default:
      return state;
  }
};

export default userManagementReducer;
