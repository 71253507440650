import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";

import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSingleImages,
  HandleApiActions,
} from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";
import SelectTextField from "../../components/text-fields/SelectTextField";
import AddIcon from "@mui/icons-material/Add";
import CustomTextFieldSP from "../../components/text-fields/CustomTextFieldSP";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";

import MultiSelectTextField2 from "../../components/text-fields/MultiSelectTextField2";
import MultiSelectTextField3 from "../../components/text-fields/MultiSelectTextField3";
import moment from "moment";

import Button from "@mui/material/Button";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import customConsole from "../../config/customConsole";
import { CustomButton, SearchSelectTextField } from "../../components";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import InfoButton from "../../components/infoButton/InfoButton";
function FirstScreen({ close, profileDetails }) {
  //1.Redux
  const dispatch = useDispatch();
  const timeZoneDetails = useSelector(
    (state) => state.countryAndPhoneCodeReducer.countryAndPhoneCode
  );
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );
  const architectureTypesDetails = useSelector(
    (state) => state.adminSettingsReducer
  );
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData
  );
  const vehicleClassDetails = useSelector((state) => state.vehicleClassReducer);

  const projectStatusList = Object.keys(projectStatusDetails?.project_status);
  customConsole("---projectStatusList---", projectStatusList[0]);
  customConsole(
    "---vehiclerStatusDetails---",
    projectStatusDetails?.vehicle_status
  );

  ///2.React useState
  const [vfData, setVfData] = useState([]);
  const [pcData, setPcData] = useState([]);
  const [architectureTypes, setArchitectureTypes] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [vcData, setVcData] = useState([]);
  const [image, setImage] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);

  const [region, setRegion] = useState("");
  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectPS, setSelectPS] = useState("");

  const [name, setName] = useState("");
  const [commercialName, setCommercialName] = useState(name);
  const [manufacturerName, setManufacturerName] = useState("");
  const [manufacturerAddress, setManufacturerAddress] = useState("");
  const [vehiclePlatform, setVehiclePlatform] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");

  const [selectedItemsVf, setSelectedItemsVf] = useState([]);
  const [selectedItemsPc, setSelectedItemsPc] = useState([]);
  const [selectedItemsAt, setSelectedItemsAt] = useState(null);
  const [selectedItemsZone, setSelectedItemsZone] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSubClass, setSelectedSubClass] = useState(null);

  //error useState
  const [nameError, setNameError] = useState(false);
  const [commercialNameError, setCommercialNameError] = useState(false);
  const [manufacturerNameError, setManufacturerNameError] = useState(false);
  const [manufacturerAddressError, setManufacturerAddressError] = useState("");
  const [vehiclePlatformError, setVehiclePlatformError] = useState("");
  const [vehicleStatusError, setVehicleStatusError] = useState("");

  console.log("country", country);
  console.log("region", region);

  console.log("vehicleStatus", vehicleStatus);
  //3.React useEffect
  useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_admin_settings }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
  }, []);

  useEffect(() => {
    customConsole("TimezoneDetails", timeZoneDetails);
    if (timeZoneDetails && timeZoneDetails.length > 0) {
      const sortedRegions = timeZoneDetails?.map((data) => data?.time_zone);

      setRegions(sortedRegions);
      const countryOptions = timeZoneDetails?.map((data) => data?.country);

      setCountries(countryOptions);
    }
  }, [timeZoneDetails]);

  useEffect(() => {
    const projectStatusData = Object.entries(
      projectStatusDetails?.project_status
    ).map(([key, value]) => ({
      label: formatString(key),
      value: value,
    }));
    console.log("----projectStatusData----", projectStatusData);
    setProjectData(projectStatusData);

    const vehicleStatusData = projectStatusDetails?.vehicle_status?.map(
      (el, idx) => ({
        value: el,
        label: formatString(el),
      })
    );

    setVehicleData(vehicleStatusData);
  }, [projectStatusDetails]);

  useEffect(() => {
    customConsole("vehicleFunctionDetails......");

    customConsole(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
    setVfData(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
  }, [vehicleFunctionDetails]);

  useEffect(() => {
    customConsole("vehicleClassDetails......");

    customConsole(vehicleClassDetails?.vehicleClassDetails?.data);
    setVcData(vehicleClassDetails?.vehicleClassDetails?.data);
  }, [vehicleClassDetails]);

  useEffect(() => {
    customConsole("powerTrainDetails");
    customConsole(powerTrainClassDetails?.powerTrainClassDetails?.data);
    setPcData(powerTrainClassDetails?.powerTrainClassDetails?.data);
  }, [powerTrainClassDetails]);

  useEffect(() => {
    customConsole("architectureTypes");
    customConsole(
      architectureTypesDetails?.adminSettings?.data?.architecture_types
    );
    setArchitectureTypes(
      architectureTypesDetails?.adminSettings?.data?.architecture_types
    );
    setZoneData(architectureTypesDetails?.adminSettings?.data?.zone_details);
  }, [architectureTypesDetails]);

  useEffect(() => {
    if (vcData && vcData.length) {
      customConsole("---vcData----");
      customConsole(vcData);
      const imageUrl = async () => {
        const fetchUrlArr = await Promise.all(
          vcData.map(async (item) => {
            customConsole("---Image URL----");
            customConsole(item.image);
            try {
              const response = await GetSingleImages({ image: item.image });
              return { ...item, imageUrl: response };
            } catch (error) {
              customConsole(error);
              return { ...item, imageUrl: "" };
            }
          })
        );
        customConsole("----Fetch Url--");
        customConsole(fetchUrlArr);
        setImage(fetchUrlArr);
      };
      imageUrl();
    }
  }, [vcData]);

  // const timeZones = Array.from(
  //   new Set(timeZoneDetails?.map((item) => item?.time_zone))
  // );

  // const ctry = timeZoneDetails.filter((item) => item?.time_zone === region);

  // customConsole("time zones", timeZones);
  // customConsole("ctry", ctry);

  // const timeZoneOptions = timeZones
  //   ?.map((tz) => ({ value: tz, label: tz }))
  //   .sort((a, b) => {
  //     return a.value.localeCompare(b.value);
  //   });
  // customConsole("timeZoneOptions", timeZoneOptions);

  // const countryOptions = ctry?.map((country) => ({
  //   value: country.country,
  //   label: country.country,
  // }));

  console.log("countries", countries);
  console.log("timeZoneDetails", timeZoneDetails);

  const handleCreate = () => {
    const reqObj = {
      name: name,
      trade_mark: commercialName,
      vehicle_platform: vehiclePlatform,
      status: selectPS,
      ...(!vehicleStatus
        ? {
            vehicle_status: vehicleStatus,
          }
        : null),
      architecture_name: selectedItemsAt,
      mft_details: {
        name: manufacturerName,
        address: manufacturerAddress,
      },
      ...(selectedItemsZone?.length > 0 &&
      selectedItemsAt?.toLowerCase() === "zonal"
        ? { zones: selectedItemsZone }
        : {}),

      vehicle_class: parseInt(selectedClass),

      pwrtrn_class:
        selectedItemsPc && selectedItemsPc.length
          ? selectedItemsPc?.map((str) => parseInt(str))
          : undefined,
      vehicle_funcs: selectedItemsVf?.map((str) => parseInt(str)),
      current_time: moment().format(),
      country: country,
      region: region,
    };

    console.log("reqObj...", reqObj);
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.add_project,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...projectConfigAPI.get_project }));
    });
    close(false);
  };

  customConsole("selectedItemsPc", selectedItemsPc);
  customConsole("selectedItemsZone", selectedItemsZone);

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    customConsole("event", event.target.value);
  };

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }

  useEffect(() => {
    customConsole("---Image---");
    customConsole(image);
  }, [image]);

  useEffect(() => {
    const timeZoneFilter = timeZoneDetails.filter(
      (item) => item?.country === country
    );
    const timeZoneOptions = timeZoneFilter?.map((data) => data?.time_zone);
    console.log("timeZoneOptions...", timeZoneOptions);
    setRegion(timeZoneOptions);
  }, [country]);

  console.log("selectPS", selectPS);
  console.log("region", region);

  return (
    <div>
      <Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontStyle: "italic", mt: "10px" }}>
            Required fields are marked with asterisk *
          </Typography>
          {/* <IconButton>
            <DownloadIcon />
          </IconButton> */}
        </Box>

        {/**/}
        <Box mt="10px" display="flex" justifyContent="space-between">
          <Box sx={{ display: "flex" }}>
            <InfoButton infoKey={"name_of_the_project"} color={"grey"} />
            <CustomTextFieldSP
              size="small"
              placeholder="Name of the Project"
              label="Name of the Project"
              required
              value={name}
              setValue={setName}
              txtFldStyle={{ width: "220px", mr: "10px" }}
              validationRules={{ minLength: 3 }}
              setError={setNameError}
              error={nameError !== ""}
            />
          </Box>

          <Box sx={{ display: "flex" }}>
            <InfoButton infoKey={"trademark"} color={"grey"} />
            <CustomTextFieldSP
              size="small"
              label="Trademark"
              placeholder="Trademark"
              value={commercialName}
              setValue={setCommercialName}
              txtFldStyle={{
                width: "220px",
                mr: "10px",
              }}
              validationRules={{ minLength: 3 }}
              setError={setCommercialNameError}
              error={commercialNameError !== ""}
            />
          </Box>

          <Box
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
              display: "flex",
            }}
          >
            <InfoButton infoKey={"project_status"} color={"grey"} />
            <SelectTextField
              required
              size={"small"}
              label={"Project Status"}
              optionsList={projectData}
              selectedValue={selectPS}
              setSelectedValue={setSelectPS}
              variant={"standard"}
              sltFldStyle={{
                width: "150px",
              }}
            />
            {/* <SearchSelectTextField
              optionsList={projectData}
              selectedValue={selectPS}
              setSelectedValue={setSelectPS}
              size={"small"}
              label={"Project Status*"}
              txtFldStyle={{ width: "200px" }}
            /> */}
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: "20px" }}>
          <InfoButton infoKey={"vehicle_platform"} color={"grey"} />
          <CustomTextFieldSP
            required
            size="small"
            label="Vehicle Platform"
            placeholder="Vehicle Platform"
            value={vehiclePlatform}
            setValue={setVehiclePlatform}
            txtFldStyle={{
              width: "220px",

              mr: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setVehiclePlatformError}
            error={vehiclePlatformError !== ""}
          />
        </Box>
        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          {/* <Box mt="30px">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Choose the type of Architecture*</InputLabel>
              <Select
                value={selectedItemsAt}
                onChange={(e) => setSelectedItemsAt(e.target.value)}
                label="Choose the type of Architecture*"
                displayEmpty
                variant="standard"
              >
                <MenuItem value="" disabled>
                  Select an Architecture
                </MenuItem>
                {architectureTypes?.map((data) => (
                  <MenuItem key={data?.name} value={data?.name}>
                    {data?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}
          <Typography fontWeight={"bold"} mt="10px">
            Select the Type Of Architecture*
          </Typography>
          <Box mt="10px" display="flex">
            <InfoButton infoKey={"type_of_architecture"} color={"grey"} />

            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={selectedItemsAt}
                onChange={(event, newValue) => setSelectedItemsAt(newValue)}
                options={architectureTypes?.map((data) => data.name) || []}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "220px" }}
                    {...params}
                    label="Choose the type of Architecture*"
                    variant="standard"
                    placeholder="Select an Architecture"
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </FormControl>
          </Box>

          {selectedItemsAt &&
          architectureTypes?.find((item) => item?.name === selectedItemsAt)
            ?.is_zones_available ? (
            <Box mt="20px" mb="10px" paddingLeft="20px" paddingRight="20px">
              <Typography fontWeight={"bold"}>
                Select the Zones & Positions*
              </Typography>

              <Box mt="10px">
                <MultiSelectTextField3
                  disabled={!selectedItemsZone}
                  size="medium"
                  label="Select Zones"
                  optionsList={zoneData}
                  selectedValues={selectedItemsZone}
                  setSelectedValues={setSelectedItemsZone}
                  sltFldStyle={{ minWidth: 200 }}
                />
              </Box>
            </Box>
          ) : (
            <Box mb="10px"></Box>
          )}
        </Box>
        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />

        <Box display="flex" justifyContent="space-between">
          <Box sx={{ mt: "20px" }}>
            <Typography fontWeight={"bold"} ml="2px">
              Vehicle Class*
            </Typography>
            <div>
              <Box
                sx={{
                  display: "flex",
                  mt: "10px",
                  width: "400px",
                }}
              >
                {" "}
                <InfoButton infoKey={"vehicle_class"} color={"grey"} />
                <FormControl fullWidth sx={{ width: "400px" }}>
                  <InputLabel>Select Vehicle Class</InputLabel>
                  <Select
                    variant="standard"
                    value={selectedClass}
                    onChange={handleClassChange}
                    sx={{ mb: 2 }}
                    label="Select Vehicle Class"
                  >
                    {image &&
                      image?.map((data, idx) => (
                        <MenuItem key={data?.id} value={data?.id}>
                          <Box display="flex" key={data?.id} value={data?.id}>
                            <Box>
                              <img
                                src={data.imageUrl}
                                alt=""
                                style={{
                                  width: "46px",
                                  height: "46px",
                                  marginRight: "8px",
                                }}
                              />
                            </Box>

                            <Box display="flex" flexDirection="column">
                              <Typography fontWeight="bold">
                                {" "}
                                {data?.name}
                              </Typography>
                              <Typography>
                                {data?.class}-{data?.sub_class}
                              </Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Box>
          {/* <Box sx={{ mt: "20px" }}>
            <Typography fontWeight={"bold"} ml="2px">
              Vehicle Class*
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: "10px",
                width: "400px",
              }}
            >
              <Autocomplete
                value={selectedClass}
                onChange={(event, newValue) => handleClassChange(newValue)}
                options={image || []}
                getOptionLabel={(option) => option?.name || ""}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option?.id}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box>
                      <img
                        src={option.imageUrl}
                        alt=""
                        style={{
                          width: "46px",
                          height: "46px",
                          marginRight: "8px",
                        }}
                      />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="bold">{option?.name}</Typography>
                      <Typography>
                        {option?.class}-{option?.sub_class}
                      </Typography>
                    </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Vehicle Class"
                    variant="standard"
                    fullWidth
                  />
                )}
                sx={{ width: "400px" }}
              />
            </Box>
          </Box> */}

          <Box mt="55px" sx={{ display: "flex" }}>
            <InfoButton infoKey={"vehicle_status"} color={"grey"} />
            <SelectTextField
              size={"small"}
              label={"Vehicle Status"}
              optionsList={vehicleData}
              selectedValue={vehicleStatus}
              setSelectedValue={setVehicleStatus}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
                mr: "10px",
              }}
            />
            {/* <SearchSelectTextField
              optionsList={vehicleData}
              selectedValue={vehicleStatus}
              setSelectedValue={setVehicleStatus}
              size={"small"}
              label={"Vehicle Status"}
              txtFldStyle={{ width: "200px" }}
            /> */}
          </Box>
        </Box>

        <Box sx={{ marginTop: "10px" }}>
          <Box sx={{ mt: "10px", flexGrow: "2" }}>
            <Box sx={{ display: "flex" }}>
              <InfoButton infoKey={"vehicle_function"} color={"grey"} />
              <Typography fontWeight={"bold"} mt="5px">
                Vehicle Function*
              </Typography>
            </Box>

            <MultiSelectTextField2
              mutiple
              size={"small"}
              label={"Select Vehicle Function"}
              optionsList={vfData}
              selectedValues={selectedItemsVf}
              setSelectedValues={setSelectedItemsVf}
              sltFldStyle={{
                textAlign: "start",
                width: "95%",
                ml: "10px",

                backgroundColor: "white",
              }}
            />
          </Box>
          <Box sx={{ mt: "10px", flexGrow: "2" }}>
            <Box sx={{ display: "flex" }}>
              <InfoButton infoKey={"power_train_class"} color={"grey"} />
              <Typography fontWeight={"bold"} mt="5px">
                PowerTrain Class
              </Typography>
            </Box>

            <MultiSelectTextField2
              mutiple
              variant="standard"
              size={"small"}
              label={"Select Powertrain Class [Optional]"}
              optionsList={pcData}
              selectedValues={selectedItemsPc}
              setSelectedValues={setSelectedItemsPc}
              sltFldStyle={{
                width: "95%",
                textAlign: "start",
                ml: "10px",
                mt: "10px",
                backgroundColor: "white",
              }}
            />
          </Box>
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
            }}
          >
            {/* <SelectTextField
              size={"small"}
              label={"Country*"}
              optionsList={countryOptions}
              selectedValue={country}
              setSelectedValue={setCountry}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
              }}
            /> */}
            <SearchSelectTextField
              optionsList={countries}
              selectedValue={country}
              setSelectedValue={setCountry}
              size={"small"}
              label={"Country*"}
              txtFldStyle={{ width: "200px" }}
            />
          </Box>
          <Box sx={{ marginTop: "10px", marginLeft: "10px" }}>
            {/* <SelectTextField
              size={"small"}
              label={"Time Zone*"}
              optionsList={regions}
              selectedValue={region}
              setSelectedValue={setRegion}
              variant={"standard"}
              sltFldStyle={{
                width: "180px",
              }}
            /> */}
            <SearchSelectTextField
              size={"small"}
              label={"Time Zone*"}
              placeholder={"Time Zone*"}
              noOptionsText={"No Time Zone"}
              optionsList={regions}
              selectedValue={region}
              setSelectedValue={setRegion}
              txtFldStyle={{
                width: "180px",
              }}
            />
          </Box>

          {/* <Box
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              flexGrow: "2",
            }}
          >
            <SelectTextField
              size={"small"}
              label={"Country*"}
              optionsList={countryOptions}
              //optionsList={countries}
              selectedValue={country}
              setSelectedValue={setCountry}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
              }}
            />
          </Box> */}
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />

        <Box sx={{ marginTop: "10px" }}>
          <Typography fontWeight={"bold"}>
            Manufacturer Name & Address
          </Typography>
          <Box sx={{ display: "flex" }}>
            <InfoButton infoKey={"manufacturer_name"} color={"grey"} />
            <CustomTextFieldSP
              required
              size="small"
              label="Manufacturer Name"
              placeholder="Manufacturer Name"
              value={manufacturerName}
              setValue={setManufacturerName}
              txtFldStyle={{
                width: "270px",
                marginTop: "10px",
              }}
              validationRules={{ minLength: 3 }}
              setError={setManufacturerNameError}
              error={manufacturerNameError !== ""}
            />
            <InfoButton infoKey={"manufacturer_address"} color={"grey"} />
            <CustomTextFieldSP
              required
              size="small"
              label="Manufacturer Address"
              placeholder="Manufacturer Address"
              value={manufacturerAddress}
              setValue={setManufacturerAddress}
              txtFldStyle={{
                width: "95%",
                mt: "15px",
              }}
              validationRules={{ minLength: 3 }}
              setError={setManufacturerAddressError}
              error={manufacturerAddressError !== ""}
            />
          </Box>
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />
      </Box>
      <Box mt="10px" display="flex" justifyContent="flex-end">
        <CustomButton
          handleOnClick={handleCreate}
          disabled={
            name?.length > 2 &&
            region &&
            country &&
            vehiclePlatform?.length > 2 &&
            selectedItemsVf?.length > 0 &&
            manufacturerName?.length > 2 &&
            selectedItemsAt?.length > 0 &&
            manufacturerAddress?.length > 2
              ? false
              : true
          }
          size="medium"
          btnName={" New Project"}
          variant="contained"
          startIcon={<AddIcon />}
        />
      </Box>
    </div>
  );
}

export default FirstScreen;

const styles = {
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
};
