import actionType from "../actionTypes";
const softwareIntersectionAPI = {
  get_software_intersections: {
    api_name: "/PCM/swm/intersections",
    https_method: "GET",
    action_type: actionType.GET_SOFTWARE_INTERSECTIONS,
    description: "Get the Software Intersections",
  },
};
export default softwareIntersectionAPI;
