import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Paper, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DependenciesDialog({
  swmName,
  open,
  handleClose,
  vehicleFunctions,
  softwareModules,
}) {
  React.useEffect(() => {
    // console.log("-- DependenciesDialog --");
    // console.log({
    //   swmName,
    //   open,
    //   handleClose,
    //   vehicleFunctions,
    //   softwareModules,
    // });
  }, []);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{swmName}</DialogTitle>
      <DialogContent>
        {/* Vehicle Functions */}
        <Paper
          elevation={24}
          sx={{ maxHeight: "250px", padding: "10px", overflow: "auto" }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              marginBottom: "20px",
            }}
          >
            Vehicle Fucntion
          </Typography>
          {vehicleFunctions.map((data, idx) => {
            return (
              <Typography
                key={idx}
                sx={{ fontSize: "14px", marginBottom: "10px" }}
              >
                <strong>{data.abbreviation}</strong> : {data.name}
              </Typography>
            );
          })}
        </Paper>
        {/* Software Modules */}
        {softwareModules.length ? (
          <Paper
            elevation={24}
            sx={{
              marginTop: "15px",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                marginBottom: "20px",
              }}
            >
              SWM Dependencies
            </Typography>
            <Box
              sx={{
                maxHeight: "80vH",
                padding: "10px",
                overflow: "auto",
              }}
            >
              {softwareModules.map((data, idx) => {
                return (
                  <Box
                    sx={{
                      border: "1px solid black",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* Software Modules */}
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "14px",
                        }}
                      >
                        Software Module
                      </Typography>
                      <Box sx={{ gap: "10px" }}>
                        {[
                          { header: "Name", value: data.name },
                          { header: "Version", value: data.version },
                          { header: "Description", value: data.description },
                        ].map((det, key) => {
                          return (
                            <Typography
                              key={key}
                              sx={{
                                fontSize: "12px",
                              }}
                            >
                              <strong>{det.header}:</strong> {det.value}
                            </Typography>
                          );
                        })}
                      </Box>
                    </Box>
                    {/* EE Component */}
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      >
                        EE Component
                      </Typography>
                      <Box sx={{ gap: "10px" }}>
                        {[
                          {
                            header: "Name",
                            value: data.ee_details[0].description,
                          },
                          {
                            header: "Version",
                            value: data.ee_details[0].version,
                          },
                          {
                            header: "Assembly number",
                            value: data.ee_details[0].assembly_number,
                          },
                          {
                            header: "Supplier name",
                            value: data.ee_details[0].supplier_name,
                          },
                        ].map((det, key) => {
                          return (
                            <Typography
                              key={key}
                              sx={{
                                fontSize: "12px",
                              }}
                            >
                              <strong>{det.header}:</strong> {det.value}
                            </Typography>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Paper>
        ) : undefined}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
