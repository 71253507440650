/**
 * @author Bharathan - bharathan@au79consulting.com
 * @date 09-08-2024
 * @description This is the SoftwareToLegal Page in the Link Tab for the Virtual Type Approval Page
 */

// Import required components and functions
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  CustomTextField,
  CustomTextField1,
  MultiSelectTextField,
  SearchSelectTextField,
  SelectTextFieldN,
} from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../redux/actions/actionHandler";
import labCar from "../../../redux/actions/labCar";
import legalRequirements from "../../../redux/actions/legalRequirements";

import userManagementApis from "../../../redux/actions/userManagent";
import MultiSelectTextFieldNN from "../../../components/text-fields/MultiSelectTextFieldNN";
import typeApprovalAPI from "../../../redux/actions/typeApprovalAPI";
import Intersections from "../Intersections/Intersections";
import CloseIcon from "@mui/icons-material/Close";
import appTheme from "../../../assets/AppTheme/appTheme";
import { LuRat } from "react-icons/lu";
import { Divider, Modal, TextField } from "@mui/material";
import InfoButton from "../../../components/infoButton/InfoButton";

//Component to link software modules with required legal standards
function SoftwareToLegalLinks({
  selectedProjectId,
  selectedProjectIsZonal,
  selectedProjectZonesList,
  projectOptionsList,
  selectedProject,
  setSelectedProject,
  setLinkTab,
  selectedProjectDetails,
  setTabValue,
  tabValue,
}) {
  console.log("projectOptionsList", projectOptionsList);
  // Redux dispatch and state selectors
  const dispatch = useDispatch();
  const {
    eeComponentsMainList,
    eeComponentsLinkedList,
    eeComponentsMainLinkedList,
    swModulesMainList,
    swModulesLinkedList,
  } = useSelector((state) => state.labCarReducer, shallowEqual);

  const getLegalRequirementsList = useSelector(
    (state) => state?.legalRequirementsReducer,
    shallowEqual
  );

  const userDetails = useSelector((state) => state.userManagementReducer);
  const roleName = userDetails?.usersList;
  const linkDetails = useSelector((state) => state.typeApprovalReducer);

  // State variables for various dropdowns and lists
  const [zoneMainOptionsList, setZoneMainOptionsList] = useState([]);
  const [selectedZoneMain, setSelectedZoneMain] = useState(null);
  const [zoneLinkedOptionsList, setZoneLinkedOptionsList] = useState([]);
  const [selectedZoneLinked, setSelectedZoneLinked] = useState(null);
  const [eeMainOptionsList, setEEMainOptionsList] = useState([]);
  const [selectedEEMainId, setSelectedEEMainId] = useState(null);
  const [eeLinkedOptionsList, setEELinkedOptionsList] = useState([]);
  const [standardList, setStandardList] = useState([]);
  const [requirementsList, setRequirementsList] = useState([]);
  const [selectedEELinkedId, setSelectedEELinkedId] = useState(null);
  const [eeMainLinkedOptionsList, setEEMainLinkedOptionsList] = useState([]);
  const [eeMainSWModulesList, setEEMainSWModulesList] = useState([]);
  const [eeLinkedSWModulesList, setEELinkedSWModulesList] = useState([]);
  const [selectedLrId, setSelectedLrId] = useState();
  const [lrDescription, setLrDescription] = useState("");
  const [lrDescDialog, setLrDescDialog] = useState(false);
  const [selectedMainSWModuleDetails, setSelectedMainSWModuleDetails] =
    useState(null);
  const [selectedStandards, setSelectedStandards] = useState();
  const [selectedChapter, setSelectedChapter] = useState();

  const [filteredStandards, setFilteredStandards] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const [newCheckedItems, setNewCheckedItems] = useState({});
  const [defaultSelectedZone, setDefaultSelectedZone] = useState(false);
  const [isLinkEnabled, setIsLinkEnabled] = useState(false);
  // Fetch EE Main Components based on selected project and zone
  const fetchEEMainComponents = () => {
    if (selectedProjectId && selectedZoneMain) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_main,
          params: {
            project_id: selectedProjectId,
            zone_sequence: selectedZoneMain,
          },
          show_toast: false,
        })
      );
    }
  };

  // Fetch EE Linked Components based on selected project and zone
  const fetchEELinkedComponents = () => {
    if (selectedProjectId && selectedZoneLinked) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_linked,
          params: {
            project_id: selectedProjectId,
            zone_sequence: selectedZoneLinked,
          },
          show_toast: false,
        })
      );
    }
  };

  // Fetch EE Main Linked Components based on selected project
  const fetchEEMainLinkedComponents = () => {
    if (selectedProjectId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_main_linked,
          params: { project_id: selectedProjectId },
          show_toast: false,
        })
      );
    }
  };

  // Fetch linked SW modules based on selected project and EE Linked ID
  const fetchLinkedSWModules = () => {
    if (selectedProjectId && selectedEELinkedId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_linked_sw_modules,
          params: { project_id: selectedProjectId, ee_id: selectedEELinkedId },
          show_toast: false,
        })
      );
    }
  };

  // Fetch main SW modules based on selected project and EE Main ID
  const fetchMainSWModules = () => {
    if (selectedProjectId && selectedEEMainId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_main_sw_modules,
          params: { project_id: selectedProjectId, ee_id: selectedEEMainId },
          show_toast: false,
        })
      );
    }
  };

  // Clear and reset states on project ID change
  useEffect(() => {
    setZoneMainOptionsList([]);
    setSelectedZoneMain(null);
    setZoneLinkedOptionsList([]);
    setSelectedZoneLinked(null);
    setEEMainOptionsList([]);
    setSelectedEEMainId(null);
    setEELinkedOptionsList([]);
    setSelectedEELinkedId(null);
    setEEMainLinkedOptionsList([]);
    setEEMainSWModulesList([]);
    setEELinkedSWModulesList([]);
    setSelectedMainSWModuleDetails(null);
  }, [selectedProjectId]);

  // Update zone options when project zones change
  useEffect(() => {
    if (selectedProjectIsZonal) {
      const zoneOptions = selectedProjectZonesList
        .map((el) => ({
          value: el?.sequence,
          label: el?.zone_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setZoneMainOptionsList(zoneOptions);
      setZoneLinkedOptionsList(zoneOptions);
    }
  }, [selectedProjectZonesList]);

  // Fetch EE Main Linked Components on project ID change
  useEffect(() => {
    fetchEEMainLinkedComponents();
  }, [selectedProjectId]);

  // Fetch EE Main Components on zone selection change
  useEffect(() => {
    setSelectedEEMainId(null);
    setEEMainSWModulesList([]);
    fetchEEMainComponents();
  }, [selectedZoneMain]);

  // Fetch EE Linked Components on zone selection change
  useEffect(() => {
    setSelectedEELinkedId(null);
    setEELinkedSWModulesList([]);
    fetchEELinkedComponents();
  }, [selectedZoneLinked]);

  // Update EE Main Components options when the list changes
  useEffect(() => {
    if (eeComponentsMainList) {
      const options = eeComponentsMainList
        ?.map((el) => ({
          value: el?.ee_id,
          label: el?.description,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      console.log("options", options);
      setEEMainOptionsList(options);
    }
  }, [eeComponentsMainList]);

  // Update EE Linked Components options when the list changes
  useEffect(() => {
    if (eeComponentsLinkedList) {
      const options = eeComponentsLinkedList
        ?.map((el) => ({
          value: el?.ee_id,
          label: el?.description,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setEELinkedOptionsList(options);
    }
  }, [eeComponentsLinkedList]);

  // Update EE Main Linked Components options when the list changes
  useEffect(() => {
    if (eeComponentsMainLinkedList) {
      const options = eeComponentsMainLinkedList
        .map((el) => ({
          value: el?.ee_id,
          label: el?.description,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setEEMainLinkedOptionsList(options);
    }
  }, [eeComponentsMainLinkedList]);

  // Fetch SW Modules based on EE Main ID and project ID
  useEffect(() => {
    setSelectedMainSWModuleDetails(null);
    fetchMainSWModules();
  }, [selectedProjectId, selectedEEMainId]);

  // Fetch Linked SW Modules based on EE Linked ID and project ID
  useEffect(() => {
    fetchLinkedSWModules();
  }, [selectedProjectId, selectedEELinkedId]);

  // Update EE Main SW Modules list when the main SW modules list changes
  useEffect(() => {
    if (swModulesMainList && selectedEEMainId) {
      setEEMainSWModulesList(swModulesMainList);
    }
  }, [swModulesMainList]);

  // Update EE Linked SW Modules list based on linked SW modules list
  useEffect(() => {
    if (swModulesLinkedList && selectedEELinkedId) {
      const filteredList = selectedMainSWModuleDetails
        ? swModulesLinkedList.filter(
            (el) => el?.swm_id !== selectedMainSWModuleDetails?.swm_id
          )
        : swModulesLinkedList;

      setEELinkedSWModulesList(filteredList);
    }
  }, [swModulesLinkedList, selectedMainSWModuleDetails]);

  //
  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_standard_details,
        params: {
          page_number: 1,
          page_size: 200,
        },
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_requirements_details,
        params: {
          page_number: 1,
          page_size: 200,
        },
      })
    );
  }, []);

  //New Standard List
  useEffect(() => {
    console.log(getLegalRequirementsList, "getLegalRequirementsList");
    if (getLegalRequirementsList?.legalStandardDetails?.length > 0) {
      const options = getLegalRequirementsList?.legalStandardDetails
        ?.map((el) => ({
          value: el?.ls_id,
          label: el?.standard_number,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      console.log("optionsssss", options);
      setStandardList(options);
      setSelectedStandards((prev) => prev || options[0]?.value);
    }
  }, [getLegalRequirementsList, selectedStandards]);

  useEffect(() => {
    if (getLegalRequirementsList?.legalStandardDetails?.length > 0) {
      const filteredChapters =
        getLegalRequirementsList?.legalStandardDetails?.find(
          (el) => el?.ls_id === selectedStandards
        );

      const requiredLists = filteredChapters?.chapters?.map((data) => {
        return {
          value: data,
          label: data,
        };
      });
      console.log(requiredLists, "requiedLists");
      setRequirementsList(requiredLists);
    }
  }, [getLegalRequirementsList, selectedStandards]);

  useEffect(() => {
    if (selectedStandards) {
      const filteredChapters =
        getLegalRequirementsList?.legalStandardDetails?.find(
          (el) => el?.ls_id === selectedStandards
        );

      setSelectedChapter((prev) => prev || filteredChapters?.chapters[0]);
    }
  }, [requirementsList, selectedProject, tabValue]);

  // useEffect(() => {
  //   if (getLegalRequirementsList?.legalRequirementsDetails?.length > 0) {
  //     const filteredStandard =
  //       getLegalRequirementsList?.legalRequirementsDetails?.filter(
  //         (data) => data?.ls_id === selectedStandards
  //       );
  //     setFilteredStandards(filteredStandard);
  //     console.log("filteredItems", filteredStandard);
  //     const chaptersSet = Array.from(
  //       new Set(filteredStandard.map((item) => item.chapter))
  //     ).map((chapter, idx) => ({
  //       value: chapter,
  //       label: chapter, // You can customize the label as needed
  //     }));

  //     console.log("formattedOptions", chaptersSet);
  //     setRequirementsList(chaptersSet);
  //   }
  // }, [getLegalRequirementsList, selectedStandards]);

  // useEffect(() => {
  //   if (selectedChapter) {
  //     console.log("filteredStandards", filteredStandards);
  //     const filteredTag = filteredStandards.filter((data) => {
  //       return data.chapter === selectedChapter;
  //     });
  //     console.log("selectedChapter", selectedChapter);
  //     console.log("filteredTags", filteredTag);
  //     console.log(linkDetails, "linkDetailslinkDetails");
  //     const linkedLrids = linkDetails?.typeApproval?.map((item) => {
  //       console.log(
  //         "linkDetails?.ticketTypeApproval?.map",
  //         parseInt(item?.swm_details?.swm_id),
  //         parseInt(selectedMainSWModuleDetails)
  //       );
  //       return parseInt(item?.swm_details?.swm_id) ==
  //         parseInt(selectedMainSWModuleDetails)
  //         ? item?.lr_details?.lr_id
  //         : undefined;
  //     }).filter((id) => id !== undefined);
  //     console.log(linkedLrids, "linkedLridslinkedLrids");
  //     // filteredTag?.forEach((tag) => {
  //     const checkedItems = [];
  //     filteredTag?.map((tag) => {
  //       if (linkedLrids?.includes(tag.lr_id)) checkedItems.push(tag.lr_id);
  //     });
  //     console.log(filteredTag, "filteredTagfilteredTag");
  //     console.log("selectedMainSWModuleDetails", selectedMainSWModuleDetails);
  //     setFilteredTags(filteredTag);
  //     setCheckedItems(checkedItems);

  //   } else {
  //     setFilteredTags([]);
  //     setCheckedItems([]);
  //   }
  // }, [selectedChapter, selectedMainSWModuleDetails]);

  useEffect(() => {
    if (selectedChapter) {
      const filteredTag =
        getLegalRequirementsList?.legalRequirementsDetails?.filter((data) => {
          return data?.chapter === selectedChapter;
        });
      console.log("selectedChapter", selectedChapter);
      console.log("filteredTags", filteredTag);
      console.log(linkDetails?.typeApproval, "linkDetailslinkDetails");

      const linkedLrids = linkDetails?.typeApproval
        ?.map((item) => {
          setNewCheckedItems((prev) =>
            getLrLinkedStates({
              isChecked: true,
              selectedEEMainId: item?.ee_details?.ee_id,
              isBlocked: true,
              lr_id: item?.lr_details?.lr_id,
              prev: prev,
              selectedMainSWModuleDetails: item?.swm_details?.swm_id,
            })
          );
        })
        ?.filter((id) => id !== undefined);

      console.log(linkedLrids, "linkedLridslinkedLrids");

      const updatedCheckedItems = new Set(checkedItems);
      filteredTag?.forEach((tag) => {
        if (linkedLrids?.includes(tag.lr_id)) {
          updatedCheckedItems.add(tag.lr_id);
        }
      });
      console.log(updatedCheckedItems, "updatedChecked");

      console.log(filteredTag, "filteredTagfilteredTag");

      console.log("selectedMainSWModuleDetails", selectedMainSWModuleDetails);

      setFilteredTags(filteredTag);
      setCheckedItems([...updatedCheckedItems]);
    } else {
      setFilteredTags([]);
      setCheckedItems([]);
    }
  }, [selectedChapter, selectedMainSWModuleDetails]);

  // Handle checkbox change
  // const handleCheckboxChange = (event, lr_id1, description1) => {
  //   console.log(checkedItems);
  //   if (!event.target.checked) {
  //     const index = checkedItems.indexOf(lr_id1);
  //     if (index !== -1) {
  //       checkedItems.splice(index, 1);
  //       console.log(checkedItems);
  //     }
  //   } else {
  //     newCheckedItems.push({ lr_id: lr_id1, description: description1 });
  //     console.log(newCheckedItems, "newCheckedItems");
  //   }
  //   setCheckedItems([...checkedItems]);
  //   // const isChecked = event.target.checked;

  //   // setCheckedItems((prevItems) => {
  //   //   if (isChecked) {
  //   //     // Add the new item to checkedItems if the checkbox is checked
  //   //     return [...prevItems, { lr_id: lr_id1, description: description1 }];
  //   //   } else {
  //   //     // Remove the item from checkedItems if the checkbox is unchecked
  //   //     return prevItems.filter((item) => item.lr_id !== lr_id1);
  //   //   }
  //   // });
  // };
  const handleCheckboxChange = (event, lr_id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedLrId(lr_id);
      setLrDescDialog(true);
    } else {
      addOrRemoveLrId(false, lr_id);
    }
  };

  const submitLrDescription = () => {
    addOrRemoveLrId(true, selectedLrId, lrDescription);
    setLrDescDialog(false);
    setLrDescription("");
  };

  const getLrLinkedStates = ({
    isChecked,
    isBlocked,
    lr_id,
    description,
    selectedEEMainId,
    prev,
    selectedMainSWModuleDetails,
  }) => {
    console.log("prev", prev);
    if (isChecked && lr_id && selectedMainSWModuleDetails) {
      // selectedMainSWModuleDetails
      const swModule = prev?.[selectedMainSWModuleDetails?.toString()] || [];
      console.log("swModule", swModule);
      const selectedLrIdIndex = swModule?.legal?.findIndex(
        (item) => item?.lr_id == lr_id
      );
      console.log("selectedLrIdIndex", selectedLrIdIndex);
      if (selectedLrIdIndex > -1) {
        swModule.blockedLr = [...(swModule.blockedLr || [])];
        if (isBlocked) {
          swModule.blockedLr.push(lr_id);
        } else {
          swModule.legal[selectedLrIdIndex] = {
            lr_id,
            isBlocked,
            description,
          };
        }
        prev[selectedMainSWModuleDetails?.toString()] = swModule;
        return JSON.parse(JSON.stringify(prev));
      } else {
        swModule.legal = [...(swModule.legal || [])];
        swModule.blockedLr = [...(swModule.blockedLr || [])];
        // if (swModule.legal?.length) {
        //   if (isBlocked) {
        //     debugger;
        //     swModule.blockedLr?.length
        //       ? swModule.blockedLr.push(lr_id)
        //       : (swModule.blockedLr = [lr_id]);
        //   } else {
        //     swModule.legal?.push({
        //       lr_id,

        //       description,
        //     });
        //   }
        //   prev[selectedMainSWModuleDetails?.toString()] = swModule;
        //   return JSON.parse(JSON.stringify(prev));
        // } else {
        if (isBlocked) {
          const blockedLr = [...(swModule.blockedLr || []), lr_id];
          prev[selectedMainSWModuleDetails?.toString()] = {
            swm_id: selectedMainSWModuleDetails,
            blockedLr,
            legal: swModule.legal,
            ee_id: swModule?.ee_id,
          };
          return JSON.parse(JSON.stringify(prev));
        } else {
          const legal = [
            ...(swModule.legal || []),
            {
              lr_id,
              description,
            },
          ];
          // swModule["ee_id"] = selectedEEMainId;
          // swModule["swm_id"] = selectedMainSWModuleDetails;
          prev[selectedMainSWModuleDetails?.toString()] = {
            ee_id: selectedEEMainId,
            swm_id: selectedMainSWModuleDetails,
            legal,
            blockedLr: swModule.blockedLr,
          };
          return JSON.parse(JSON.stringify(prev));
        }
        // }
      }
      // newCheckedItems[selectedMainSWModuleDetails?.toString()] = swModule;
      // setNewCheckedItems(JSON.parse(JSON.stringify(newCheckedItems)));
    } else if (!isChecked) {
      const legal = prev?.[
        selectedMainSWModuleDetails?.toString()
      ]?.legal?.filter((item) => item.lr_id !== lr_id);
      if (legal?.length) {
        prev[selectedMainSWModuleDetails?.toString()].legal = legal;
      } else {
        delete prev[selectedMainSWModuleDetails?.toString()];
      }
      return JSON.parse(JSON.stringify(prev));
    }
  };
  const addOrRemoveLrId = (isChecked, lr_id, description) => {
    // setNewCheckedItems
    setNewCheckedItems((prev) => {
      const value = getLrLinkedStates({
        isChecked,
        lr_id,
        selectedEEMainId,
        description,
        prev: prev || {},
        selectedMainSWModuleDetails,
      });
      return value;
    });
  };
  useEffect(() => {
    if (newCheckedItems) {
      console.log(newCheckedItems, "newCheckedItems");
    }
  }, [newCheckedItems]);

  const handleClickLink = () => {
    // const reqObj = {
    //   project_id: Number(selectedProjectId),
    //   ee_id: Number(selectedEEMainId),
    //   swm_id: Number(selectedMainSWModuleDetails),
    //   lr_id: checkedItems,
    // };
    let intersections = Object.entries(newCheckedItems).map((item) => {
      delete item[1]?.blockedLr;
      return item[1];
    });
    intersections = intersections.filter((item) => item?.legal?.length);

    const reqObj = {
      project_id: Number(selectedProjectId),
      intersections,
    };

    dispatch(
      HandleApiActions({
        ...typeApprovalAPI.get_add_intersection,
        params: reqObj,
        show_toast: "true",
      })
    );
    setTabValue(1);
    setLinkTab(true);
  };

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...typeApprovalAPI.get_tickets,
        params: { project_id: selectedProjectId, page_size: 100 },
      })
    );
  }, [selectedProjectId, selectedProject]);

  useEffect(() => {
    if (selectedProjectIsZonal && zoneMainOptionsList[0]?.value) {
      setSelectedZoneMain(zoneMainOptionsList[0]?.value);
    }
  }, [zoneMainOptionsList, selectedProject]);

  useEffect(() => {
    /* Select first zone linked by default */
    if (selectedProjectIsZonal && zoneLinkedOptionsList[0]?.value) {
      setSelectedZoneLinked(zoneLinkedOptionsList[0]?.value);
    }
  }, [zoneLinkedOptionsList, selectedProject]);

  useEffect(() => {
    /* Select first ee component main by default */
    if (selectedProjectIsZonal && eeMainOptionsList[0]?.value) {
      setSelectedEEMainId(eeMainOptionsList[0]?.value);
    }
  }, [eeMainOptionsList, selectedProject]);

  useEffect(() => {
    /* Select first software module main by default */
    if (!selectedMainSWModuleDetails && eeMainSWModulesList[0]) {
      setSelectedMainSWModuleDetails(swModulesMainList[0]);
    }
  }, [eeMainSWModulesList, selectedProject]);

  useEffect(() => {
    /* Select first ee component main/linked by default */
    if (!selectedProjectIsZonal && eeMainLinkedOptionsList[0]?.value) {
      setSelectedEEMainId(eeMainLinkedOptionsList[0]?.value);
      setSelectedEELinkedId(eeMainLinkedOptionsList[0]?.value);
    } else if (!selectedProjectIsZonal) {
      setSelectedEEMainId(null);
      setSelectedEELinkedId(null);
    }
  }, [eeMainLinkedOptionsList]);

  console.log("selectedMainSWModuleDetails", selectedMainSWModuleDetails);
  console.log("linkDetails", linkDetails);
  console.log(!selectedProjectId, "!selectedProjectId");
  console.log("checkedItems", checkedItems);

  useEffect(() => {
    setFilteredTags([]);
  }, [selectedStandards]);

  useEffect(() => {
    if (projectOptionsList.length > 0) {
      setSelectedProject(projectOptionsList[0]);
    }
  }, [projectOptionsList]);

  useEffect(() => {
    // Check if the project is zonal
    const isZonal = projectOptionsList[0]?.is_zonal === true;

    if (isZonal) {
      // Retrieve the default zone name
      const defaultZone = projectOptionsList[0]?.zones[0]?.zone_name || "";

      // Update state
      setSelectedZoneMain(defaultZone);
      setDefaultSelectedZone(isZonal);
    } else {
      // Reset state if not zonal
      setSelectedZoneMain(""); // or any default value
      setDefaultSelectedZone(isZonal);
    }
  }, [selectedProject, projectOptionsList]);

  useEffect(() => {
    if (eeMainSWModulesList && eeMainSWModulesList.length > 0) {
      setSelectedMainSWModuleDetails(eeMainSWModulesList[0]?.swm_id);
    }
  }, [eeMainSWModulesList]);

  console.log(
    "Link Disabled",
    newCheckedItems[selectedMainSWModuleDetails]?.legal?.length
  );
  console.log("selectedProject", selectedProject);
  return (
    <>
      <Box sx={styles.box}>
        <Box>
          <SearchSelectTextField
            size={"small"}
            label={"Search Project"}
            placeholder={"Search Project"}
            noOptionsText={"No project found"}
            optionsList={projectOptionsList}
            selectedValue={selectedProject}
            setSelectedValue={setSelectedProject}
            txtFldStyle={{
              mt: { sm: 2, md: 1 },
              width: "215px",
              backgroundColor: "white",
            }}
          />
        </Box>
        {selectedProject ? (
          <>
            <Box sx={styles.box1}>
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", mb: "20px" }}
              >
                <Button
                  disabled={
                    newCheckedItems[selectedMainSWModuleDetails]?.legal
                      ?.length > 0
                      ? false
                      : true
                  }
                  sx={{ ...appTheme.commonBtnStyle, ...styles.button }}
                  onClick={handleClickLink}
                >
                  Link
                </Button>
              </Box>
              <Grid container spacing={4}>
                {/* Grid item for the Software Modules section */}
                <Grid item xs={12} md={6} sx={styles.grid}>
                  <Box sx={{ mb: 3 }}>
                    {/* Dropdowns to select Zone, EE Component, and Tag */}
                    {(selectedProjectIsZonal || defaultSelectedZone) && (
                      <SelectTextFieldN
                        disabled={!selectedProject}
                        size={"small"}
                        label={"Select Zone *"}
                        variant={"standard"}
                        optionsList={zoneMainOptionsList}
                        selectedValue={selectedZoneMain}
                        handleOnChange={(e) =>
                          setSelectedZoneMain(e.target.value)
                        }
                        sltFldStyle={{ width: "190px" }}
                      />
                    )}
                    <SelectTextFieldN
                      size={"small"}
                      label={"Select EE Component*"}
                      variant={"standard"}
                      optionsList={
                        selectedProjectIsZonal
                          ? eeMainOptionsList
                          : eeMainLinkedOptionsList
                      }
                      selectedValue={selectedEEMainId}
                      disabled={
                        selectedProjectIsZonal ? !selectedZoneMain : false
                      }
                      handleOnChange={(e) =>
                        setSelectedEEMainId(e.target.value)
                      }
                      sltFldStyle={{
                        width: "190px",
                        ml: 2,
                      }}
                    />
                  </Box>
                  <Card sx={styles.card}>
                    {/* Card Header displaying the title "Software Modules" */}
                    <CardHeader
                      sx={styles.cardHeader}
                      title={
                        <Typography
                          textAlign={"center"}
                          fontWeight={600}
                          variant="subtitle1"
                        >
                          {"SOFTWARE MODULES"}
                        </Typography>
                      }
                    />
                    <CardContent sx={styles.cardContent}>
                      {eeMainSWModulesList &&
                      eeMainSWModulesList?.length > 0 ? (
                        eeMainSWModulesList?.map((item, index) => (
                          <Box
                            key={index}
                            onClick={() =>
                              setSelectedMainSWModuleDetails(item?.swm_id)
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mb: 1.5,
                              mt: 1,
                              pl: 1.4,
                              pr: 1,
                              backgroundColor: "background.paper",
                              borderRadius: 1,
                              boxShadow: 4,
                              border:
                                selectedMainSWModuleDetails &&
                                selectedMainSWModuleDetails == item?.swm_id
                                  ? "2px solid blue"
                                  : "1px solid lightgrey",
                              cursor: "pointer",
                              "&:hover": {
                                // backgroundColor: "lightgrey",
                              },
                            }}
                          >
                            <Box
                              sx={{ flexGrow: 1, textAlign: "start", my: 1 }}
                            >
                              <Typography variant="body2">
                                <strong>Name:</strong> {item?.name}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Description:</strong>{" "}
                                {item?.description}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Version:</strong> {item?.version}
                              </Typography>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ textAlign: "center", mt: 2 }}
                        >
                          No data available
                        </Typography>
                      )}
                    </CardContent>
                    {/* Card Actions section (currently empty) */}
                    <CardActions sx={styles.cardActions}></CardActions>
                  </Card>
                </Grid>
                {/* Grid item for the Legal Standards section */}
                <Grid item xs={12} md={6} sx={styles.grid}>
                  <Box sx={{ mb: 3 }}>
                    {/* Dropdown to select Legal Standards */}

                    <SelectTextFieldN
                      size={"small"}
                      label={"Select Legal Standards*"}
                      optionsList={standardList}
                      selectedValue={selectedStandards}
                      handleOnChange={(e) =>
                        setSelectedStandards(e.target.value)
                      }
                      variant={"standard"}
                      sltFldStyle={{
                        width: "190px",
                      }}
                    />
                    <SelectTextFieldN
                      size={"small"}
                      label={"Select Tags*"}
                      optionsList={requirementsList}
                      selectedValue={selectedChapter}
                      handleOnChange={(e) => setSelectedChapter(e.target.value)}
                      variant={"standard"}
                      sltFldStyle={{
                        width: "190px",
                        ml: "10px",
                      }}
                    />
                  </Box>
                  <Card sx={styles.card}>
                    {/* Card Header displaying the title "Legal Standards" */}
                    <CardHeader
                      sx={styles.cardHeader}
                      title={
                        <Typography
                          textAlign={"center"}
                          fontWeight={600}
                          variant="subtitle1"
                        >
                          {"LEGAL REQUIREMENTS"}
                        </Typography>
                      }
                    />
                    <CardContent sx={styles.cardContent}>
                      {filteredTags && filteredTags?.length > 0 ? (
                        filteredTags?.map((item, index) => (
                          <Box
                            key={index}
                            // onClick={() => setSelectedMainSWModuleDetails(item)}
                            sx={{
                              display: "flex",

                              alignItems: "center",
                              justifyContent: "space-between",
                              mb: 1.5,
                              mt: 1,
                              pl: 1.4,
                              pr: 1,
                              backgroundColor: "background.paper",
                              borderRadius: 1,
                              boxShadow: 4,
                              // border:
                              //   selectedMainSWModuleDetails &&
                              //   selectedMainSWModuleDetails?.swm_id == item?.swm_id
                              //     ? "2px solid blue"
                              //     : "1px solid lightgrey",

                              "&:hover": {
                                // backgroundColor: "lightgrey",
                              },
                            }}
                          >
                            <Box
                              sx={{ flexGrow: 1, textAlign: "start", my: 1 }}
                            >
                              <Typography variant="body2">
                                <strong>Req Number:</strong> {item?.req_number}{" "}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Description:</strong>
                                {item?.description}
                              </Typography>
                            </Box>
                            {selectedMainSWModuleDetails && (
                              <Box>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        selectedMainSWModuleDetails &&
                                        newCheckedItems?.[
                                          selectedMainSWModuleDetails?.toString()
                                        ]?.blockedLr?.includes(item?.lr_id)
                                          ? true
                                          : false
                                      }
                                      value={item?.lr_id}
                                      checked={
                                        (selectedMainSWModuleDetails &&
                                          newCheckedItems?.[
                                            selectedMainSWModuleDetails?.toString()
                                          ]?.legal?.findIndex(
                                            (lr) => lr.lr_id == item.lr_id
                                          ) > -1) ||
                                        (selectedMainSWModuleDetails &&
                                          newCheckedItems?.[
                                            selectedMainSWModuleDetails?.toString()
                                          ]?.blockedLr?.includes(item?.lr_id))
                                          ? true
                                          : false
                                        // newCheckedItems?.includes(item?.lr_id) ||
                                      }
                                      onChange={(event) =>
                                        handleCheckboxChange(
                                          event,
                                          item?.lr_id,
                                          item?.description
                                        )
                                      }
                                    />
                                  }
                                />
                              </Box>
                            )}
                          </Box>
                        ))
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ textAlign: "center", mt: 2 }}
                        >
                          No data available
                        </Typography>
                      )}
                    </CardContent>
                    {/* Card Actions section (currently empty) */}
                    <CardActions sx={styles.cardActions}></CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : null}
      </Box>
      {lrDescDialog && (
        <Modal
          open={lrDescDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="modal-modal-title"
                component="h2"
                sx={{ fontWeight: "bold", mt: "5px" }}
              >
                Reason For Linking
              </Typography>
              <IconButton onClick={() => setLrDescDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={{ mt: "10px" }}>
              <CustomTextField1
                size={"small"}
                variant="filled"
                label={"Description"}
                placeholder={"Description"}
                value={lrDescription}
                setValue={setLrDescription}
                txtFldStyle={{
                  width: "350px",
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
            >
              <Button
                disabled={lrDescription?.length > 0 ? false : true}
                onClick={submitLrDescription}
                variant="contained"
                sx={{ ...appTheme.commonBtnStyle, ...styles.button }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default SoftwareToLegalLinks;
// Styles used for layout and design
const styles = {
  button: {
    variant: "contained",
  },
  box: {
    mb: "70px",
    width: "100%",
  },
  box1: { mt: 2 },
  box2: { p: 2 },
  box3: {
    display: "flex",
    border: "1px solid grey",
    mt: "0px",
  },
  box4: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box5: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box6: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    padding: "5px",
  },
  box7: {
    display: "flex",
    border: "1px solid grey",
  },
  box8: {
    flex: 1,
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box9: {
    flex: 1,
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box10: {
    flex: 1,
    textAlign: "center",
    padding: "5px",
  },
  box11: { p: 2 },
  box12: {
    display: "flex",
    border: "1px solid grey",
  },
  box13: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box14: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box15: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    padding: "5px",
  },
  IconButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  box16: {
    display: "flex",
    border: "1px solid grey",
  },
  box17: {
    flex: 1,
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box18: {
    flex: 1,
    textAlign: "center",
    borderRight: "1px solid grey",
    padding: "5px",
  },
  box19: {
    flex: 1,
    textAlign: "center",
    padding: "5px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    mx: 2,
    boxShadow: 12,
    width: "420px",
  },
  cardHeader: {
    pb: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  cardContent: {
    height: "340px",
    overflowY: "auto",
    paddingTop: "0px",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  typography: { textAlign: "center", mt: 2 },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
