/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-06-2024
 * @description Main component for managing users, roles, and project access.
 */
import React, { useEffect, useState } from "react";
import ProjectAccess from "./ProjectAccess";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import RolesAndAccessMain from "./RolesAndAccessMain";
import styles from "./userManagementStyles";
import Users from "./Users";

import { useDispatch } from "react-redux";
import {
  HandleApiActions,
  profileActions,
  settingsApis,
  userManagementApis,
} from "../../redux/actions";

/**
 * CustomTabPanel component renders a tab panel for a tab-based interface.
 */
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 02-07-2024
 * @description Main component for managing users, roles, and project access.
 * @param : none
 * @return The rendered user management component.
 */
function UserManagement() {
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_country_codes,
        show_toast: false,
      })
    );

    dispatch(
      HandleApiActions({
        ...settingsApis.get_app_meta_data,
        show_toast: false,
      })
    );

    dispatch(
      HandleApiActions({
        ...userManagementApis.get_features_list,
        show_toast: false,
      })
    );

    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: 1,
          page_size: 100,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );

    dispatch(
      HandleApiActions({
        ...profileActions.get_profile_details,
        show_toast: false,
        params: {},
      })
    );
  }, []);

  // Handles tab change event.
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={styles.container}>
      {/* User management tabs */}
      <Box sx={styles.tabsContainer}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            sx={styles.tabs}
            //icon={<img src={TabIcon} alt="" />}
            iconPosition="start"
            label="Roles And Access"
            {...a11yProps(0)}
          />
          <Tab
            sx={styles.tabs}
            //icon={<img src={TabIcon} alt="" styles={{ color: "" }} />}
            iconPosition="start"
            label="Users"
            {...a11yProps(1)}
          />
          <Tab sx={styles.tabs} label="Project Access" {...a11yProps(2)} />
        </Tabs>
      </Box>

      {/* User management tab body */}
      <Box>
        <CustomTabPanel value={tabValue} index={0}>
          <RolesAndAccessMain />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Users />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <ProjectAccess />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

export default UserManagement;
