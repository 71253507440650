/**
 * @author Tahir Shaik
 * @date 23/08/2024
 * @description
 */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import appTheme from "../../../assets/AppTheme/appTheme";
import moment from "moment";
import React, { useEffect, useState } from "react";
import sampleJson from "./sample.json";
import { DataGrid } from "@mui/x-data-grid";
import DependenciesDialog from "./DependenciesDialog";
import { HandleApiActions } from "../../../redux/actions";
import typeApprovalAPI from "../../../redux/actions/typeApprovalAPI";
import { useDispatch } from "react-redux";
import { CustomButton } from "../../../components";
function Intersections({
  intersectionDetailsList,
  handleTabChange,
  tabValue,
  setTabValue,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dependencies, setDependencies] = useState({});
  const [existingPrDetails, setExistingPrDetails] = useState([]);
  const [existingECUs, setExistingECUs] = useState([]);
  const [existingSWMs, setExistingSWMs] = useState([]);
  const [existingLS, setExistingLS] = useState([]);
  const [existingLSCh, setExistingLSCh] = useState([]);
  const [existingLR, setExistingLR] = useState([]);
  const [selectedECU, setSelectedECU] = useState(0);
  const [selectedSWM, setSelectedSWM] = useState(0);
  const [selectedLS, setSelectedLS] = useState(0);
  const [selectedLSCh, setSelectedLSCh] = useState("");
  const [selectedLR, setSelectedLR] = useState(0);
  const [existingData, setExistingData] = useState([]);
  const [requestObj, setRequestObj] = useState({});
  /**
   * @params {selectedItem:1, setSelectedItem(), data:{details:"", id:""}}
   */
  const CustomDropDown = (props) => {
    // console.log("-- CustomDropDown --");
    // console.log(props);
    return (
      <FormControl>
        <InputLabel
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          {props.header}
        </InputLabel>
        <Select
          variant="standard"
          value={
            props.selectedItem !== undefined
              ? props.selectedItem
              : props.data[0].id
          }
          onChange={(e) => {
            if (props.setSelectedItem) {
              props.setSelectedItem(e.target.value);
            }
          }}
          sx={{
            minWidth: "200px",
            textAlign: "start",
            ...props.styles,
          }}
        >
          {props.data.map((req, idx) => {
            return (
              <MenuItem
                key={idx}
                value={req.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {props.is_data_object ? (
                  <Typography sx={{ fontSize: "14px" }}>
                    {req.details.fullname}
                    <br /> {req.details.email}
                    <br />
                    {req.details.role_name}
                    <br /> {req.details.department}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      maxWidth: "200px",
                      overflow: "auto",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {req.details}
                  </Typography>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const handleRemoveFilters = () => {
    setSelectedECU(0);
    setSelectedSWM(0);
    setSelectedLS(0);
    setSelectedLSCh(0);
    setSelectedLR(0);
  };

  useEffect(() => {
    if (tabValue === 0) {
      setExistingData([]);
      setExistingPrDetails([]);
      setExistingECUs([]);
      setExistingSWMs([]);
      setExistingLS([]);
      setExistingLSCh([]);
      setExistingLR([]);
      setRequestObj({});
      dispatch({
        type: typeApprovalAPI.get_add_intersection.action_type,
        payload: { error: true, msg: "", data: { intersections: [] } },
      });
    }
  }, [handleTabChange]);

  //   Use effect to execute on component mount
  useEffect(() => {
    // console.log(intersectionDetailsList);
    if (
      intersectionDetailsList &&
      // !sampleJson.error &&
      // Object.keys(sampleJson).length &&
      intersectionDetailsList.length
    ) {
      const prDetails = [];
      const ecus = [];
      const swms = [];
      const lss = [];
      const lrs = [];
      let intrscts = [];
      const reqObj = {
        project_id: intersectionDetailsList[0].project_details.project_id,
        intersections: [],
        current_time: moment().format(),
      };
      for (const data of intersectionDetailsList) {
        const index = intrscts.findIndex(
          (itr) =>
            itr.ee_id === parseInt(data.ee_details.ee_id) &&
            itr.swm_id === parseInt(data.swm_details.swm_id)
        );
        if (index !== -1) {
          const existing = intrscts[index];
          intrscts[index] = {
            ...existing,
            legal: [
              ...existing.legal,
              {
                lr_id: parseInt(data.lr_details.lr_id),
                description: data.description,
              },
            ],
          };
        } else {
          intrscts.push({
            ee_id: parseInt(data.ee_details.ee_id),
            swm_id: parseInt(data.swm_details.swm_id),
            legal: [
              {
                lr_id: parseInt(data.lr_details.lr_id),
                description: data.description,
              },
            ],
          });
        }
        if (
          prDetails.findIndex(
            (e) =>
              parseInt(e.project_id) ===
              parseInt(data.project_details.project_id)
          ) === -1
        ) {
          prDetails.push(data.project_details);
        }
        if (
          ecus.findIndex(
            (e) => parseInt(e.ee_id) === parseInt(data.ee_details.ee_id)
          ) === -1
        ) {
          ecus.push(data.ee_details);
        }
        if (
          swms.findIndex(
            (s) => parseInt(s.swm_id) === parseInt(data.swm_details.swm_id)
          ) === -1
        ) {
          swms.push(data.swm_details);
        }
        if (
          lss.findIndex(
            (l) => parseInt(l.ls_id) === parseInt(data.ls_details.ls_id)
          ) === -1
        ) {
          lss.push(data.ls_details);
        }
        if (
          lrs.findIndex(
            (r) => parseInt(r.lr_id) === parseInt(data.lr_details.lr_id)
          ) === -1
        ) {
          lrs.push(data.lr_details);
        }
      }
      reqObj.intersections = intrscts;
      // console.log("intrscts");
      // console.log(intrscts);
      // console.log(reqObj);
      // console.log("prDetails: ", prDetails);
      // console.log("ecus: ", ecus);
      // console.log("swms: ", swms);
      // console.log("lss: ", lss);
      // console.log("lrs: ", lrs);
      setExistingPrDetails(prDetails);
      setExistingECUs(ecus);
      setExistingSWMs(swms);
      setExistingLS(lss);
      setExistingLR(lrs);
      setExistingData(intersectionDetailsList);
      setRequestObj(reqObj);
    }
  }, []);
  useEffect(() => {
    setSelectedSWM(0);
    setSelectedLS(0);
    setSelectedLR(0);
  }, [selectedECU]);
  useEffect(() => {
    setSelectedLS(0);
    setSelectedLR(0);
  }, [selectedSWM]);
  useEffect(() => {
    setSelectedLR(0);
  }, [selectedLS]);
  useEffect(() => {
    // When the selected legal standard is changed, change the selected chapters to ""
    setSelectedLSCh("");

    // When the selected legal standard is changed, change the legal standard chapters list
    let filteredLSCh = existingLS?.find((ls) => ls?.ls_id == selectedLS);
    setExistingLSCh(filteredLSCh?.chapters || []);
  }, [selectedLS]);
  useEffect(() => {
    let filteredIntersectionDetailsList = intersectionDetailsList?.filter(
      (el) =>
        (!selectedECU || parseInt(el?.ee_details?.ee_id) === selectedECU) &&
        (!selectedSWM || parseInt(el?.swm_details?.swm_id) === selectedSWM) &&
        (!selectedLS || parseInt(el?.ls_details?.ls_id) === selectedLS)
      // &&
      // (!selectedLR || parseInt(el?.lr_details?.lr_id) === selectedLR)
    );
    // console.log(
    //   "filteredIntersectionDetailsList: ",
    //   filteredIntersectionDetailsList
    // );

    setExistingData(filteredIntersectionDetailsList);
  }, [selectedECU, selectedSWM, selectedLS, selectedLSCh, selectedLR]);
  useEffect(() => {
    // console.log("intersectionDetailsList: ", intersectionDetailsList);
    // console.log("dependencies: ", dependencies);
    // console.log("requestObj: ", requestObj);
    // console.log("existingData: ", existingData);
    // console.log("existingPrDetails: ", existingPrDetails);
    // console.log("existingECUs: ", existingECUs);
    // console.log("existingSWMs: ", existingSWMs);
    // console.log("existingLS: ", existingLS);
    // console.log("existingLSCh: ", existingLSCh);
    // console.log("existingLR: ", existingLR);
    // console.log("selectedECU: ", selectedECU);
    // console.log("selectedSWM: ", selectedSWM);
    // console.log("selectedLS: ", selectedLS);
    // console.log("selectedLSCh: ", selectedLSCh);
    // console.log("selectedLR: ", selectedLR);
  }, [
    intersectionDetailsList,
    dependencies,
    requestObj,
    existingData,
    existingPrDetails,
    existingECUs,
    existingSWMs,
    existingLS,
    existingLSCh,
    existingLR,
    selectedECU,
    selectedSWM,
    selectedLS,
    selectedLSCh,
    selectedLR,
  ]);
  const handleRaiseTickets = async () => {
    // console.log("handleRaiseTickets: ");
    // console.log(requestObj);
    if (Object.keys(requestObj).length) {
      const resp = await dispatch(
        HandleApiActions({
          // api_name: typeApprovalAPI.add_intersection.api_name,
          // https_method: typeApprovalAPI.add_intersection.https_method,
          ...typeApprovalAPI.add_intersection,
          params: requestObj,
          show_toast: true,
        })
      );
      // console.log(resp);
      if (resp.error !== undefined && resp.error === false) {
        setExistingData([]);
        setExistingPrDetails([]);
        setExistingECUs([]);
        setExistingSWMs([]);
        setExistingLS([]);
        setExistingLSCh([]);
        setExistingLR([]);
        setRequestObj({});
        dispatch({
          type: typeApprovalAPI.get_add_intersection.action_type,
          payload: { error: true, msg: "", data: { intersections: [] } },
        });
      }
    }
    setTabValue(2);
  };
  const handleDisplayDependecies = (details) => {
    setDependencies(details);
    setOpen(true);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pb: 7 }}>
      <Box sx={styles.rmFlContainer}>
        <CustomButton
          size={"medium"}
          variant={"contained"}
          btnName={"Clear Filters"}
          handleOnClick={handleRemoveFilters}
          btnStyle={styles.customButton}
        />
      </Box>
      <Paper
        // elevation={12}
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          flexWrap: "wrap",
          // justifyContent: "space-evenly",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          py: 2,
          mb: 2,
        }}
      >
        {/* Project details */}
        {existingPrDetails.length ? (
          <Paper
            elevation={12}
            sx={{
              gap: 1,
              padding: "10px",
              height: "170px",
              width: "240px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "3px",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
              {"PROJECT DETAILS"}
            </Typography>
            {existingPrDetails[0] ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  textAlign: "left",
                  marginTop: 2,
                }}
              >
                <Typography variant="body2">
                  <span style={styles.txtBold}>Name: </span>
                  {existingPrDetails[0]?.name}
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Architecture Name: </span>
                  {existingPrDetails[0]?.architecture_name}
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Vehicle Platform: </span>
                  {existingPrDetails[0]?.vehicle_platform}
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Trade Mark: </span>
                  {existingPrDetails[0]?.trade_mark}
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Paper>
        ) : undefined}
        {/* EE Component details */}
        {existingECUs.length ? (
          <Paper
            elevation={12}
            sx={{
              gap: 1,
              padding: "10px",
              height: "170px",
              width: "240px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "3px",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
              {"EE COMPONENT"}
            </Typography>
            <CustomDropDown
              header={"ECUs"}
              is_data_object={false}
              selectedItem={selectedECU}
              setSelectedItem={setSelectedECU}
              data={existingECUs.map((ecu) => {
                return { id: parseInt(ecu.ee_id), details: ecu.description };
              })}
            />
            {existingECUs.filter((data) => parseInt(data.ee_id) === selectedECU)
              .length ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.4,
                  textAlign: "left",
                  marginTop: 2,
                }}
              >
                <Typography variant="body2">
                  <span style={styles.txtBold}>Version: </span>
                  {
                    existingECUs.filter(
                      (data) => parseInt(data.ee_id) === selectedECU
                    )[0].version
                  }
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Supplier Name: </span>
                  {
                    existingECUs.filter(
                      (data) => parseInt(data.ee_id) === selectedECU
                    )[0].supplier_name
                  }
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Assembly Number: </span>
                  {
                    existingECUs.filter(
                      (data) => parseInt(data.ee_id) === selectedECU
                    )[0].assembly_number
                  }
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Paper>
        ) : undefined}
        {/* SWM Details */}
        {existingSWMs.length ? (
          <Paper
            elevation={12}
            sx={{
              gap: 1,
              padding: "10px",
              height: "170px",
              width: "240px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "3px",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
              {"SOFTWARE MODULE"}
            </Typography>
            <CustomDropDown
              header={"SWM"}
              is_data_object={false}
              selectedItem={selectedSWM}
              setSelectedItem={setSelectedSWM}
              data={existingSWMs
                ?.filter((swm) => swm?.ee_id == selectedECU)
                ?.map((swm) => {
                  return { id: parseInt(swm.swm_id), details: swm.name };
                })}
            />
            {/* {existingSWMs.filter(
              (data) => parseInt(data.swm_id) === selectedSWM
            ).length ? ( */}
            {existingSWMs?.filter(
              (swm) =>
                parseInt(swm.ee_id) === selectedECU &&
                parseInt(swm.swm_id) === selectedSWM
            ).length ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.4,
                  textAlign: "left",
                  marginTop: 2,
                }}
              >
                <Typography variant="body2">
                  <span style={styles.txtBold}>Version: </span>
                  {
                    existingSWMs.filter(
                      (data) => parseInt(data.swm_id) === selectedSWM
                    )[0].version
                  }
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Full Name: </span>
                  {
                    existingSWMs.filter(
                      (data) => parseInt(data.swm_id) === selectedSWM
                    )[0]?.owner_details?.fullname
                  }
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Email: </span>
                  {
                    existingSWMs.filter(
                      (data) => parseInt(data.swm_id) === selectedSWM
                    )[0]?.owner_details?.email
                  }
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Paper>
        ) : undefined}
        {/* Legal Details */}
        {existingLS.length ? (
          <Paper
            elevation={12}
            sx={{
              gap: 1,
              padding: "10px",
              height: "170px",
              width: "240px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "3px",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
              {"LEGAL"}
            </Typography>
            <Box sx={{ mb: 4 }}>
              <CustomDropDown
                header={"STANDARD"}
                is_data_object={false}
                selectedItem={selectedLS}
                setSelectedItem={setSelectedLS}
                data={existingLS.map((ls) => {
                  return {
                    id: parseInt(ls.ls_id),
                    details: ls.standard_number,
                  };
                })}
              />
            </Box>
            {/* <Box sx={{ mb: 4 }}>
              <CustomDropDown
                header={"CHAPTERS/TAGS"}
                is_data_object={false}
                selectedItem={selectedLSCh}
                setSelectedItem={setSelectedLSCh}
                data={existingLSCh.map((ch) => {
                  return {
                    id: ch,
                    details: ch,
                  };
                })}
              />
            </Box> */}
            {/* <Box sx={{ mb: 4 }}>
              <CustomDropDown
                header={"LEGAL REQUIREMENTS No."}
                is_data_object={false}
                selectedItem={selectedLR}
                setSelectedItem={setSelectedLR}
                data={existingLR.map((ls) => {
                  return {
                    id: parseInt(ls.lr_id),
                    details: ls.req_number,
                  };
                })}
              />
            </Box> */}
          </Paper>
        ) : undefined}
      </Paper>

      {/* Intersection details */}
      {existingData.length ? (
        <Box>
          <TableContainer component={Paper} sx={{ maxHeight: "75vH" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {[
                    "SNO",
                    "EE\nComponent",
                    "Software\nModule",
                    "Dependencies",
                    "Legal\nStandard",
                    "Chapter",
                    "Legal\nRequirement",
                    "Users",
                    "Description",
                  ].map((data, idx) => {
                    return (
                      <TableCell
                        key={idx}
                        align="center"
                        sx={{ fontWeight: "bold", border: "1px solid black" }}
                      >
                        {data}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {existingData.map((data, inx) => {
                  return (
                    <TableRow key={inx}>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        {inx + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          border: "1px solid black",
                          // maxWidth: "300px",
                          // overflow: "auto",
                        }}
                      >
                        <strong>Description:</strong>&nbsp;&nbsp;
                        {data.ee_details.description}
                        <br />
                        <br />
                        <strong>Version:</strong>&nbsp;&nbsp;
                        {data.ee_details.version}
                        <br />
                        <br />
                        <strong>Assembly:</strong>&nbsp;&nbsp;
                        {data.ee_details.assembly_number}
                        <br />
                        <br />
                        <strong>Supplier:</strong>&nbsp;&nbsp;
                        {data.ee_details.supplier_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <strong>Name:</strong>&nbsp;&nbsp;{" "}
                        {data.swm_details.name}
                        <br />
                        <br />
                        <strong>Description:</strong>&nbsp;&nbsp;{" "}
                        {data.swm_details.description}
                        <br />
                        <br />
                        <strong>Version:</strong>&nbsp;&nbsp;
                        {data.swm_details.version}
                        <br />
                        <br />
                        <strong>Owner:</strong>&nbsp;&nbsp; <br />
                        {data.swm_details?.owner_details?.fullname}
                        <br />
                        {data.swm_details?.owner_details?.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid black" }}
                      >
                        <Button
                          sx={{
                            ...appTheme.commonBtnStyle,
                          }}
                          onClick={() => {
                            handleDisplayDependecies({
                              swm_name: data.swm_details.name,
                              software_modules:
                                data.dependencies?.software_modules || [],
                              vehicle_functions:
                                data.swm_details.vehicle_functions,
                            });
                          }}
                        >
                          View{" >>"}
                        </Button>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <strong>Number:</strong>&nbsp;&nbsp;
                        {data.ls_details.standard_number}
                        <br />
                        <br />
                        <strong>Name:</strong>&nbsp;&nbsp;
                        {data.ls_details.standard_name}
                        <br />
                        <br />
                        <strong>Revision:</strong>&nbsp;&nbsp;
                        {data.ls_details.revision}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        {data.lr_details.chapter}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <strong>Number:</strong>&nbsp;&nbsp;
                        {data.lr_details.req_number}
                        <br />
                        <br />
                        <strong>Description:</strong>&nbsp;&nbsp;
                        {data.lr_details.description}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <CustomDropDown
                          header={"OEM"}
                          is_data_object={true}
                          data={data.oem_users.map((user) => {
                            return {
                              id: user.user_id,
                              details: {
                                ...user,
                              },
                            };
                          })}
                        />
                        <br />
                        <br />
                        <CustomDropDown
                          header={"Technical Service"}
                          is_data_object={true}
                          data={data.tech_service_users.map((user) => {
                            return {
                              id: user.user_id,
                              details: {
                                ...user,
                              },
                            };
                          })}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <strong>Description:</strong>&nbsp;&nbsp;
                        {data.description}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              marginTop: "20px",
              textAlign: "right",
            }}
          >
            <Button
              sx={{
                ...appTheme.commonBtnStyle,
              }}
              onClick={handleRaiseTickets}
            >
              Raise Tickets
            </Button>
          </Box>
        </Box>
      ) : undefined}
      {open && (
        <DependenciesDialog
          open={open}
          handleClose={() => setOpen(false)}
          swmName={dependencies.swm_name}
          vehicleFunctions={dependencies.vehicle_functions}
          softwareModules={dependencies.software_modules}
        />
      )}
    </Box>
  );
}

export default Intersections;

// Styles
const styles = {
  txtBold: {
    fontWeight: "bold",
  },
  rmFlContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
};
