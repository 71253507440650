/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Reusable searchable text field component.
 */

import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Reusable searchable text field component.
 * @param { size, label, placeholder, value, setValue, handleClick }
 * @return The rendered searchable text field.
 */
function SearchTextField({
  size,
  label,
  placeholder,
  variant,
  value,
  setValue,
  handleClick,
}) {
  // Handles change events for the text field.
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      // type="search"
      size={size}
      label={label}
      variant={variant}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyDown={(e) => {
        if (e.key == "Enter") {
          e.preventDefault();
          handleClick();
        }
      }}
      sx={{
        "& input::-webkit-search-cancel-button": {
          cursor: "pointer",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClick} edge="start">
            <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchTextField;
