import React, { useState } from "react";
import { Box, TextField } from "@mui/material";

const CustomTextFieldSP = ({
  size,
  label,
  placeholder,
  value,
  setValue,
  txtFldStyle,
  validationRules,
  setError,
  required,
}) => {
  const [error, setLocalError] = useState("");

  // Handles change events for the text field.
  const handleChange = (e) => {
    const val = e.target.value;
    setValue(val); // Update parent component's state with the new value

    // Validate based on the rules
    if (validationRules?.minLength && val.length < validationRules.minLength) {
      setLocalError(
        `Should be at least ${validationRules.minLength} characters long`
      );
      setError(true); // Set error in parent component
    } else {
      setLocalError("");
      setError(false); // Clear error in parent component
    }
  };

  return (
    <Box>
      <TextField
        required={required}
        fullWidth
        size={size}
        label={label}
        type="text"
        variant="standard"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        error={!!error}
        helperText={error}
        sx={txtFldStyle}
      />
    </Box>
  );
};

export default CustomTextFieldSP;
