import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ChipsTextField,
  CustomAddDialog,
  CustomButton,
  CustomDataGridTable,
  CustomDataGridTableN,
  CustomPagination,
  CustomTextFieldN,
  CustomUpdateDialog,
  EnterSelectTextField,
  SearchSelectTextField,
  SearchTextField,
  SelectTextFieldN,
} from "../../components";
import AddIcon from "@mui/icons-material/Add";
import { HandleApiActions, legalRequirements } from "../../redux/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import customConsole from "../../config/customConsole";
import EditIcon from "@mui/icons-material/Edit";

function LegalRequirements() {
  const dispatch = useDispatch();

  // Redux store data
  const { legalStandardDetails } = useSelector(
    (state) => state.legalRequirementsReducer,
    shallowEqual
  );
  const { legalRequirementsDetails } = useSelector(
    (state) => state.legalRequirementsReducer,
    shallowEqual
  );

  // Legal standard states
  const [lSOptionsList, setLSOptionsList] = useState([]);
  const [selectedLS, setSelectedLS] = useState(null);
  const [selectedLSDetails, setSelectedLSDetails] = useState(null);

  // Legal requirements states
  const [searchLRValueTmp, setSearchLRValueTmp] = useState("");
  const [searchLRValue, setSearchLRValue] = useState("");

  // Legal requirements
  const [tableRowsData, setTableRowsData] = useState([]);

  // Dialogs
  const [isAddLSDialogOpen, setIsAddLSDialogOpen] = useState(false);
  const [isUpdateLSDialogOpen, setIsUpdateLSDialogOpen] = useState(false);
  const [isAddLRDialogOpen, setIsAddLRDialogOpen] = useState(false);
  const [isUpdateLRDialogOpen, setIsUpdateLRDialogOpen] = useState(false);
  const [chaptersOptionsList, setChaptersOptionsList] = useState([]);
  const [chaptersOptionsListS, setChaptersOptionsListS] = useState([]);

  // Chapter
  const [selectedChapter, setSelectedChapter] = useState("");

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Sorting
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("created_at");
  const [selectedSortOrder, setSelectedSortOrder] = useState("ASC");

  // Update legal standard
  const [updateLSDetails, setUpdateLSDetails] = useState(null);

  // Update legal requirements
  const [updateLRDetails, setUpdateLRDetails] = useState(null);

  const COLUMNS = [
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      filterable: false,
      sortable: false,
    },
    {
      field: "req_number",
      headerName: "Requirement Number",
      width: 220,
    },
    // {
    //   field: "chapter",
    //   headerName: "Tag/Chapter",
    //   width: 370,
    // },
    {
      field: "description",
      headerName: "Description",
      minWidth: 470, // Minimum width
      flex: 1, // Takes up remaining width
    },
  ];

  // Handle get legal standards list
  const handleGetLegalStandardList = () => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_standard_details,
        show_toast: false,
      })
    );
  };

  // Handle get legal requirements list
  const handleGetLegalRequirementsList = ({
    selectedLSId,
    selectedChapter,
  }) => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_requirements_details,
        params: {
          page_number: page,
          page_size: rowsPerPage,
          ...(searchLRValue && { req_number: searchLRValue }),
          sort_column: "req_number",
          sort_order: "ASC",
          // sort_column: selectedSortColumn,
          // sort_order: selectedSortOrder,
          ls_id: selectedLSId,
          chapter: selectedChapter,
        },
        show_toast: false,
      })
    );
  };

  // Add legal standard
  const handleAddLegalStandard = ({
    name,
    standardNumber,
    revision,
    description,
    summary,
    chapters,
  }) => {
    let currentTime = new Date()?.toISOString();

    customConsole({
      name,
      standard_number: standardNumber,
      revision,
      description,
      ...(summary && { summary }),
      ...(chapters?.length > 0 && { chapters }),
      current_time: currentTime,
    });

    dispatch(
      HandleApiActions({
        ...legalRequirements.add_legal_standard,
        params: {
          name,
          standard_number: standardNumber,
          revision,
          description,
          ...(summary && { summary }),
          ...(chapters?.length > 0 && { chapters }),
          current_time: currentTime,
        },
        show_toast: true,
      })
    ).then(() => {
      handleGetLegalStandardList();
    });

    toggleAddLSDialog(false)();
  };

  // Update legal standard click
  const handleUpdateLegalStandardClick = (props) => {
    toggleUpdateLSDialog(true)();

    setUpdateLSDetails({ ...props });
  };

  // Update legal standard
  const handleUpdateLegalStandard = ({ ls_id, name, description, summary }) => {
    if (ls_id) {
      let currentTime = new Date().toISOString();

      customConsole({
        ls_id,
        ...(name && { name }),
        ...(description && { description }),
        ...(summary && { summary }),
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...legalRequirements.update_legal_standard,
          params: {
            ls_id,
            ...(name && { name }),
            ...(description && { description }),
            ...(summary && { summary }),
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        handleGetLegalStandardList();
      });

      toggleUpdateLSDialog(false)();
    }
  };

  // Add legal requirement
  const handleAddLegalRequirement = ({ reqNumber, chapter, description }) => {
    let currentTime = new Date().toISOString();

    customConsole({
      ls_id: Number(selectedLSDetails?.ls_id),
      req_number: reqNumber,
      chapter,
      description,
      current_time: currentTime,
    });

    dispatch(
      HandleApiActions({
        ...legalRequirements.add_legal_requirements,
        params: {
          ls_id: Number(selectedLSDetails?.ls_id),
          req_number: reqNumber,
          chapter,
          description,
          current_time: currentTime,
        },
        show_toast: true,
      })
    ).then(() => {
      // get the legal standards list
      handleGetLegalStandardList();
    });

    toggleAddLRDialog(false)();
  };

  // Update legal requirement
  const handleUpdateLegalRequirement = ({ lr_id, description }) => {
    if (lr_id) {
      let currentTime = new Date().toISOString();

      customConsole({
        lr_id,
        description,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...legalRequirements.update_legal_requirements,
          params: {
            lr_id,
            description,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        // get the legal standards list
        handleGetLegalStandardList();
      });

      toggleUpdateLRDialog(false)();
    }
  };

  // Toggle Dialogs state
  const toggleAddLSDialog = (isOpen) => () => {
    setIsAddLSDialogOpen(isOpen);
  };
  const toggleUpdateLSDialog = (isOpen) => () => {
    setIsUpdateLSDialogOpen(isOpen);
  };
  const toggleAddLRDialog = (isOpen) => () => {
    setIsAddLRDialogOpen(isOpen);
  };
  const toggleUpdateLRDialog = (isOpen) => () => {
    setIsUpdateLRDialogOpen(isOpen);
  };

  // Handles the click event for table rows for editing
  const handleTableRowClick = ({ params }) => {
    toggleUpdateLRDialog(true)();

    setUpdateLRDetails({ ...params.row });
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  // Open sort menu
  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  // Handle sort close
  const handleSortClose = () => {
    setAnchorElSort(null);
    setOpenSort(false);
  };

  // Handle sort change
  const handleSortChange = (column, order) => {
    setSelectedSortColumn(column);
    setSelectedSortOrder(order);

    setPage(1); // Reset to the first page when page size changes

    handleSortClose();
  };

  // Reset the sort values
  const handleSortResetAll = () => {
    handleSortChange("created_at", "ASC");
  };

  // Apply sorting
  const handleSortApplyNow = (selectedSortColumn, selectedSortOrder) => {
    handleSortChange(selectedSortColumn, selectedSortOrder);
  };

  // UseEffects
  useEffect(() => {
    handleGetLegalStandardList();
  }, []);

  useEffect(() => {
    if (legalStandardDetails) {
      // get the legal standard options list
      let filteredLSOptionsList = legalStandardDetails?.map((el) => {
        return {
          value: el?.ls_id,
          label: el?.standard_number,
        };
      });
      setLSOptionsList(filteredLSOptionsList);

      // Select first legal standard by default
      if (
        !selectedLS &&
        legalStandardDetails[0]?.ls_id &&
        legalStandardDetails[0]?.standard_number
      ) {
        setSelectedLS({
          value: legalStandardDetails[0]?.ls_id,
          label: legalStandardDetails[0]?.standard_number,
        });
      }
    }
  }, [legalStandardDetails]);

  useEffect(() => {
    if (selectedLS) {
      // get the selected legal standard details
      let filteredSelectedLSDetails = legalStandardDetails?.find(
        (el) => el?.ls_id == selectedLS?.value
      );
      setSelectedLSDetails(filteredSelectedLSDetails);
    }
  }, [selectedLS, legalStandardDetails]);

  useEffect(() => {
    if (selectedLS) {
      // get the selected legal standard details
      let filteredSelectedLSDetails = legalStandardDetails?.find(
        (el) => el?.ls_id == selectedLS?.value
      );

      // By default select the first chapter, only when the selected legal standard is changed
      setSelectedChapter(filteredSelectedLSDetails?.chapters[0] || "");
    }
  }, [selectedLS]);

  useEffect(() => {
    if (selectedLSDetails) {
      //  Chapters options list for select option legal standard
      let filteredChaptersOptionsListS = selectedLSDetails?.chapters?.map(
        (el) => {
          return {
            value: el,
            label: el,
          };
        }
      );
      setChaptersOptionsListS(filteredChaptersOptionsListS);

      // Chapters options list for search & enter legal requirements
      setChaptersOptionsList(selectedLSDetails?.chapters || []);
    }
  }, [selectedLSDetails]);

  useEffect(() => {
    // Set the search to empty when the selected chapter is changed
    setSearchLRValueTmp("");
    setSearchLRValue("");
  }, [selectedChapter]);

  useEffect(() => {
    // get the legal requirements list
    if (selectedChapter && selectedLSDetails?.ls_id) {
      handleGetLegalRequirementsList({
        selectedLSId: selectedLSDetails?.ls_id,
        selectedChapter,
      });
    } else {
      // reset the table rows data
      setTableRowsData([]);
    }
  }, [
    selectedChapter,
    legalStandardDetails,
    page,
    rowsPerPage,
    selectedSortColumn,
    selectedSortOrder,
    searchLRValue,
  ]);

  useEffect(() => {
    // Handle pagination hasMore
    if (legalRequirementsDetails?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    // legal requirements list
    let filteredLegalRequirementsList = legalRequirementsDetails?.map(
      (lr, index) => {
        return {
          ...lr,
          id: (page - 1) * rowsPerPage + index + 1,
        };
      }
    );
    setTableRowsData(filteredLegalRequirementsList);
  }, [legalRequirementsDetails]);

  return (
    <Box sx={styles.mainContainer}>
      {/* Legal standard details */}
      <Box sx={styles.lsMainContainer}>
        {/* Legal standard: Filters, buttons */}
        <Box sx={styles.lsTopContainer}>
          <Box sx={styles.lsSearchSelectStandardContainer}>
            <SearchSelectTextField
              size={"small"}
              label={"Search Standard No."}
              placeholder={"Search Standard No."}
              noOptionsText={"No standards found"}
              optionsList={lSOptionsList}
              selectedValue={selectedLS}
              setSelectedValue={setSelectedLS}
              txtFldStyle={styles.lsSearchSelectStandard}
            />
          </Box>
          <CustomButton
            size={"small"}
            variant={"contained"}
            btnName={"Add Standard"}
            handleOnClick={toggleAddLSDialog(true)}
            startIcon={<AddIcon />}
          />
        </Box>
        {selectedLS && (
          <Paper elevation={6} sx={styles.lsDetailsContainer}>
            <Typography sx={styles.heading}>Legal Standard Details</Typography>
            <Divider sx={styles.divider} />
            <Grid container>
              <Grid item xs={12} sm={6} md={4} mt={2}>
                <Typography variant="subtitle1" sx={styles.lsDetailsTxt}>
                  <span style={styles.txtBold}>Standard No: </span>
                  {selectedLSDetails?.standard_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mt={2}>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" sx={styles.lsDetailsTxt}>
                    <span style={styles.txtBold}>Name: </span>
                    {selectedLSDetails?.name}
                  </Typography>
                  <IconButton
                    size="small"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                    onClick={() =>
                      handleUpdateLegalStandardClick({
                        ls_id: selectedLSDetails?.ls_id,
                        name: selectedLSDetails?.name,
                      })
                    }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  {/* <Button
                  variant="text"
                  startIcon={<EditIcon fontSize="small" />}
                  sx={{ marginLeft: 1 }}
                  // onClick={() => handleEdit("name")}
                >
                  Edit
                </Button> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mt={2}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle1"
                    sx={{ ...styles.lsDetailsTxt, marginRight: 1 }}
                  >
                    <span style={styles.txtBold}>Tags/Chapters:</span>
                  </Typography>
                  <SelectTextFieldN
                    size="small"
                    label="Select Tag/Chapter"
                    variant="standard"
                    optionsList={chaptersOptionsListS}
                    selectedValue={selectedChapter}
                    handleOnChange={(e) => setSelectedChapter(e.target.value)}
                    sltFldStyle={styles.lsSelectTextFieldN}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mt={2}>
                <Typography variant="subtitle1" sx={styles.lsDetailsTxt}>
                  <span style={styles.txtBold}>Revision: </span>
                  {selectedLSDetails?.revision}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mt={2}>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" sx={styles.lsDetailsTxt}>
                    <span style={styles.txtBold}>Description: </span>
                    {selectedLSDetails?.description}
                  </Typography>
                  <IconButton
                    size="small"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                    onClick={() =>
                      handleUpdateLegalStandardClick({
                        ls_id: selectedLSDetails?.ls_id,
                        description: selectedLSDetails?.description,
                      })
                    }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  {/* <Button
                  variant="text"
                  startIcon={<EditIcon fontSize="small" />}
                  sx={{ marginLeft: 1 }}
                  // onClick={() => handleEdit("description")}
                >
                  Edit
                </Button> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mt={2}>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" sx={styles.lsDetailsTxt}>
                    <span style={styles.txtBold}>Summary: </span>
                    {selectedLSDetails?.summary || "-"}
                  </Typography>
                  <IconButton
                    size="small"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                    onClick={() =>
                      handleUpdateLegalStandardClick({
                        ls_id: selectedLSDetails?.ls_id,
                        summary: selectedLSDetails?.summary
                          ? selectedLSDetails?.summary
                          : " ",
                      })
                    }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  {/* <Button
                  variant="text"
                  startIcon={<EditIcon fontSize="small" />}
                  sx={{ marginLeft: 1 }}
                  // onClick={() => handleEdit("summary")}
                >
                  Edit
                </Button> */}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Box>

      {selectedLS && (
        // Legal requirements
        <Box sx={styles.lrMainContainer}>
          <Box sx={styles.lrTopMainContainer}>
            <SearchTextField
              size={"small"}
              label={"Search Legal Req. No."}
              placeholder={"Search Legal Req. No."}
              variant={"standard"}
              value={searchLRValueTmp}
              setValue={setSearchLRValueTmp}
              handleClick={() => setSearchLRValue(searchLRValueTmp)}
            />
            {/* Add/Filter/Sorting buttons */}
            <Box sx={styles.lrbtnsContainer}>
              {/* <CustomFilters /> */}

              {/* <CustomSorting
                anchorEl={anchorElSort}
                open={openSort}
                handleClose={handleSortClose}
                sortOptionsList={[
                  { value: "created_at", label: "Created At" },
                  { value: "updated_at", label: "Updated At" },
                  { value: "req_number", label: "Requirement Number" },
                  // { value: "chapter", label: "Chapter" },
                ]}
                selectedSortColumn={selectedSortColumn}
                selectedSortOrder={selectedSortOrder}
                handleSortClick={handleSortClick}
                onReset={handleSortResetAll}
                onApplyNow={handleSortApplyNow}
              /> */}

              <CustomButton
                size={"small"}
                variant={"contained"}
                btnName={"Add Legal Req."}
                handleOnClick={toggleAddLRDialog(true)}
                startIcon={<AddIcon />}
              />
            </Box>
          </Box>
          <Paper elevation={6} sx={styles.lrTableContainer}>
            <CustomDataGridTableN
              tableRowsData={tableRowsData}
              columns={COLUMNS}
              handleTableRowClick={handleTableRowClick}
              isMultiLine={true}
              tableHeading={selectedChapter}
            />
            <Paper elevation={4} sx={styles.paginationPaper}>
              {/* Pagination with select page size */}
              <CustomPagination
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                hasMore={hasMore}
              />
            </Paper>
          </Paper>
        </Box>
      )}

      {/* Dialogs to add/update legal standard/requirements */}
      <AddLegalStandardDialog
        open={isAddLSDialogOpen}
        toggleDialog={toggleAddLSDialog}
        handleAddLegalStandard={handleAddLegalStandard}
      />
      <UpdateLegalStandardDialog
        open={isUpdateLSDialogOpen}
        toggleDialog={toggleUpdateLSDialog}
        handleUpdateLegalStandard={handleUpdateLegalStandard}
        data={updateLSDetails}
      />
      <AddLegalRequirementsDialog
        open={isAddLRDialogOpen}
        toggleDialog={toggleAddLRDialog}
        handleAddLegalRequirement={handleAddLegalRequirement}
        chaptersOptionsList={chaptersOptionsList}
      />
      <UpdateLegalRequirementsDialog
        open={isUpdateLRDialogOpen}
        toggleDialog={toggleUpdateLRDialog}
        handleUpdateLegalRequirement={handleUpdateLegalRequirement}
        data={updateLRDetails}
      />
    </Box>
  );
}

export default LegalRequirements;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Dialog to add the legal standard.
 * @param :
 * @return : The rendered add legal standard Dialog
 */
function AddLegalStandardDialog({
  open,
  toggleDialog,
  handleAddLegalStandard,
}) {
  // Add legal standards states
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [standardNumber, setStandardNumber] = useState("");
  const [standardNumberError, setStandardNumberError] = useState("");
  const [revision, setRevision] = useState("");
  const [revisionError, setRevisionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [chapters, setChapters] = useState([]);

  // Check if all the fields are valid
  const isFormValid = () => {
    return (
      name?.trim() !== "" &&
      !nameError &&
      standardNumber?.trim() !== "" &&
      !standardNumberError &&
      revision?.trim() !== "" &&
      !revisionError &&
      description?.trim() !== "" &&
      !descriptionError &&
      (summary?.trim().length === 0 || !summaryError)
    );
  };

  // Clear all the add legal standard states
  const clearDataFormFields = () => {
    setName("");
    setNameError("");
    setStandardNumber("");
    setStandardNumberError("");
    setRevision("");
    setRevisionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSummaryError("");
    setChapters([]);
  };

  // Handle close dialog
  const handleClose = () => {
    toggleDialog(false)();

    clearDataFormFields();
  };

  // UseEffects
  useEffect(() => {}, []);

  return (
    // Add the legal standard
    <CustomAddDialog
      open={open}
      onClose={handleClose}
      dialogTitle={"Add Legal Standard"}
      dialogContent={
        // Add legal standard
        <Stack spacing={2} mb={2} height={"354px"}>
          <CustomTextFieldN
            size={"small"}
            label={"Name*"}
            placeholder={"Name"}
            type={"text"}
            // fldType={"text1"}
            value={name}
            setValue={setName}
            valueError={nameError}
            setValueError={setNameError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Standard Number*"}
            placeholder={"Standard Number"}
            type={"text"}
            // fldType={"text1"}
            value={standardNumber}
            setValue={setStandardNumber}
            valueError={standardNumberError}
            setValueError={setStandardNumberError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Revision*"}
            placeholder={"Revision"}
            type={"text"}
            // fldType={"text1"}
            value={revision}
            setValue={setRevision}
            valueError={revisionError}
            setValueError={setRevisionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description"}
            type={"text"}
            // fldType={"textO"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            // fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <ChipsTextField
            size={"small"}
            label={"Tags/Chapters"}
            placeholder={"Tags/Chapters"}
            variant={"standard"}
            chips={chapters}
            setChips={setChapters}
            txtFldStyle={styles.chipsTextField}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddLegalStandard({
          name,
          standardNumber,
          revision,
          description,
          summary,
          chapters,
        });
        clearDataFormFields();
      }}
      onDisabled={!isFormValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Dialog to update the legal standard.
 * @param :
 * @return : The rendered update legal standard Dialog
 */
function UpdateLegalStandardDialog({
  open,
  toggleDialog,
  handleUpdateLegalStandard,
  data,
}) {
  // Update legal standards states
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");

  // Check if all the fields are valid
  const isFormValid = () => {
    if (data?.name) {
      return name !== "" && name?.trim() !== data?.name?.trim() && !nameError;
    } else if (data?.description) {
      return (
        description !== "" &&
        description?.trim() !== data?.description?.trim() &&
        !descriptionError
      );
    } else if (data?.summary) {
      return (
        summary !== " " &&
        summary?.trim() !== data?.summary?.trim() &&
        !summaryError
      );
    } else {
      return true;
    }
  };

  // Clear all the update legal standard states
  const clearDataFormFields = () => {
    setName("");
    setNameError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSummaryError("");
  };

  // Handle close dialog
  const handleClose = () => {
    toggleDialog(false)();

    clearDataFormFields();
  };

  // UseEffects
  useEffect(() => {
    if (data?.name) {
      setName(data?.name);
    } else if (data?.description) {
      setDescription(data?.description);
    } else if (data?.summary) {
      setSummary(data?.summary);
    }
  }, [data]);

  const dialogTitle = () => {
    if (data?.name) {
      return "Update Name";
    } else if (data?.description) {
      return "Update Description";
    } else if (data?.summary) {
      return "Update Summary";
    } else {
      return "Update";
    }
  };

  return (
    // Update the legal standard
    <CustomUpdateDialog
      open={open}
      onClose={handleClose}
      dialogTitle={dialogTitle()}
      dialogContent={
        // Update legal standard
        <Stack spacing={2} mb={2}>
          {data?.name && (
            <CustomTextFieldN
              size={"small"}
              label={"Name*"}
              placeholder={"Name"}
              type={"text"}
              // fldType={"text1"}
              value={name}
              setValue={setName}
              valueError={nameError}
              setValueError={setNameError}
              variant={"standard"}
              txtFldStyle={styles.customTextFieldN}
            />
          )}
          {data?.description && (
            <CustomTextFieldN
              size={"small"}
              label={"Description*"}
              placeholder={"Description"}
              type={"text"}
              // fldType={"textO"}
              value={description}
              setValue={setDescription}
              valueError={descriptionError}
              setValueError={setDescriptionError}
              variant={"standard"}
              txtFldStyle={styles.customTextFieldN}
            />
          )}
          {data?.summary && (
            <CustomTextFieldN
              size={"small"}
              label={"Summary"}
              placeholder={"Summary"}
              type={"text"}
              // fldType={"textO"}
              value={summary}
              setValue={setSummary}
              valueError={summaryError}
              setValueError={setSummaryError}
              variant={"standard"}
              txtFldStyle={styles.customTextFieldN}
            />
          )}
        </Stack>
      }
      onConfirm={() => {
        handleUpdateLegalStandard({
          ls_id: data?.ls_id,
          name,
          description,
          summary,
        });
        clearDataFormFields();
      }}
      onDisabled={!isFormValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Dialog to add the legal requirements.
 * @param :
 * @return : The rendered add legal requirements Dialog
 */
function AddLegalRequirementsDialog({
  open,
  toggleDialog,
  handleAddLegalRequirement,
  chaptersOptionsList,
}) {
  // Add legal requirements states
  const [reqNumber, setReqNumber] = useState("");
  const [reqNumberError, setReqNumberError] = useState("");
  const [chapter, setChapter] = useState("");
  const [chapterError, setChapterError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  // Check if all the fields are valid
  const isFormValid = () => {
    return (
      reqNumber?.trim() !== "" &&
      !reqNumberError &&
      chapter?.trim() !== "" &&
      !chapterError &&
      description?.trim() !== "" &&
      !descriptionError
    );
  };

  // Clear all the add legal requirements states
  const clearDataFormFields = () => {
    setReqNumber("");
    setReqNumberError("");
    setChapter("");
    setChapterError("");
    setDescription("");
    setDescriptionError("");
  };

  // Handle close dialog
  const handleClose = () => {
    toggleDialog(false)();

    clearDataFormFields();
  };

  // UseEffects
  useEffect(() => {}, []);

  return (
    // Add the legal requirements
    <CustomAddDialog
      open={open}
      onClose={handleClose}
      dialogTitle={"Add Legal Requirement"}
      dialogContent={
        // Add legal requirement
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Requirement Number*"}
            placeholder={"Requirement Number"}
            type={"text"}
            // fldType={"text1"}
            value={reqNumber}
            setValue={setReqNumber}
            valueError={reqNumberError}
            setValueError={setReqNumberError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          {/* <CustomTextFieldN
            size={"small"}
            label={"Chapter*"}
            placeholder={"Chapter"}
            type={"text"}
            fldType={"text1"}
            value={chapter}
            setValue={setChapter}
            valueError={chapterError}
            setValueError={setChapterError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          /> */}
          <EnterSelectTextField
            size={"small"}
            label={"Select or Enter Chapter*"}
            placeholder={"Select or Enter Chapter"}
            optionsList={chaptersOptionsList}
            value={chapter}
            setValue={setChapter}
            txtFldStyle={styles.enterSelectTextField}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description"}
            type={"text"}
            // fldType={"textO"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddLegalRequirement({ reqNumber, chapter, description });
        clearDataFormFields();
      }}
      onDisabled={!isFormValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Dialog to update the legal requirements.
 * @param :
 * @return : The rendered update legal requirements Dialog
 */
function UpdateLegalRequirementsDialog({
  open,
  toggleDialog,
  handleUpdateLegalRequirement,
  data,
}) {
  // Update legal requirements states
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  // Check if all the fields are valid
  const isFormValid = () => {
    return (
      description != "" &&
      description?.trim() !== data?.description?.trim() &&
      !descriptionError
    );
  };

  // Clear all the update legal requirements states
  const clearDataFormFields = () => {
    setDescription("");
    setDescriptionError("");
  };

  // Handle close dialog
  const handleClose = () => {
    toggleDialog(false)();

    clearDataFormFields();
  };

  // UseEffects
  useEffect(() => {
    setDescription(data?.description || "");
  }, [data]);

  return (
    // Update the legal requirements
    <CustomUpdateDialog
      open={open}
      onClose={handleClose}
      dialogTitle={"Update Legal Requirement"}
      // dialogTitle={"Update Description"}
      dialogContent={
        // Update legal requirement
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description"}
            type={"text"}
            // fldType={"textO"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
        </Stack>
      }
      onConfirm={() => {
        handleUpdateLegalRequirement({ lr_id: data?.lr_id, description });
        clearDataFormFields();
      }}
      onDisabled={!isFormValid()}
    />
  );
}

// Styles
const styles = {
  mainContainer: {
    mt: 1,
    mx: 2,
    pb: "70px",
    // border: "1px solid red",
  },
  // Legal standard
  lsMainContainer: {},
  lsTopContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 3,
  },
  heading: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  divider: {
    mt: 0.4,
    mb: 1,
  },
  lsSearchSelectStandardContainer: {
    textAlign: "start",
  },
  lsSearchSelectStandard: {
    width: "220px",
  },
  lrSearchSelectLegalReq: {
    width: "220px",
  },
  lsDetailsContainer: {
    px: 2,
    pt: 1,
    pb: 2,
    my: 2,
  },
  lsDetailsTxt: {
    textAlign: "start",
    pl: 2,
  },
  txtBold: {
    fontWeight: "bold",
  },
  lsSelectTextFieldN: {
    width: "170px",
    ml: 1,
    mt: -2,
  },
  // Legal requirements
  lrMainContainer: {
    mt: 8,
  },
  lrbtnsContainer: {
    display: "flex",
    gap: 1,
  },
  lrTopMainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 2,
  },
  lrTableContainer: {
    my: 2,
    height: 470,
    width: "100%",
    mb: 8,
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
  // Dialogs
  customTextFieldN: {
    width: "270px",
  },
  chipsTextField: {
    width: "270px",
  },
  enterSelectTextField: {
    width: "270px",
  },
};
