import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextFieldSP,
  MultiSelectTextField2,
  MultiSelectTextField3,
  SearchSelectTextField,
  SelectTextField,
} from "../../components";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import customConsole from "../../config/customConsole";
import {
  adminSettingsAPI,
  HandleApiActions,
  profileActions,
  projectConfigAPI,
} from "../../redux/actions";
import { GetSingleImages } from "../../redux/actions/actionHandler";
import moment from "moment";

function ReFirstScreen({ close, profileDetails, recreateProject }) {
  const projectStatus = {
    100: "ACTIVE_PRE_SOP",
    200: "ACTIVE_POST_SOP",
    300: "DE_ACTIVATED",
    500: "COMPLETED",
  };

  //1.Redux
  const dispatch = useDispatch();
  const timeZoneDetails = useSelector(
    (state) => state.countryAndPhoneCodeReducer.countryAndPhoneCode
  );
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );
  const architectureTypesDetails = useSelector(
    (state) => state.adminSettingsReducer
  );
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData
  );
  const vehicleClassDetails = useSelector((state) => state.vehicleClassReducer);

  // const projectStatusList = Object.keys(projectStatusDetails?.project_status);
  // const projectStatusList = Object.entries(
  //   projectStatusDetails?.project_status
  // ).map(([key, value]) => ({
  //   label: key,
  //   value: value,
  // }));

  customConsole(
    "---vehiclerStatusDetails---",
    projectStatusDetails?.vehicle_status
  );

  ///2.React useState
  const [vfData, setVfData] = useState([]);
  const [pcData, setPcData] = useState([]);
  const [architectureTypes, setArchitectureTypes] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [vcData, setVcData] = useState([]);
  const [image, setImage] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);

  const [region, setRegion] = useState("");
  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectPS, setSelectPS] = useState("");

  const [name, setName] = useState("");
  const [commercialName, setCommercialName] = useState("");
  const [manufacturerName, setManufacturerName] = useState("");
  const [manufacturerAddress, setManufacturerAddress] = useState("");
  const [vehiclePlatform, setVehiclePlatform] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");

  const [selectedItemsVf, setSelectedItemsVf] = useState([]);
  const [selectedItemsPc, setSelectedItemsPc] = useState([]);
  const [selectedItemsAt, setSelectedItemsAt] = useState("");
  const [selectedItemsZone, setSelectedItemsZone] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  //error useState
  const [nameError, setNameError] = useState(false);
  const [commercialNameError, setCommercialNameError] = useState(false);
  const [manufacturerNameError, setManufacturerNameError] = useState(false);
  const [manufacturerAddressError, setManufacturerAddressError] = useState("");
  const [vehiclePlatformError, setVehiclePlatformError] = useState("");
  const [vehicleStatusError, setVehicleStatusError] = useState("");

  useEffect(() => {
    console.log("----Changing recreate project---");
    console.log(recreateProject);

    if (recreateProject) {
      setRegion(recreateProject.region || "");
      setCountry(recreateProject.country || "");
      setSelectPS(recreateProject.status || "");
      setName(recreateProject.name || "");
      setCommercialName(
        recreateProject.name === name ? name : recreateProject.trade_mark || ""
      );
      setManufacturerName(recreateProject.mft_details?.name || "");
      setManufacturerAddress(recreateProject.mft_details?.address || "");
      setVehiclePlatform(recreateProject.vehicle_platform || "");
      setVehicleStatus(recreateProject.vehicle_status || "");
      setSelectedItemsVf(recreateProject.veh_funcs?.map((data) => data) || []);
      setSelectedItemsPc(
        recreateProject.pwrtrn_class?.map((data) => data) || []
      );
      setSelectedItemsAt(recreateProject.architecture_name || "");
      setSelectedItemsZone(
        recreateProject?.zones?.map((data) => ({
          zone_name: data?.zone_name || "",
          position: data?.position || "",
          sequence: data?.sequence || 0,
        })) || []
      );
      setSelectedClass(recreateProject.veh_class || "");
    }
  }, [recreateProject]);

  useEffect(() => {
    console.log("name", name);
    console.log("commercialName", commercialName);
    console.log("manufacturerName", manufacturerName);
    console.log("manufacturerAddress", manufacturerAddress);
    console.log("vehiclePlatform", vehiclePlatform);
    console.log("vehicleStatus", vehicleStatus);
    console.log("region", region);
    console.log("country", country);
    console.log("selectPS", selectPS);
    console.log("selectedClass", selectedClass);
    console.log("selectedItemsVf", selectedItemsVf);
    console.log("selectedItemsPc", selectedItemsPc);
    console.log("selectedItemsAt", selectedItemsAt);
    console.log("selectedItemsZone", selectedItemsZone);
  }, [selectedItemsPc]);

  useEffect(() => {
    console.log("----powertrain--");
    console.log(selectedItemsPc);
  }, [selectedItemsPc]);

  //3.React useEffect
  useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_admin_settings }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
  }, []);

  useEffect(() => {
    customConsole("TimezoneDetails", timeZoneDetails);
    if (timeZoneDetails && timeZoneDetails.length > 0) {
      const sortedRegions = timeZoneDetails?.map((data) => data?.time_zone);

      setRegions(sortedRegions);
      const countryOptions = timeZoneDetails?.map((data) => data?.country);

      setCountries(countryOptions);
    }
  }, [timeZoneDetails]);

  useEffect(() => {
    const projectStatusData = Object.entries(
      projectStatusDetails?.project_status
    ).map(([key, value]) => ({
      label: formatString(key),
      value: value,
    }));
    console.log("----projectStatusData----", projectStatusData);
    setProjectData(projectStatusData);

    const vehicleStatusData = projectStatusDetails?.vehicle_status?.map(
      (el, idx) => ({
        value: el,
        label: formatString(el),
      })
    );

    setVehicleData(vehicleStatusData);
  }, [projectStatusDetails]);

  useEffect(() => {
    customConsole("vehicleFunctionDetails......");

    customConsole(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
    setVfData(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
  }, [vehicleFunctionDetails]);

  useEffect(() => {
    customConsole("vehicleClassDetails......");

    customConsole(vehicleClassDetails?.vehicleClassDetails?.data);
    setVcData(vehicleClassDetails?.vehicleClassDetails?.data);
  }, [vehicleClassDetails]);

  useEffect(() => {
    customConsole("powerTrainDetails");
    customConsole(powerTrainClassDetails?.powerTrainClassDetails?.data);
    setPcData(powerTrainClassDetails?.powerTrainClassDetails?.data);
  }, [powerTrainClassDetails]);

  useEffect(() => {
    customConsole("architectureTypes");
    customConsole(
      architectureTypesDetails?.adminSettings?.data?.architecture_types
    );
    setArchitectureTypes(
      architectureTypesDetails?.adminSettings?.data?.architecture_types
    );
    setZoneData(architectureTypesDetails?.adminSettings?.data?.zone_details);
  }, [architectureTypesDetails]);

  useEffect(() => {
    if (vcData && vcData.length) {
      customConsole("---vcData----");
      customConsole(vcData);
      const imageUrl = async () => {
        const fetchUrlArr = await Promise.all(
          vcData.map(async (item) => {
            customConsole("---Image URL----");
            customConsole(item.image);
            try {
              const response = await GetSingleImages({ image: item.image });
              return { ...item, imageUrl: response };
            } catch (error) {
              customConsole(error);
              return { ...item, imageUrl: "" };
            }
          })
        );
        customConsole("----Fetch Url--");
        customConsole(fetchUrlArr);
        setImage(fetchUrlArr);
      };
      imageUrl();
    }
  }, [vcData]);

  useEffect(() => {
    console.log("----Zone change--");
    console.log(selectedItemsZone);
  }, [selectedItemsZone]);

  // const timeZones = Array.from(
  //   new Set(timeZoneDetails?.map((item) => item?.time_zone))
  // );

  // const ctry = timeZoneDetails.filter((item) => item?.time_zone === region);

  // customConsole("time zones", timeZones);
  // customConsole("ctry", ctry);

  // const timeZoneOptions = timeZones
  //   ?.map((tz) => ({ value: tz, label: tz }))
  //   .sort((a, b) => {
  //     return a.value.localeCompare(b.value);
  //   });
  // customConsole("timeZoneOptions", timeZoneOptions);

  // const countryOptions = ctry?.map((country) => ({
  //   value: country.country,
  //   label: country.country,
  // }));

  const handleCreate = () => {
    const reqObj = {
      name: name,
      trade_mark: commercialName,
      vehicle_platform: vehiclePlatform,
      status: selectPS,
      ...(!vehicleStatus?.length
        ? {
            vehicle_status: projectStatusDetails?.vehicle_status[vehicleStatus],
          }
        : null),
      architecture_name: selectedItemsAt,
      mft_details: {
        name: manufacturerName,
        address: manufacturerAddress,
      },
      ...(selectedItemsZone?.length > 0 &&
      selectedItemsAt?.toLowerCase() === "zonal"
        ? { zones: selectedItemsZone }
        : {}),

      vehicle_class: parseInt(selectedClass),

      pwrtrn_class:
        selectedItemsPc && selectedItemsPc.length
          ? selectedItemsPc?.map((str) => parseInt(str))
          : undefined,
      vehicle_funcs: selectedItemsVf?.map((str) => parseInt(str)),
      current_time: moment().format(),
      country: country,
      region: region,
    };

    console.log("reqObj...", reqObj);
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.add_project,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...projectConfigAPI.get_project }));
    });
    close(false);
  };

  customConsole("selectedItemsPc", selectedItemsPc);
  customConsole("selectedItemsZone", selectedItemsZone);

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    customConsole("event", event.target.value);
  };

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }

  useEffect(() => {
    customConsole("---Image---");
    customConsole(image);
  }, [image]);

  useEffect(() => {
    const timeZoneFilter = timeZoneDetails.filter(
      (item) => item?.country === country
    );
    const timeZoneOptions = timeZoneFilter?.map((data) => data?.time_zone);
    console.log("timeZoneOptions...", timeZoneOptions);
    setRegion(timeZoneOptions);
  }, [country]);

  console.log("projecData", projectData);
  console.log("region", region);
  return (
    <div>
      <Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontStyle: "italic", mt: "10px" }}>
            Required fields are marked with asterisk *
          </Typography>
          {/* <IconButton>
            <DownloadIcon />
          </IconButton> */}
        </Box>

        {/**/}
        <Box mt="10px" display="flex" justifyContent="space-between">
          <CustomTextFieldSP
            size="small"
            placeholder="Name of the Project"
            label="Name of the Project"
            required
            value={name}
            setValue={setName}
            txtFldStyle={{ width: "220px", mr: "10px" }}
            validationRules={{ minLength: 3 }}
            setError={setNameError}
            error={nameError !== ""}
          />
          <CustomTextFieldSP
            size="small"
            label="Trademark"
            placeholder="Trademark"
            value={commercialName}
            setValue={setCommercialName}
            txtFldStyle={{
              width: "220px",
              mr: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setCommercialNameError}
            error={commercialNameError !== ""}
          />

          <Box
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <SelectTextField
              required
              size={"small"}
              label={"Project Status"}
              optionsList={projectData}
              selectedValue={selectPS}
              setSelectedValue={setSelectPS}
              variant={"standard"}
              sltFldStyle={{
                width: "150px",
              }}
            />
            {/* <SearchSelectTextField
              optionsList={projectData}
              selectedValue={selectPS}
              setSelectedValue={setSelectPS}
              size={"small"}
              label={"Project Status*"}
              txtFldStyle={{ width: "200px" }}
            /> */}
          </Box>
          <CustomTextFieldSP
            required
            size="small"
            label="Vehicle Platform"
            placeholder="Vehicle Platform"
            value={vehiclePlatform}
            setValue={setVehiclePlatform}
            txtFldStyle={{
              width: "200px",

              mr: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setVehiclePlatformError}
            error={vehiclePlatformError !== ""}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          {/* <Box mt="30px">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Choose the type of Architecture*</InputLabel>
              <Select
                value={selectedItemsAt}
                onChange={(e) => setSelectedItemsAt(e.target.value)}
                label="Choose the type of Architecture*"
                displayEmpty
                variant="standard"
              >
                <MenuItem value="" disabled>
                  Select an Architecture
                </MenuItem>
                {architectureTypes?.map((data) => (
                  <MenuItem key={data?.name} value={data?.name}>
                    {data?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}
          <Box mt="30px">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={selectedItemsAt}
                onChange={(event, newValue) => setSelectedItemsAt(newValue)}
                options={architectureTypes?.map((data) => data.name) || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose the type of Architecture*"
                    variant="standard"
                    placeholder="Select an Architecture"
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </FormControl>
          </Box>

          {selectedItemsAt &&
          architectureTypes?.find((item) => item?.name === selectedItemsAt)
            ?.is_zones_available ? (
            <Box mt="20px" mb="10px">
              <Typography fontWeight={"bold"}>
                Select the Zones & Positions*
              </Typography>

              <Box mt="10px">
                <MultiSelectTextField3
                  disabled={!selectedItemsZone}
                  size="medium"
                  label="Select Zones"
                  optionsList={zoneData}
                  selectedValues={selectedItemsZone}
                  setSelectedValues={setSelectedItemsZone}
                  sltFldStyle={{ minWidth: 200 }}
                />
              </Box>
            </Box>
          ) : (
            <Box mb="10px"></Box>
          )}
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box sx={{ mt: "20px" }}>
            <Typography fontWeight={"bold"} ml="2px">
              Vehicle Class*
            </Typography>
            <div>
              <Box
                sx={{
                  display: "flex",
                  mt: "10px",
                  width: "400px",
                }}
              >
                <FormControl fullWidth sx={{ width: "400px" }}>
                  <InputLabel>Select Vehicle Class</InputLabel>
                  <Select
                    variant="standard"
                    value={selectedClass}
                    onChange={handleClassChange}
                    sx={{ mb: 2 }}
                    label="Select Vehicle Class"
                  >
                    {image &&
                      image?.map((data, idx) => (
                        <MenuItem key={data?.id} value={data?.id}>
                          <Box display="flex" key={data?.id} value={data?.id}>
                            <Box>
                              <img
                                src={data.imageUrl}
                                alt=""
                                style={{
                                  width: "46px",
                                  height: "46px",
                                  marginRight: "8px",
                                }}
                              />
                            </Box>

                            <Box display="flex" flexDirection="column">
                              <Typography fontWeight="bold">
                                {" "}
                                {data?.name}
                              </Typography>
                              <Typography>
                                {data?.class}-{data?.sub_class}
                              </Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Box>
          {/* <Box sx={{ mt: "20px" }}>
            <Typography fontWeight={"bold"} ml="2px">
              Vehicle Class*
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: "10px",
                width: "400px",
              }}
            >
              <Autocomplete
                value={selectedClass}
                onChange={(event, newValue) => handleClassChange(newValue)}
                options={image || []}
                getOptionLabel={(option) => option?.name || ""}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option?.id}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box>
                      <img
                        src={option.imageUrl}
                        alt=""
                        style={{
                          width: "46px",
                          height: "46px",
                          marginRight: "8px",
                        }}
                      />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="bold">{option?.name}</Typography>
                      <Typography>
                        {option?.class}-{option?.sub_class}
                      </Typography>
                    </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Vehicle Class"
                    variant="standard"
                    fullWidth
                  />
                )}
                sx={{ width: "400px" }}
              />
            </Box>
          </Box> */}

          <Box mt="55px">
            <SelectTextField
              size={"small"}
              label={"Vehicle Status"}
              optionsList={vehicleData}
              selectedValue={vehicleStatus}
              setSelectedValue={setVehicleStatus}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
                mr: "10px",
              }}
            />
            {/* <SearchSelectTextField
              optionsList={vehicleData}
              selectedValue={vehicleStatus}
              setSelectedValue={setVehicleStatus}
              size={"small"}
              label={"Vehicle Status"}
              txtFldStyle={{ width: "200px" }}
            /> */}
          </Box>
        </Box>

        <Box sx={{ marginTop: "10px" }}>
          <Box sx={{ mt: "10px", flexGrow: "2" }}>
            <Typography fontWeight={"bold"} ml="2px">
              Vehicle Function*
            </Typography>
            <MultiSelectTextField2
              mutiple
              size={"small"}
              label={"Select Vehicle Function"}
              optionsList={vfData}
              selectedValues={selectedItemsVf}
              setSelectedValues={setSelectedItemsVf}
              sltFldStyle={{
                width: "95%",
                textAlign: "start",
                mt: "10px",

                backgroundColor: "white",
              }}
            />
          </Box>
          <Box sx={{ mt: "10px", flexGrow: "2" }}>
            <Typography fontWeight={"bold"} ml="2px">
              PowerTrain Class
            </Typography>
            <MultiSelectTextField2
              mutiple
              variant="standard"
              size={"small"}
              label={"Select Powertrain Class [Optional]"}
              optionsList={pcData}
              selectedValues={selectedItemsPc}
              setSelectedValues={setSelectedItemsPc}
              sltFldStyle={{
                width: "95%",
                textAlign: "start",
                ml: "2px",
                mt: "10px",
                backgroundColor: "white",
              }}
            />
          </Box>
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
            }}
          >
            {/* <SelectTextField
              size={"small"}
              label={"Country*"}
              optionsList={countryOptions}
              selectedValue={country}
              setSelectedValue={setCountry}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
              }}
            /> */}
            <SearchSelectTextField
              optionsList={countries}
              selectedValue={country}
              setSelectedValue={setCountry}
              size={"small"}
              label={"Country*"}
              txtFldStyle={{ width: "200px" }}
            />
          </Box>
          <Box sx={{ marginTop: "10px", marginLeft: "10px" }}>
            {/* <SelectTextField
              size={"small"}
              label={"Time Zone*"}
              optionsList={regions}
              selectedValue={region}
              setSelectedValue={setRegion}
              variant={"standard"}
              sltFldStyle={{
                width: "180px",
              }}
            /> */}
            <SearchSelectTextField
              size={"small"}
              label={"Time Zone*"}
              placeholder={"Time Zone*"}
              noOptionsText={"No Time Zone"}
              optionsList={regions}
              selectedValue={region}
              setSelectedValue={setRegion}
              txtFldStyle={{
                width: "180px",
              }}
            />
          </Box>

          {/* <Box
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              flexGrow: "2",
            }}
          >
            <SelectTextField
              size={"small"}
              label={"Country*"}
              optionsList={countryOptions}
              //optionsList={countries}
              selectedValue={country}
              setSelectedValue={setCountry}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
              }}
            />
          </Box> */}
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />

        <Box sx={{ marginTop: "10px" }}>
          <Typography fontWeight={"bold"}>
            Manufacturer Name & Address
          </Typography>
          <CustomTextFieldSP
            required
            size="small"
            label="Manufacturer Name"
            placeholder="Manufacturer Name"
            value={manufacturerName}
            setValue={setManufacturerName}
            txtFldStyle={{
              width: "270px",
              marginTop: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setManufacturerNameError}
            error={manufacturerNameError !== ""}
          />

          <CustomTextFieldSP
            required
            size="small"
            label="Manufacturer Address"
            placeholder="Manufacturer Address"
            value={manufacturerAddress}
            setValue={setManufacturerAddress}
            txtFldStyle={{
              width: "95%",
              mt: "15px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setManufacturerAddressError}
            error={manufacturerAddressError !== ""}
          />
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />
      </Box>
      <Box mt="10px" display="flex" justifyContent="flex-end">
        <CustomButton
          handleOnClick={handleCreate}
          disabled={
            name?.length > 2 &&
            region &&
            country &&
            vehiclePlatform?.length > 2 &&
            selectedItemsVf?.length > 0 &&
            manufacturerName?.length > 2 &&
            selectedItemsAt?.length > 0 &&
            manufacturerAddress?.length > 2
              ? false
              : true
          }
          size="medium"
          btnName={" Recreate Project"}
          variant="contained"
          startIcon={<AddIcon />}
        />
      </Box>
    </div>
  );
}

export default ReFirstScreen;
