/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable Multi-select dropdown component with checkboxes.
 */
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable Multi-select dropdown component with checkboxes.
 * @param { size, label, optionsList, selectedValues, setSelectedValues, sltFldStyle }
 * @return The rendered multi-select dropdown component.
 */
function MultiSelectTextFieldNN({
  size,
  label,
  optionsList,
  selectedValues,
  setSelectedValues,
  sltFldStyle,
  variant,
  disabled,
}) {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 220,
      },
    },
  };

  // Handles change events for the multi-select dropdown.
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl size={size} sx={sltFldStyle}>
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        variant={variant}
        multiple
        value={selectedValues}
        onChange={handleChange}
        renderValue={(selected) =>
          selected
            .map((id) => {
              const option = optionsList.find((opt) => opt.id === id);
              return option ? option.name : "";
            })
            .join(", ")
        }
        input={<OutlinedInput label={label} />}
        MenuProps={menuProps}
      >
        {optionsList?.map((data) => (
          <MenuItem key={data.id} value={data.id}>
            <Checkbox checked={selectedValues.indexOf(data.id) > -1} />
            <ListItemText primary={data.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectTextFieldNN;
