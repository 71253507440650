// /**
//  * @author Tahir Shaik
//  * @description App Theme Colors Should Be declared Here and will use in entire app
//  * @date 11-06-2024
//  * @version 0.0.1 - Initial Release
//  */

// const appBarColor = "var(--app_bar)";
// const primaryTestColor = "var(--primary_test_color)";
// const headerTextColor = "var(--header_test_color)";
// const backGroundColor = "var(--body_backGround)";
// const darkBlueColor = "var(--dark_blue)";
// const textGray = "var(--light_gray)";
// const cardDarkColor = "var(--card_color)";
// const OrderHeaderColor = "var(--order_heading)";
// const whiteMode = "var(--white_mode)";
// const svgColor = "var(--svg_color)";
// const dashbordAppbar = "var(--dashbord_Appbar)";
// const newBookingLightBlue = "var(--lightBlue)";
// const walletCard = "var(--wallet_card)";
// const secondaryCard = "var(--secondary_card)";
// const sideBarHover = "var(--sidebar_hover)";
// const secondaryText = "var(--secondary_text)";
// const homePageCard = "var(--homepage_cards)";
// const infoCardColor = "var(--info_card)";
// const appColors = {
//   // themeColor: "#080a3b",
//   // primaryColor: "#121b47",
//   // secondaryColor: "#0384fc",
//   // primaryButtonColor: "#0CD8BE",
//   themeColor: "#121B47",
//   svgColor: svgColor,
//   primaryColor: "#121b47",
//   secondaryColor: "#0384fc",
//   primaryButtonColor: "#0CD8BE",
//   white: "#fff",
//   blue: "#30a7ff",
//   walletCard: walletCard,
//   dashbordAppbar: dashbordAppbar,
//   secondaryCard: secondaryCard,
//   Textgray: textGray,
//   lightGreen: "#08D5C9",
//   lightblue: "#E1ECF9",
//   waterBlue: "#e1ecf9",
//   sideBarHover: sideBarHover,
//   infoCardColor: infoCardColor,
//   whiteMode: whiteMode,
//   cardColor: cardDarkColor,
//   darkBlue: darkBlueColor,
//   newBookingLightBlue: newBookingLightBlue,
//   darkGray: primaryTestColor,
//   darkTextColor: "#58595b",
//   error: "red",
//   backGroundGradient: "linear-gradient(170deg, #07103d 0%, #28367b 75%)",
//   Failed: "#ef7272",
//   Sucsess: "#30a6ff",
//   textColorNew: "#068fff",
//   headerTextColor: headerTextColor,
//   bookingGradient: "linear-gradient(120deg, #2B4AE0 49%, #31A7FF 115%)",
//   dialogBackGround: "#c9eeffcc",
//   dialogColor: "#c9eeff",
//   backGround: backGroundColor,
//   orderHeaderColor: OrderHeaderColor,
//   grayText: "#58595b",
//   secondaryText: secondaryText,
//   homePageCard: homePageCard,
//   appBrColor: appBarColor,
// };

// export default appColors;

export const buttons = {
  greenDefault: "#37b518",
  greenHover: "#9aeb75",
};

export const tabs = {
  backcolor: "#007cb0",
  color: "white",
};

export const list = {};
