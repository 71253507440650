/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description : Component to add, update and replace ee component.
 */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { TbReplace } from "react-icons/tb";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import {
  CustomAddDialog,
  CustomLogsDialog,
  CustomReplaceDialog,
  CustomTextFieldN,
  CustomUpdateDialog,
} from "../../components";
import VlcSWModules from "./SWModules";
import { HandleApiActions, labCar } from "../../redux/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import customConsole from "../../config/customConsole";
import appTheme from "../../assets/AppTheme/appTheme";
import InfoButton from "../../components/infoButton/InfoButton";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description : Show the ee component component
 * @param : heading, eEComponentsList, handleEEComponentAdd, handleEEComponentUpdate, handleEEComponentReplace, selectedProjectDetails, selectedZone,
 * @return : The rendered ee component.
 */
function VlcEEComponents({
  heading,
  eEComponentsList,
  handleEEComponentAdd,
  handleEEComponentUpdate,
  handleEEComponentReplace,
  selectedProjectDetails,
  selectedZone,
}) {
  const dispatch = useDispatch();

  const { swModulesList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* -------------- Dialogs -------------- */
  const [openEEComponentAddDialog, setOpenEEComponentAddDialog] =
    useState(false);
  const [openEEComponentUpdateDialog, setOpenEEComponentUpdateDialog] =
    useState(false);
  const [openEEComponentReplaceDialog, setOpenEEComponentReplaceDialog] =
    useState(false);
  const [openEEComponentChangeLogsDialog, setOpenEEComponentChangeLogsDialog] =
    useState(false);

  /* -------------- Selected ee component -------------- */
  const [isEEComponentSelected, setIsEEComponentSelected] = useState(false);
  const [selectedEEComponentDetails, setSelectedEEComponentDetails] =
    useState(null);

  /* -------------- Selected ee component update/replace details  -------------- */
  const [
    selectedEEComponentUpdateDetails,
    setSelectedEEComponentUpdateDetails,
  ] = useState(null);
  const [
    selectedEEComponentReplaceDetails,
    setSelectedEEComponentReplaceDetails,
  ] = useState(null);

  /* -------------- EE - SW Modules -------------- */
  const [eESWModulesList, setEESWModulesList] = useState([]);

  /* -------------- EE Change Logs -------------- */
  const [eeChangeLogsList, setEEChangeLogsList] = useState([]);
  const [eeLogsDialogData, setEELogsDialogData] = useState({});

  /* -------------- useEffects -------------- */
  useEffect(() => {
    /* Make to default state when the project is changed */
    setIsEEComponentSelected(false);
    setSelectedEEComponentDetails(null);
    setSelectedEEComponentUpdateDetails(null);
    setSelectedEEComponentReplaceDetails(null);
    setEESWModulesList([]);
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Make to default state when the selected zone is changed */
    setIsEEComponentSelected(false);
    setSelectedEEComponentDetails(null);
  }, [selectedZone]);

  useEffect(() => {
    /* Make to default state when the ee component is changed */
    setEESWModulesList([]);
  }, [selectedEEComponentDetails]);

  const getSWModulesList = () => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.get_sw_modules,
          params: {
            project_id: selectedProjectDetails?.project_id,
            ee_id: selectedEEComponentDetails?.ee_id,
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    getSWModulesList();
  }, [selectedEEComponentDetails]);

  useEffect(() => {
    if (swModulesList) {
      setEESWModulesList(swModulesList);
    }
  }, [swModulesList]);

  useEffect(() => {
    customConsole(
      "selectedEEComponentUpdateDetails:" + selectedEEComponentUpdateDetails
    );
    customConsole(
      "selectedEEComponentReplaceDetails:" + selectedEEComponentReplaceDetails
    );
  }, [selectedEEComponentUpdateDetails, selectedEEComponentReplaceDetails]);

  /* -------------- Handle EE Component functions -------------- */
  const handleSelectedEEComponent = (eEComponent) => {
    if (eEComponent) {
      setIsEEComponentSelected(true);
      setSelectedEEComponentDetails(eEComponent);
    }
  };

  const handleSelectedEEComponentUpdateDetails = (eEComponent) => {
    setOpenEEComponentUpdateDialog(true);

    if (eEComponent) {
      setSelectedEEComponentUpdateDetails(eEComponent);
    }
  };

  const handleSelectedEEComponentReplaceDetails = (eEComponent) => {
    setOpenEEComponentReplaceDialog(true);

    if (eEComponent) {
      setSelectedEEComponentReplaceDetails(eEComponent);
    }
  };

  /* -------------- Handle SW Module functions -------------- */
  const handleEESWModuleAdd = ({
    version,
    name,
    summary,
    description,
    vf_ids,
  }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        version,
        name,
        ...(summary && { summary }),
        description,
        vf_ids,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.add_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            version,
            name,
            ...(summary && { summary }),
            description,
            vf_ids,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getSWModulesList();
      });
    }
  };

  const handleEESWModuleUpdate = ({ swm_id, version }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        swm_id,
        version,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.update_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id,
            version,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getSWModulesList();
      });
    }
  };

  const handleEESWModuleReplace = ({
    swm_id,
    version,
    name,
    summary,
    description,
    vf_ids,
  }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        swm_id,
        version,
        name,
        ...(summary && { summary }),
        description,
        vf_ids,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.replace_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id,
            version,
            name,
            ...(summary && { summary }),
            description,
            vf_ids,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getSWModulesList();
      });
    }
  };

  /* Get EE component change logs */
  const handleGetEEComponentChangeLogs = async ({
    ee_id,
    pageNo,
    pageSize,
  }) => {
    let apiDetails = labCar.get_ee_components_change_logs;

    if (selectedProjectDetails && selectedProjectDetails?.project_id) {
      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectDetails?.project_id),
          ee_id,
          page_number: pageNo,
          page_size: pageSize,
        },
        show_toast: false,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id,
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setEEChangeLogsList([]);
      } else {
        setEEChangeLogsList(resp?.data);
      }
    }
  };

  /* Handle dialogs */
  const handleEELogsDialogOpen = (data) => {
    setOpenEEComponentChangeLogsDialog(true);

    // handleGetEEComponentChangeLogs({ ee_id: data?.ee_id });

    setEELogsDialogData(data);
  };
  const handleEELogsDialogClose = () => {
    setEELogsDialogData(null);
    setEEChangeLogsList([]);

    setOpenEEComponentChangeLogsDialog(false);
  };

  return (
    <Box sx={styles.eeCompMainContainer}>
      <Box>
        {/* EE Component card */}
        <Card sx={styles.eeCompCard}>
          <CardHeader
            sx={styles.eeCompCardHeader}
            title={
              <>
                <Typography
                  textAlign={"start"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {heading}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    textAlign={"start"}
                    fontSize="13px"
                    alignContent={"center"}
                    color="textSecondary"
                  >
                    {"EE Components"}
                  </Typography>
                  <InfoButton infoKey={"ee_component"} color={"grey"} />
                </Box>
              </>
            }
            action={
              <Box
                sx={{
                  pt: 1.5,
                }}
              >
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  startIcon={<AddIcon />}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => setOpenEEComponentAddDialog(true)}
                >
                  Add
                </Button>
              </Box>
            }
          />
          <CardContent sx={styles.eeCompCardContent}>
            {eEComponentsList && eEComponentsList?.length > 0 ? (
              eEComponentsList?.map((item, index) => (
                <Box
                  key={index}
                  onClick={() => handleSelectedEEComponent(item)}
                  sx={{
                    ...styles.eeCompsList,
                    border:
                      selectedEEComponentDetails &&
                      selectedEEComponentDetails?.ee_id == item?.ee_id
                        ? "2px solid blue"
                        : "1px solid lightgrey",
                  }}
                >
                  <Box sx={styles.eeCompsListItemContainer}>
                    <Typography variant="body2">
                      <strong>Description:</strong> {item?.description}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Assembly Number:</strong> {item?.assembly_number}
                    </Typography>
                    {/* <Typography variant="body2">
                  <strong>Supplier Name:</strong> {item?.supplier_name}
                </Typography> */}
                    <Typography variant="body2">
                      <strong>Version:</strong> {item?.version}
                    </Typography>
                  </Box>
                  <Box sx={styles.edRpIconsContainer}>
                    <Tooltip title="Update">
                      <IconButton
                        size="small"
                        color="info"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectedEEComponentUpdateDetails(item);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Replace">
                      <IconButton
                        size="small"
                        color="info"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectedEEComponentReplaceDetails(item);
                        }}
                      >
                        <TbReplace sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Logs">
                      <IconButton
                        size="small"
                        color="info"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEELogsDialogOpen(item);
                        }}
                      >
                        <HistoryEduIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body1" sx={styles.noDataAvailable}>
                No data available
              </Typography>
            )}
          </CardContent>
          <CardActions sx={styles.eeCompCardActions}>
            {/* <Button
              startIcon={<CloseIcon />}
              color="error"
              size="small"
              variant="contained"
            >
              Cancel
            </Button> */}
          </CardActions>
        </Card>

        <VlcEEComponentAddDialog
          openEEComponentAddDialog={openEEComponentAddDialog}
          setOpenEEComponentAddDialog={setOpenEEComponentAddDialog}
          handleEEComponentAdd={handleEEComponentAdd}
        />

        <VlcEEComponentUpdateDialog
          openEEComponentUpdateDialog={openEEComponentUpdateDialog}
          setOpenEEComponentUpdateDialog={setOpenEEComponentUpdateDialog}
          handleEEComponentUpdate={handleEEComponentUpdate}
          selectedEEComponentUpdateDetails={selectedEEComponentUpdateDetails}
          setSelectedEEComponentUpdateDetails={
            setSelectedEEComponentUpdateDetails
          }
        />

        <VlcEEComponentReplaceDialog
          openEEComponentReplaceDialog={openEEComponentReplaceDialog}
          setOpenEEComponentReplaceDialog={setOpenEEComponentReplaceDialog}
          handleEEComponentReplace={handleEEComponentReplace}
          selectedEEComponentReplaceDetails={selectedEEComponentReplaceDetails}
          setSelectedEEComponentReplaceDetails={
            setSelectedEEComponentReplaceDetails
          }
        />
      </Box>

      {/* Software module card */}
      <Box>
        {isEEComponentSelected && (
          <VlcSWModules
            heading={selectedEEComponentDetails.description}
            sWModulesList={eESWModulesList}
            handleSWModuleAdd={handleEESWModuleAdd}
            handleSWModuleUpdate={handleEESWModuleUpdate}
            handleSWModuleReplace={handleEESWModuleReplace}
            setIsEEComponentSelected={setIsEEComponentSelected}
            setSelectedEEComponentDetails={setSelectedEEComponentDetails}
            selectedProjectDetails={selectedProjectDetails}
            selectedEEComponentDetails={selectedEEComponentDetails}
          />
        )}
      </Box>

      <CustomLogsDialog
        open={openEEComponentChangeLogsDialog}
        handleClose={handleEELogsDialogClose}
        dialogTitle={"Change Logs"}
        dialogContent={eeChangeLogsList}
        data={eeLogsDialogData}
        getLogsData={handleGetEEComponentChangeLogs}
      />
    </Box>
  );
}

export default VlcEEComponents;

// Styles
const styles = {
  eeCompMainContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-evenly",
  },
  eeCompCard: {
    mt: 1.3,
    mx: 2,
    width: "370px",
    boxShadow: 12,
  },
  eeCompCardHeader: {
    py: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  eeCompCardContent: {
    height: "391px",
    overflowY: "auto",
  },
  eeCompCardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  eeCompsList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
    cursor: "pointer",
    "&:hover": {
      // backgroundColor: "lightgrey",
    },
  },
  eeCompsListItemContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  noDataAvailable: {
    textAlign: "center",
    mt: 2,
  },
  txtStyle: {
    width: "270px",
  },
  edRpIconsContainer: {
    display: "flex",
    flexDirection: "column",
  },
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : EE component add dialog.
 * @param : openEEComponentAddDialog, setOpenEEComponentAddDialog, handleEEComponentAdd,
 * @return : The rendered EE component add dialog.
 */
function VlcEEComponentAddDialog({
  openEEComponentAddDialog,
  setOpenEEComponentAddDialog,
  handleEEComponentAdd,
}) {
  /* Add EE Components Fields */
  const [assemblyNo, setAssemblyNo] = useState("");
  const [assemblyNoError, setAssemblyNoError] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierNameError, setSupplierNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");

  /* -------------- UseEffects -------------- */

  /* -------------- Add EE Component -------------- */

  const clearAddEEComponentFields = () => {
    setAssemblyNo("");
    setAssemblyNoError("");
    setSupplierName("");
    setSupplierNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
  };

  const isAddEEComponentValid = () => {
    return (
      assemblyNo?.trim() !== "" &&
      !assemblyNoError &&
      supplierName?.trim() !== "" &&
      !supplierNameError &&
      version?.trim() !== "" &&
      !versionError &&
      description?.trim() !== "" &&
      !descriptionError &&
      (summary?.trim().length === 0 || !summaryError)
    );
  };

  const handleCloseEEComponentAdd = () => {
    setOpenEEComponentAddDialog(false);

    clearAddEEComponentFields();
  };

  const handleAddEEComponent = () => {
    handleEEComponentAdd({
      assembly_number: assemblyNo,
      supplier_name: supplierName,
      version: version,
      summary: summary,
      description: description,
    });

    handleCloseEEComponentAdd();
  };

  return (
    <CustomAddDialog
      open={openEEComponentAddDialog}
      onClose={handleCloseEEComponentAdd}
      dialogTitle={"Add EE Component"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Assembly No*"}
            placeholder={"Assembly No*"}
            type={"text"}
            fldType={"textR"}
            value={assemblyNo}
            setValue={setAssemblyNo}
            valueError={assemblyNoError}
            setValueError={setAssemblyNoError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Supplier Name*"}
            placeholder={"Supplier Name*"}
            type={"text"}
            fldType={"text"}
            value={supplierName}
            setValue={setSupplierName}
            valueError={supplierNameError}
            setValueError={setSupplierNameError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddEEComponent({});
        clearAddEEComponentFields();
      }}
      onDisabled={!isAddEEComponentValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : EE component update dialog.
 * @param : openEEComponentUpdateDialog, setOpenEEComponentUpdateDialog, handleEEComponentUpdate, selectedEEComponentUpdateDetails, setSelectedEEComponentUpdateDetails,
 * @return : The rendered EE component update dialog.
 */
function VlcEEComponentUpdateDialog({
  openEEComponentUpdateDialog,
  setOpenEEComponentUpdateDialog,
  handleEEComponentUpdate,
  selectedEEComponentUpdateDetails,
  setSelectedEEComponentUpdateDetails,
}) {
  /* Update EE Components Fields */
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");

  useEffect(() => {
    if (
      openEEComponentUpdateDialog == true &&
      selectedEEComponentUpdateDetails
    ) {
      setVersion(selectedEEComponentUpdateDetails?.version || "");
    }
  }, [openEEComponentUpdateDialog, selectedEEComponentUpdateDetails]);

  /* -------------- Update EE Component -------------- */

  const clearUpdateEEComponentFields = () => {
    setVersion("");
    setVersionError("");
  };

  const isUpdateEEComponentValid = () => {
    return (
      !versionError &&
      version?.trim() !== selectedEEComponentUpdateDetails?.version
    );
  };

  const handleCloseEEComponentUpdate = () => {
    setOpenEEComponentUpdateDialog(false);

    clearUpdateEEComponentFields();

    setSelectedEEComponentUpdateDetails(null);
  };

  const handleUpdateEEComponent = ({}) => {
    if (
      selectedEEComponentUpdateDetails &&
      selectedEEComponentUpdateDetails?.ee_id
    ) {
      handleEEComponentUpdate({
        ee_id: Number(selectedEEComponentUpdateDetails?.ee_id),
        version: version,
      });

      handleCloseEEComponentUpdate();
    }
  };

  return (
    <CustomUpdateDialog
      open={openEEComponentUpdateDialog}
      onClose={handleCloseEEComponentUpdate}
      dialogTitle={"Update EE Component"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
        </Stack>
      }
      onConfirm={() => {
        handleUpdateEEComponent({});
        clearUpdateEEComponentFields();
      }}
      onDisabled={!isUpdateEEComponentValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : EE component replace dialog.
 * @param : openEEComponentReplaceDialog, setOpenEEComponentReplaceDialog, handleEEComponentReplace, selectedEEComponentReplaceDetails, setSelectedEEComponentReplaceDetails,
 * @return : The rendered EE component replace dialog.
 */
function VlcEEComponentReplaceDialog({
  openEEComponentReplaceDialog,
  setOpenEEComponentReplaceDialog,
  handleEEComponentReplace,
  selectedEEComponentReplaceDetails,
  setSelectedEEComponentReplaceDetails,
}) {
  /* Replace EE Components Fields */
  const [assemblyNo, setAssemblyNo] = useState("");
  const [assemblyNoError, setAssemblyNoError] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierNameError, setSupplierNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");

  useEffect(() => {
    if (
      openEEComponentReplaceDialog == true &&
      selectedEEComponentReplaceDetails
    ) {
      setAssemblyNo(selectedEEComponentReplaceDetails?.assembly_number || "");
      setSupplierName(selectedEEComponentReplaceDetails?.supplier_name || "");
      setVersion(selectedEEComponentReplaceDetails?.version || "");
      setDescription(selectedEEComponentReplaceDetails?.description || "");
      setSummary(selectedEEComponentReplaceDetails?.summary || "");
    }
  }, [openEEComponentReplaceDialog, selectedEEComponentReplaceDetails]);

  /* -------------- Replace EE Component -------------- */

  const clearReplaceEEComponentFields = () => {
    setAssemblyNo("");
    setAssemblyNoError("");
    setSupplierName("");
    setSupplierNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
  };

  const isReplaceEEComponentValid = () => {
    return (
      !assemblyNoError &&
      !supplierNameError &&
      !versionError &&
      !descriptionError &&
      (summary?.trim().length === 0 || !summaryError) &&
      (assemblyNo?.trim() !==
        selectedEEComponentReplaceDetails?.assembly_number ||
        supplierName?.trim() !==
          selectedEEComponentReplaceDetails?.supplier_name ||
        version?.trim() !== selectedEEComponentReplaceDetails?.version ||
        description?.trim() !==
          selectedEEComponentReplaceDetails?.description ||
        summary?.trim() !== selectedEEComponentReplaceDetails?.summary)
    );
  };

  const handleCloseEEComponentReplace = () => {
    setOpenEEComponentReplaceDialog(false);

    clearReplaceEEComponentFields();

    setSelectedEEComponentReplaceDetails(null);
  };

  const handleReplaceEEComponent = ({}) => {
    if (
      selectedEEComponentReplaceDetails &&
      selectedEEComponentReplaceDetails?.ee_id
    ) {
      handleEEComponentReplace({
        ee_id: Number(selectedEEComponentReplaceDetails?.ee_id),
        assembly_number: assemblyNo,
        supplier_name: supplierName,
        version: version,
        summary: summary,
        description: description,
      });

      handleCloseEEComponentReplace();
    }
  };

  return (
    <CustomReplaceDialog
      open={openEEComponentReplaceDialog}
      onClose={handleCloseEEComponentReplace}
      dialogTitle={"Replace EE Component"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Assembly No*"}
            placeholder={"Assembly No*"}
            type={"text"}
            fldType={"textR"}
            value={assemblyNo}
            setValue={setAssemblyNo}
            valueError={assemblyNoError}
            setValueError={setAssemblyNoError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Supplier Name*"}
            placeholder={"Supplier Name*"}
            type={"text"}
            fldType={"text"}
            value={supplierName}
            setValue={setSupplierName}
            valueError={supplierNameError}
            setValueError={setSupplierNameError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
        </Stack>
      }
      onConfirm={() => {
        handleReplaceEEComponent({});
        clearReplaceEEComponentFields();
      }}
      onDisabled={!isReplaceEEComponentValid()}
    />
  );
}
