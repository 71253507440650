/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Reducer function for handling legal requirements related actions and state changes.
 */

import actionType from "../actionTypes";

const initialState = {
    legalStandardDetails: [],
    legalRequirementsDetails: [],
}

const legalRequirementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_LEGAL_STANDARD_DETAILS:
            return {
                ...state,
                legalStandardDetails: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_LEGAL_REQUIREMENTS_DETAILS:
            return {
                ...state,
                legalRequirementsDetails: action.payload.error ? [] : action.payload.data,
            }
        default:
            return state;
    }
}

export default legalRequirementsReducer