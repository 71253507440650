
export const project_config = {
    "project_status": "Project status refers to the status for vehicles already in the market referred as post SOP, and vehicle yet to hit the production as pre-SOP",
    "vehicle_status": "Vehicle status refers to the OEM manufacturing status of the vehicle",
    "vehicle_platform": "Vehicle platform is the collection of software or cloud services based on which vehicle is developed & operated in the market.",
    "name_of_the_project": "Project name should indicate the vehicle & platform or respective function to be homologated",
    "trademark": "Tradename is usually registered vehicle model name before Go-live until it is launched in the market with actual model name.",
    "vehicle_class": "Vehicle class indicates the product line and category of vehicle for example commercial, passenger or heavy-duty vehicles with different no of wheel & other specifications",
    "vehicle_function": "Vehicle function indicates the respective functionalities of component or group of components E/E or SW module for example Lane keep assistance",
    "power_train_class": "Powertrain refers to the power generation source for example gasoline powered, battery powered and others",
    "manufacturer_name": "Name of Car manufacturer for homologation purposes",
    "manufacturer_address": "Address of the Car manufacturer for homologation purposes",
    "type_of_architecture": "The structured configuration of electronic components, modules, and networks within a vehicle defines Vehicle Electronics Architecture"
  };
  
  export const vlc_ta_lr = {
    "software_module": "A software module in a car refers to a self-contained unit of software that performs a specific function or set of functions within the vehicle's electronic systems.",
    "ee_component": "In a modern car, E&E components play a vital role in controlling, monitoring, and enhancing various vehicle functions. These components are integrated into the vehicle's electrical and electronic architecture, enabling everything from basic operations to advanced safety and infotainment systems.",
    "zonal_architecture": "Zonal architecture in the context of automotive design refers to a modern approach to the electrical and electronic (E/E) architecture of vehicles, where the vehicle's electronic systems are organized into specific zones. Each zone is responsible for managing a specific set of functions or components within a defined area of the vehicle.",
    "centralized_architecture": "Centralized architecture in the context of automotive design refers to an approach where the majority of a vehicle's electronic control functions are managed by a few powerful central processing units (CPUs) or a single central computing unit.",
    "car_view": "Car view on the VLC module refers to the EE component networking inside the car with respect to the position of each EE component.",
    "mind_map": "Mind map on the VLC module refers to the EE component & Software module networking inside the car with respect to the position of each EE component as well as residing software modules.",
    "dependencies": "Linking dependencies generally refer to a single function within the car that uses multiple components to achieve the final outcome; hence, modules responsible for the completion of a particular function are dependent on each other by a uni-directional or bi-directional relationship.",
    "virtual_lab_car": "A virtual representation of a physical vehicle, capturing its systems, behaviors, and interactions in the virtual environment; in other words, the digital replica of an actual car with EE components and software modules as indicators of different zones and functions.",
    "legal_requirement": "In the context of homologation, the legal requirements refer to the mandatory processes, standards, and certifications that a vehicle or its components must meet to be legally sold or operated in a specific market. These requirements are set by regulatory authorities to ensure safety, environmental protection, and compliance with other relevant regulations.",
    "linking_legal_to_software_module": "Legal requirements must be fulfilled for each function available in the car; respectively, the EE components and software modules should follow the legal standards. Hence, it is mandatory to link each legal requirement to the respective software module responsible for the particular function as governed in the requirement.",
    "intersections": "In the context of mapping legal requirements to software modules in a car, 'intersections' refers to the points at which a single legal requirement might intersect with one or more software modules within the vehicle. This intersection requires careful mapping to ensure that all relevant software components collectively meet the regulatory standards.",
    "import_function": "To upload the mapping and configurations in the form of CSV or PDF wherever applicable.",
    "export_function": "To download the mapping and configurations in the form of CSV or PDF wherever applicable."
  };
  