/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description : This page is to add the user.
 */
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, userManagementApis } from "../../redux/actions";
import UpdateUser from "./UpdateUser";
import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import {
  CustomAddDialog,
  CustomButton,
  CustomDataGridTable,
  CustomDataGridTableN,
  CustomFilters,
  CustomPagination,
  CustomSorting,
  CustomTextFieldN,
  PhoneNumberTextField,
  SelectTextField,
} from "../../components";
import customConsole from "../../config/customConsole";
import CustomFiltersN from "../../components/tables/CustomFiltersN";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description : Component to add the user.
 * @param : none
 * @return : The rendered user component.
 */
function Users() {
  const dispatch = useDispatch();

  const { usersList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const { countryCodes } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const { rolesList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );

  const [isRightSideDrawerOpen, setIsRightSideDrawerOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [drawerData, setDrawerData] = useState(null);

  const [tableRowsData, setTableRowsData] = useState([]);
  const [userData, setUserData] = useState({});
  const profileDetails = useSelector((store) => store.profileReducer);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Sorting
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("created_at");
  const [selectedSortOrder, setSelectedSortOrder] = useState("ASC");

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("fullname");
  const [searchFieldValue, setSearchFieldValue] = useState("");

  // Roles
  const [userRolesOptions, setUserRolesOptions] = useState([]);

  useEffect(() => {
    let filteredRoles = rolesList
      ?.filter((el) => el?.is_active)
      ?.map((el) => {
        return {
          value: el?.role_id,
          label: `${el?.role_name}`,
        };
      })
      ?.sort((a, b) => {
        return a?.value?.localeCompare(b?.value);
      });

    setUserRolesOptions(filteredRoles);
  }, [rolesList]);

  useEffect(() => {
    // Handle pagination hasMore
    if (usersList?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    let filteredUsersList = usersList?.map((user, index) => {
      return {
        ...user,
        id: (page - 1) * rowsPerPage + index + 1,
        role: user?.role_details?.role_name,
        department: user?.role_details?.department,
        region: user.profile.region,
        created_at: convertUtcToTimeZone(
          user.created_at,
          profileDetails?.profile?.profile?.region
        ),
      };
    });

    setTableRowsData(filteredUsersList);
  }, [usersList]);

  useEffect(() => {
    handleGetUsersList();
  }, [
    page,
    rowsPerPage,
    selectedSortColumn,
    selectedSortOrder,
    selectedSearchField,
    searchFieldValue,
  ]);

  const handleGetUsersList = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_users_list,
        params: {
          page_number: page,
          page_size: rowsPerPage,
          sort_column: selectedSortColumn,
          sort_order: selectedSortOrder,
          ...(searchFieldValue && { [selectedSearchField]: searchFieldValue }),
        },
        show_toast: false,
      })
    );
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  // Open sort menu
  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  // Handle sort close
  const handleSortClose = () => {
    setAnchorElSort(null);
    setOpenSort(false);
  };

  // Handle sort change
  const handleSortChange = (column, order) => {
    setSelectedSortColumn(column);
    setSelectedSortOrder(order);

    setPage(1); // Reset to the first page when page size changes

    handleSortClose();
  };

  // Reset the sort values
  const handleSortResetAll = () => {
    handleSortChange("created_at", "ASC");
  };

  // Apply sorting
  const handleSortApplyNow = (selectedSortColumn, selectedSortOrder) => {
    handleSortChange(selectedSortColumn, selectedSortOrder);
  };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);

    setPage(1); // Reset to the first page when page size changes

    handleFilterClose();
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("fullname", "");
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  // Toggles the state of the drawer.
  const toggleUpdateDeleteDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  // Toggles the state of the drawer.
  const toggleDrawer = (newOpen) => () => {
    setIsRightSideDrawerOpen(newOpen);
  };

  // Toggles the state of the dialog.
  const addUserToggleDialog = (newOpen) => () => {
    setIsAddUserDialogOpen(newOpen);
  };

  // Handles the click event to open dialog.
  const handleAddUserDialog = () => {
    addUserToggleDialog(true)();
  };

  // Handles the click event for table rows for editing
  const handleTableRowClick = ({ params }) => {
    toggleUpdateDeleteDrawer(true)();

    setUserData({ ...params.row });
  };

  // Handles the click event to add new user
  const handleAddNewUserClick = ({
    fullname,
    email,
    phone_code,
    phone,
    region,
    role_id,
  }) => {
    let currentTime = new Date().toISOString();
    customConsole({
      fullname,
      email,
      phone_code,
      phone,
      current_time: currentTime,
      region,
      role_id,
    });

    dispatch(
      HandleApiActions({
        ...userManagementApis.add_user,
        params: {
          fullname,
          email,
          ...(phone && phone_code && { phone_code }),
          ...(phone && { phone }),
          current_time: currentTime,
          region,
          role_id,
        },
        show_toast: true,
      })
    ).then(() => {
      handleGetUsersList();
    });

    addUserToggleDialog(false)();
  };

  const COLUMNS = [
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      filterable: false,
      sortable: false,
    },
    { field: "fullname", headerName: "Name", width: 220 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "role", headerName: "Role", width: 220 },
    { field: "department", headerName: "Department", width: 220 },
    { field: "region", headerName: "Region", width: 220 },
    { field: "created_at", headerName: "Registered At", width: 220 },
  ];

  return (
    <Box style={styles.mainContainer} elevation={3}>
      {/* Add user button */}
      <Box sx={styles.tblFun}>
        {/* Add/Filter/Sorting buttons */}
        <Box sx={styles.btnsContainer}>
          <CustomFiltersN
            anchorEl={anchorElFilter}
            open={openFilter}
            handleClose={handleFilterClose}
            mainOptionsList={[
              { value: "fullname", label: "Full Name", type: "search" },
              {
                value: "is_active",
                label: "User Status",
                type: "select",
                value_options_list: [
                  { value: "true", label: "Active" },
                  { value: "false", label: "In-Active" },
                ],
              },
              {
                value: "role_id",
                label: "Roles",
                type: "select",
                value_options_list:
                  userRolesOptions?.length > 0 ? [...userRolesOptions] : [],
              },
            ]}
            selectedMainOption={selectedSearchField}
            selectedMainOptionValue={searchFieldValue}
            handleFilterClick={handleFilterClick}
            onReset={handleFilterResetAll}
            onApplyNow={handleFilterApplyNow}
          />

          <CustomSorting
            anchorEl={anchorElSort}
            open={openSort}
            handleClose={handleSortClose}
            sortOptionsList={[
              { value: "created_at", label: "Created At" },
              { value: "fullname", label: "Full Name" },
              { value: "email", label: "Email" },
            ]}
            selectedSortColumn={selectedSortColumn}
            selectedSortOrder={selectedSortOrder}
            handleSortClick={handleSortClick}
            onReset={handleSortResetAll}
            onApplyNow={handleSortApplyNow}
          />
          <CustomButton
            startIcon={<AddIcon />}
            size={"medium"}
            variant={"contained"}
            btnName={"Add"}
            handleOnClick={handleAddUserDialog}
            btnStyle={styles.customButton}
          />
        </Box>
      </Box>
      {/* table */}
      <Paper elevation={4} sx={styles.userPaper}>
        {/* Data grid table */}
        <CustomDataGridTableN
          tableRowsData={tableRowsData}
          columns={COLUMNS}
          handleTableRowClick={handleTableRowClick}
        />
        <Paper elevation={4} sx={styles.paginationPaper}>
          {/* Pagination with select page size */}
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            hasMore={hasMore}
          />
        </Paper>
      </Paper>

      <RightSideDrawer
        open={isRightSideDrawerOpen}
        drawerData={drawerData}
        setDrawerData={setDrawerData}
        toggleDrawer={toggleDrawer}
      />
      <AddUserDialog
        open={isAddUserDialogOpen}
        addUserToggleDialog={addUserToggleDialog}
        handleAddNewUserClick={handleAddNewUserClick}
        countryCodes={countryCodes}
        rolesList={rolesList}
      />
      <UpdateDeleteRightSideDrawer
        open={openDrawer}
        toggleDrawer={toggleUpdateDeleteDrawer}
        userData={userData}
        handleGetUsersList={handleGetUsersList}
      />
    </Box>
  );
}

export default Users;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description Component for displaying a right-side drawer with additional details.
 * @param { open, drawerData, setDrawerData, toggleDrawer }
 * @return The rendered right side drawer component.
 */
function RightSideDrawer({ open, drawerData, setDrawerData, toggleDrawer }) {
  const handleOnClose = () => {
    setDrawerData(null);

    toggleDrawer(false)();
  };

  const DrawerList = (
    <Box sx={{ width: 270 }} role="presentation" onClick={toggleDrawer(false)}>
      <Typography mt={1} textAlign={"center"} variant="h5" gutterBottom>
        {drawerData?.heading}
      </Typography>
      <Divider />
      <List>
        {drawerData?.items?.map((text, index) => (
          <ListItem key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleOnClose}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description : Shows the add user dialog.
 * @param : open, addUserToggleDialog, handleAddNewUserClick, countryCodes, rolesList,
 * @return : The rendered add user dialog
 */
const AddUserDialog = ({
  open,
  addUserToggleDialog,
  handleAddNewUserClick,
  countryCodes,
  rolesList,
}) => {
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedRoleDepartment, setSelectedRoleDepartment] = useState("");
  const [roleDepartmentOptions, setRoleDepartmentOptions] = useState("");
  const [phoneCodeOptions, setPhoneCodeOptions] = useState("");
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("");
  const [roleId, setRoleId] = useState("");

  const clearDataFormFields = () => {
    setFullName("");
    setFullNameError("");
    setEmail("");
    setEmailError("");
    setSelectedRoleDepartment("");
    setSelectedRegion("");
    setSelectedPhoneCode("");
    setPhoneNumber("");
    setPhoneNumberError("");
  };

  const handleClose = () => {
    addUserToggleDialog(false)();

    clearDataFormFields();
  };

  useEffect(() => {
    let filteredRegions = countryCodes
      ?.map((el) => {
        return { value: el.time_zone, label: el.time_zone };
      })
      .sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    let filteredPhoneCodes = countryCodes
      ?.map((el) => {
        return {
          value: el.phone_code,
          label: `${el.country}: ${el.phone_code}`,
          phone_code: el.phone_code,
        };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    setRegionOptions(filteredRegions);
    setPhoneCodeOptions(filteredPhoneCodes);
  }, [countryCodes]);

  useEffect(() => {
    let filteredRoleDepartment = rolesList
      ?.filter((el) => el.is_active)
      ?.map((el) => {
        return {
          value: el.role_name,
          label: `${el.role_name} - ${el.department}`,
        };
      })
      ?.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    setRoleDepartmentOptions(filteredRoleDepartment);
  }, [rolesList]);

  useEffect(() => {
    let getRoleId = rolesList.find(
      (el) => el.role_name == selectedRoleDepartment
    );

    if (getRoleId) {
      customConsole("getRoleId: " + getRoleId.role_id);

      setRoleId(getRoleId.role_id);
    }
  }, [selectedRoleDepartment]);

  const isFormValid = () => {
    return (
      fullName?.trim() !== "" &&
      !fullNameError &&
      email?.trim() !== "" &&
      !emailError &&
      (phoneNumber?.trim() === "" || phoneNumber?.length === 10) &&
      selectedRoleDepartment !== "" &&
      selectedRegion !== ""
    );
  };

  return (
    // Add user dialog
    <CustomAddDialog
      open={open}
      onClose={handleClose}
      dialogTitle={"Add User"}
      dialogContent={
        // add user fields
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Full Name*"}
            placeholder={"Full Name"}
            type={"text"}
            fldType={"text"}
            value={fullName}
            setValue={setFullName}
            valueError={fullNameError}
            setValueError={setFullNameError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Email*"}
            placeholder={"Email"}
            type={"email"}
            fldType={"email"}
            value={email}
            setValue={setEmail}
            valueError={emailError}
            setValueError={setEmailError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <SelectTextField
            size={"small"}
            label={"Role-Department*"}
            optionsList={roleDepartmentOptions}
            selectedValue={selectedRoleDepartment}
            setSelectedValue={setSelectedRoleDepartment}
            variant={"standard"}
            sltFldStyle={styles.customTextFieldN}
          />
          <SelectTextField
            size={"small"}
            label={"Time Zone*"}
            optionsList={regionOptions}
            selectedValue={selectedRegion}
            setSelectedValue={setSelectedRegion}
            variant={"standard"}
            sltFldStyle={styles.customTextFieldN}
          />
          <PhoneNumberTextField
            size="small"
            variant={"standard"}
            phoneCodeLabel="Country"
            phoneNumberLabel="Phone Number (Optional)"
            phoneCodeOptions={phoneCodeOptions}
            selectedPhoneCode={selectedPhoneCode}
            setSelectedPhoneCode={setSelectedPhoneCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumberError={phoneNumberError}
            setPhoneNumberError={setPhoneNumberError}
            phoneCodeStyle={{
              width: "75px",
              marginRight: "5px",
            }}
            phoneNumberStyle={{
              width: "190px",
            }}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddNewUserClick({
          fullname: fullName,
          email,
          phone_code: selectedPhoneCode,
          phone: phoneNumber,
          region: selectedRegion,
          role_id: Number(roleId),
        });
        clearDataFormFields();
      }}
      onDisabled={!isFormValid()}
    />
  );
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 06-07-2024
 * @description : Shows the update/delete user drawer
 * @param : open, userData, toggleDrawer
 * @return : The rendered update/delete user drawer
 */
function UpdateDeleteRightSideDrawer({
  open,
  userData,
  toggleDrawer,
  handleGetUsersList,
}) {
  const handleOnClose = () => {
    toggleDrawer(false)();
  };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleOnClose}>
        <Box width={"390px"}>
          <UpdateUser
            userData={userData}
            toggleDrawer={toggleDrawer}
            handleGetUsersList={handleGetUsersList}
          />
        </Box>
      </Drawer>
    </div>
  );
}

// Styling for the Users component
const styles = {
  mainContainer: {
    marginBottom: "60px",
  },
  container: {
    width: "100%",
    overflow: "hidden",
  },
  btnsContainer: {
    display: "flex",
    gap: 1,
  },
  tblFun: {
    mx: 2,
    mb: 2,
    display: "flex",
    justifyContent: "flex-end",
  },
  userPaper: {
    height: 470,
    width: "100%",
    mb: 14,
    // maxWidth: { xs: "350px", sm: "400px", md: "100%" },
    // overflow: "auto",
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
  customButton: {
    ml: 10,
  },
  customTextFieldN: {
    width: "270px",
  },
};
