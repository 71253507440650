/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable component for selecting from a searchable list of options.
 */
import {
  Autocomplete,
  Box,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable component for selecting from a searchable list of options.
 * @param { size, label, placeholder, noOptionsText, optionsList, selectedValue, setSelectedValue, txtFldStyle }
 * @return The rendered searchable with select option component.
 */
function SearchSelectTextField({
  size,
  label,
  placeholder,
  noOptionsText,
  optionsList,
  selectedValue,
  setSelectedValue,
  txtFldStyle,
  disableClearable = true,
}) {
  // Handles change events when a new value is selected from options.
  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  return (
    <Autocomplete
      disablePortal
      disableClearable={disableClearable}
      options={optionsList}
      value={selectedValue}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          label={label}
          placeholder={placeholder}
          variant="standard"
          sx={txtFldStyle}
        />
      )}
    />
  );
}

export default SearchSelectTextField;
